%page {
    @extend %cover;

    opacity: 0;
    visibility: hidden;
    z-index: z('content');
}

%page__wrapper {
    color: inherit;
}
