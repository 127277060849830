@include media('<phone'){


    .inlabschibsted__title {
        position: absolute;
        font-size: 9rem;
        top: 0%;
        width: 100%;
        z-index: 2;
        display: flex;
        justify-content: center;
        font-family: "Nd Tupa Nova";
        text-shadow: 0 1px #ef8bc7, 0 1.05px #ef8bc7, 1.05px 1px #ef8bc7, 1.05px 1.05px #ef8bc7, 3.1px 4.1px #ef8bc7, 1.1px 4.15px #ef8bc7, 2.15px 6.2px #ef8bc7, 4.15px 8.25px #ef8bc7, 6.2px 9.3px #ef8bc7;
        padding-top: 15%;
    }
    
    
.inlabSchibstedConceptList {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 20px;
    top: 12%;
    align-items: center;
}
    .inlabSchibstedConceptList > div:hover {
        border: 2px solid #ef8dc8;
        display: flex;
        width: 77%;
        height: 200%;
        background: #ffffff;
        border-radius: 37px;
        color: #ef8dc8;
        padding: 10px;
        filter: drop-shadow(2px 4px 6px pink);
    }
    
    
    
    
    #schibstedHeader {
        position: absolute;
        opacity: 1;
        z-index: 1;
        width: 100%;
        display: flex;
        justify-content: center;
        transform: scale(3);
        margin-top: 55%;
    }
    
    
    
    .textBox1 {
        width: 80%;
        color: white;
        display: flex;
        align-items: center;
        border: 2px solid #ffffff6b;
        z-index: 3;
        position: relative;
        transform: translate(14%, 10px);
        height: 53%;
        border-radius: 47px;
        background: #f08cc875;
        gap: 26px;
        margin-top: 42%;
        justify-content: center;
        flex-wrap: wrap;
        filter: drop-shadow(2px 4px 6px pink);
        padding: 0px 30px 8px 30px;
    }
    
    
    #schibstedHeader {
        position: absolute;
        opacity: 1;
        z-index: 1;
        width: 100%;
        display: flex;
        justify-content: center;
        transform: scale(4);
        margin-top: 75%;
        filter: blur(0px);
    }
    
    
    .inLabSchibstedcaseBackground {
        position: relative;
        width: 100%;
        opacity: 1;
        z-index: 1;
        top: 0%;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        font-size: 2.2rem;
        font-family: "Helvetica Neue LT Pro";
        padding: -1%;
    }

    .newsBoxCurationParagraphContainer {
        position: absolute;
        width: 91%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        z-index: 10;
        gap: 2%;
        margin-top: 0%;
        padding-left: 6%;
        height: 100%;
        font-size: 2.5rem;
        font-weight: 400;
        font-family: $font-suisse-bp;;
        top: 50%;
    }
    
    
    .inLabSchibstedUnderHeading {
        position: relative;
        width: 99%;
        opacity: 1;
        z-index: 1;
        display: flex;
        justify-content: center;
        font-family: "HelveticaNeueLT Pro 43 LtEx";
        font-size: 2.4rem;
    }
    
    .inLabSchibstedcaseDescription {
        position: relative;
        width: 100%;
        opacity: 1;
        z-index: 1;
        display: flex;
        justify-content: center;
        font-size: 2.3rem;
        font-family: "Helvetica Neue LT Pro";
    }

    
    
    
    #arrowToSchibstedCase {
        position: absolute;
        display: flex;
        justify-content: center;
        width: 20%;
        z-index: 2;
        margin-top: 6%;
        -webkit-text-stroke: 1px;
        fill: rgba(255, 255, 255, 0);
        stroke-width: 2%;
        stroke: white;
        transition: fill 0.3s ease-in-out;
        transform: scale(0.75);
    }
    
    
    .inlabSchibstedConceptOverview {
        position: relative;
        height: 130vh;
        width: 100vw;
        z-index: 3;
        background: #f2f1f2;
        background: -moz-linear-gradient(0deg, white 0%, #edb3d6 27%, #ef8bc7 73%, rgba(239, 139, 199, 0) 100%);
        background: linear-gradient(0deg, #dadada -1%, #edb3d6 42%, #ef8bc7 73%, rgba(239, 139, 199, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ef8bc7",GradientType=1);
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        margin-top: -50%;

 
    }
    
    
    .inlabSchibstedConceptIntro {
        position: relative;
        display: flex;
        justify-content: center;
        width: 73%;
        font-family: $font-helvetica-neue-lt-pro-ltex;
        font-size: 2.5rem;
        margin-top: 0%;
        top: 9%;
    }
    
    
    .inlabSchibstedlinktoWebsite {
        position: relative;
        display: flex;
        font-size: 2.75rem;
        z-index: 9999;
        opacity: 1;
        padding-right: 10%;
        flex-wrap: nowrap;
        height: 0%;
        margin-top: 6%;
        font-family: "Helvetica Neue LT Pro";
        top: 917%;
        width: fit-content;
        transform: none;
        color: white;
        display: flex;
        justify-content: center;
        left: 9%;
    }
    


    .newsBoxCurationLT, .aNewSoundLT, .newsTherapyLT, .pointOfViewsLT, .surpriseMeLT, .weAreHereLT {
        font-family: $font-helvetica-neue-lt-pro-ltex;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 4.25rem;
    }
    
    
    .inlabSchibstedConceptTitle {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 11rem;
        padding-top: 5%;
        font-family: "Nd Tupa Nova";
        margin-top: 0%;
        top: 5%;
        font-size: 14rem;
    }


    #videoContainer {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-left: 0%;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
        height: 100%;
        align-items: center;
    }




    #newsBoxCurationVideoBlurred {
        width: 50%;
        position: relative;
        top: 0px;
        left: 0px;
        display: flex;
        margin-left: 0%;
        background: #dadada;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f7bff",endColorstr="#ffffff",GradientType=1);
        display: none;
    }

    .textBox2 {
        position: relative;
        width: 76%;
        background: #dadada;
        border-radius: 20px;
        height: 9.5%;
        margin-left: 0%;
        border: 5px solid;
        top: 5%;
        margin-top: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 12%;
        flex-wrap: nowrap;
    }

    .newsBoxCurationSketch img {
        position: absolute;
        z-index: 11;
        width: 89%;
        top: -16%;
        opacity: 0;
        transition: opacity 0.5s ease;
        left: 8%;
        top: -25%;
        filter: brightness(0.96);
        opacity: 0;
    }

    .newsboxCuration {
        position: relative;
        background: #dadada;
        z-index: 0;
        height: 130%;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    #newsBoxCurationVideo {
        width: 83%;
        height: auto;
        position: relative;
        margin-left: 0%;
        border-radius: 57px;
        transform: scale(0.75);
        filter: drop-shadow(2px 4px 6px rgba(71, 22, 60, 0.3));
        top: -14%;
    }


    .newsboxCurationTitle {
        position: absolute;
        width: 80%;
        top: 14%;
        z-index: 50;
        font-family: $font-helvetica-neue-lt-pro;
        font-size: 7rem;
        color: #6e4275;
        line-height: 94%;
        padding-left: 2%;
        padding-top: -0.5%;
    }

    

    .sketchbutton1 {
        background: 100%;
        width: 32%;
        background: #dadada;
        border: 4px solid #6e4275;
        border-radius: 14px;
        padding-left: 2%;
        position: absolute;
    }


    .newsBoxCurationSketchButton {
        position: absolute;
        font-family: "Helvetica Neue LT Pro";
        font-size: 3rem;
        display: flex;
        color: #6e4275;
        padding-left: 2%;
    }

    .newsBoxCurationButtonContainer {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        height: 100%;
        align-items: center;
        width: 100%;
        margin-left: -17%;
        margin-top: -12%;
        z-index: 20;
        font-family: "Suisse BP";
        font-weight: 400;
        font-size: 1.9rem;
    }





    .newsTherapy {
        position: relative;
        width: 100%;
        z-index: 20;
        height: 130%;
        background: #dadada;
        border-top: 85px dotted #e8e8e8;
        top: -2%;
    }


    #newsTherapyVideo {
        width: 65%;
        position: relative;
        border-radius: 45px;
        transform: scale(1);
        filter: drop-shadow(2px 4px 6px rgba(71, 22, 60, 0.3));
        margin-top: -42%;
    }



    .newsTherapyTitle {
        position: absolute;
        width: 80%;
        top: 14%;
        z-index: 50;
        font-family: "Helvetica Neue LT Pro";
        font-size: 7rem;
        color: #6e4275;
        line-height: 94%;
        padding-left: 2%;
        padding-top: -0.5%;
    }


    .newsTherapyParagraphContainer {
        position: absolute;
        width: 91%;
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-wrap: nowrap;
        z-index: 10;
        gap: 2%;
        align-content: center;
        margin-top: 81%;
        padding-left: 5%;
        font-family: "Suisse BP";
        font-weight: 400;
        font-size: 2.5rem;
    }
    

    
    
    
    .textBox6 {
        position: relative;
        width: 76%;
        background: #dadada;
        border-radius: 20px;
        height: 9.5%;
        margin-left: 0%;
        border: 5px solid;
        top: 5%;
        margin-top: 13%;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 12%;
        flex-wrap: nowrap;
        padding-bottom: 3%;
    }


















.aNewSound {
    position: relative;
    width: 100%;
    z-index: 20;
    height: 130%;
    background: #dadada;
    border-top: 85px dotted #e8e8e8;
    top: -2%;
}



.aNewSoundTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;
}


.aNewSoundParagraphContainer {
    position: absolute;
    width: 91%;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
    z-index: 10;
    gap: 2%;
    align-content: center;
    margin-top: 91%;
    padding-left: 5%;
    font-family: "Suisse BP";
    font-weight: 400;
    font-size: 2.5rem;
}


#aNewSoundVideo {

    width: 65%;
    position: relative;
    border-radius: 45px;
    transform: scale(1);
    filter: drop-shadow(2px 4px 6px rgba(71, 22, 60, 0.3));
    margin-top: -42%;
}

.textBox4 {
position: relative;
    width: 76%;
    background: #dadada;
    border-radius: 20px;
    height: 9.5%;
    margin-left: 0%;
    border: 5px solid;
    top: 5%;
    margin-top: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12%;
    flex-wrap: nowrap;
    padding-bottom: 3%;
}

.weAreHere {
    position: relative;
    width: 100%;
    z-index: 20;
    height: 130%;
    background: #dadada;
    border-top: 85px dotted #e8e8e8;
    top: -2%;
}


.weAreHereTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;

}

.textBox5 {
    position: relative;
    width: 76%;
    background: #dadada;
    border-radius: 20px;
    height: 9.5%;
    margin-left: 0%;
    border: 5px solid;
    top: 5%;
    margin-top: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12%;
    flex-wrap: nowrap;
    padding-bottom: 3%;
}

.weAreHereIdentifiedProblemTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: $font-helvetica-neue-lt-pro;
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;
}

.weAreHereParagraphContainer {
    position: absolute;
    width: 91%;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
    z-index: 10;
    gap: 2%;
    align-content: center;
    margin-top: 91%;
    padding-left: 5%;
    font-family: "Suisse BP";
    font-weight: 400;
    font-size: 2.5rem;

}

#weAreHereVideoBlurred {
    width: 50%;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    margin-left: 0%;
    background: #dadada;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f7bff",endColorstr="#ffffff",GradientType=1);
    display: none;
}


#weAreHereVideo {
    width: 65%;
    position: relative;
    border-radius: 45px;
    transform: scale(1);
    filter: drop-shadow(2px 4px 6px rgba(71, 22, 60, 0.3));
    margin-top: 0%;
    top: -30%;
}















.pointOfView {

    position: relative;
    width: 100%;
    z-index: 20;
    height: 130%;
    background: #dadada;
    border-top: 85px dotted #e8e8e8;
    top: -2%;

}
.pointOfViewTitle {

    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;

} 

.textBox3 { 
    position: relative;
    width: 76%;
    background: #dadada;
    border-radius: 20px;
    height: 9.5%;
    margin-left: 0%;
    border: 5px solid;
    top: 5%;
    margin-top: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12%;
    flex-wrap: nowrap;
    padding-bottom: 3%;


}


.pointOfViewIdentifiedProblemTitle {

    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: $font-helvetica-neue-lt-pro;
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;


}

.pointOfViewParagraphContainer {

    position: absolute;
    width: 91%;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
    z-index: 10;
    gap: 2%;
    align-content: center;
    margin-top: 91%;
    padding-left: 5%;
    font-family: "Suisse BP";
    font-weight: 400;
    font-size: 2.5rem;


}


  #pointOfViewVideoBlurred {
    width: 50%;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    margin-left: 0%;
    background: #dadada;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f7bff",endColorstr="#ffffff",GradientType=1);
    display: none;
  }

#pointOfViewVideo {

    width: 65%;
    position: relative;
    border-radius: 45px;
    transform: scale(1);
    filter: drop-shadow(2px 4px 6px rgba(71, 22, 60, 0.3));
    margin-top: 0%;
    top: -30%;

}























.surpriseMe {
    position: relative;
    width: 100%;
    z-index: 20;
    height: 130%;
    background: #dadada;
    border-top: 85px dotted #e8e8e8;
    top: -2%;



}
.surpriseMeTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;
}


.surpriseMeConcept {
    position: relative;
    width: 100%;
    height: 40%;
    z-index: 10;
}


.textBox7 {
    position: relative;
    width: 76%;
    background: #dadada;
    border-radius: 20px;
    height: 9.5%;
    margin-left: 0%;
    border: 5px solid;
    top: 5%;
    margin-top: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12%;
    flex-wrap: nowrap;
    padding-bottom: 3%;


}


.surpriseMeIdentifiedProblemTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: $font-helvetica-neue-lt-pro;
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;

}

.surpriseMeParagraphContainer {
    position: absolute;
    width: 91%;
    display: flex;
    flex-direction: column;
    height: 50%;
    flex-wrap: nowrap;
    z-index: 10;
    gap: 2%;
    align-content: center;
    margin-top: 91%;
    padding-left: 5%;
    font-family: $font-suisse-bp;
    font-weight: 400;
    font-size: 2.5rem;
}

  #surpriseMeVideoBlurred {
    width: 50%;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    margin-left: 0%;
    background: #dadada;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f7bff",endColorstr="#ffffff",GradientType=1);
    display: none;
  }

#surpriseMeVideo {
    width: 65%;
    position: relative;
    border-radius: 45px;
    transform: scale(1);
    filter: drop-shadow(2px 4px 6px rgba(71, 22, 60, 0.3));
    margin-top: 0%;
    top: -30%;




}


.end {
    position: relative;
    width: 100%;
    height: 40%;
    margin-top: -5%;
    background: rgb(239,139,199);
    background: -moz-linear-gradient(0deg, rgba(239,139,199,1) 0%, rgba(218,218,218,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(239,139,199,1) 0%, rgba(218,218,218,1) 100%);
    background: linear-gradient(0deg, rgba(239,139,199,1) 0%, rgba(218,218,218,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ef8bc7",endColorstr="#dadada",GradientType=1);
  
  
  }

  .endText {
    position: relative;
    width: 100%;
    display: flex;
    top: 0%;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    font-size: 8rem;
    font-family: $font-helvetica-neue-lt-pro;
    left: 5%;
  }
  
  
  #endTopButton {
    position: relative;
    display: flex;
    width: 100%;
    top: 0%;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: center;
    fill: white;
    transform: scale(0.5);
}
  
  
  .endBtnCircle {
    display: block;
    fill: white;
  }

  



}



