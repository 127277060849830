%cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin ratio($width, $height) {
  position: relative;
  overflow: hidden;
  font-size: 0;

  &::after {
    content: '';
    display: inline-block;
    width: 100%;
    padding-top: $height / $width * 100%;
  }
}

%center {
  display: flex;
  align-items: center;
  justify-content: center;
}
