
*,
*::after,
*::before {
  box-sizing: border-box;
  user-select: none;
  cursor:auto;
}

html {
  @extend %cover;
  $vw: 100vw;
  font-size: calc($vw / 1920px * 10px);
  position: fixed;
  line-height: 1;
  overflow: hidden;
  background: $color-contessa;
  color: $color-white;

  @include media('<phone') {
    font-size: calc($vw / 750px * 10px);
  }
}



body {
  @extend %cover;
  font-family: $font-suisse-bp;
  @extend %description-16;
  position: fixed;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

button {
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  outline: none;
}

img {
  opacity: 0;
  transition: opacity 0.4s ease;
  vertical-align: middle;

  &.loaded {
    opacity: 1;
  }
}

canvas {
    pointer-events: none;
    position: relative;
    z-index: z('canvas')
}

.content {
  @extend %cover;
}

[data-animation='paragraph'],
[data-animation='label'],
[data-animation='title'] {
  span {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
  }
}
