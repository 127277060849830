%title-20 {
  font-family: $font-helvetica-neue-lt-pro;
  font-size: 2rem;

  @include media('<phone') {
    font-size: 4.8rem;
  }
}

%title-30 {
  font-family: $font-george-x;
  font-size: 3rem;

  @include media('<phone') {
    font-size: 5.2rem;
  }
}

%title-53 {
  font-family: $font-helvetica-neue-lt-pro;
  font-size: 5.3rem;
  line-height: 1;
}

%title-60 {
  font-family: $font-george-x;
  font-size: 6rem;
}

%title-100 {
  font-family: $font-george-x;
  line-height: 1;
  font-size: 10rem;

  @include media('<phone') {
    font-size: 12rem;
  }
}

%title-200 {
  font-family: $font-george-x;
  font-size: 20rem;
}

%title-120 {
  font-family: $font-george-x;
  font-size: 12rem;
  line-height: 0.82;
}


%helvetica-15 {

  font-family: $font-helvetica-neue-lt-pro;
  font-size: 1.5rem;

  @include media('<phone') {
    font-size: 2.6rem;
  }

}

%nd-tupa-nova-53 {
  font-family: $font-nd-tupa-nova;
  font-size: 5.3rem;
  line-height: 1;
}

%nd-tupa-nova-170 {
  font-family: $font-nd-tupa-nova;
  font-size: 2.4rem;
  line-height: 1;
}

%opencase-nd-tupa-nova-10 {
  font-family: $font-nd-tupa-nova;
  font-size: 0.8rem;
  line-height: 1;
  color:white;
  
}
