.detail {
  @extend %page;
  @extend %center;
  
  // background-color: $color-quicksand;
  // color: $color-white;

  @include media('<phone') {
    height: auto;
  }
}
.detail__wrapper {
  display: flex;
  align-items: center;

  @include media('<phone') {
    display: block;
    margin: auto;
    padding: 16rem 0;
    width: 66.1rem;
  }
}

.detail__media {
  width: 55.4rem;
  height: 78.2rem;
  position: relative;

  @include media('<phone') {
    width: 100%;
    height: 93.4rem;
    margin: 0 auto 7.8rem;
  }
}

.detail__media__image {
  @extend %cover;

  object-fit: cover;
}

.detail__information {
  padding-left: 8.2rem;

  @include media('<phone') {
    padding-left: 0;
  }
}

.detail__information__collection {
  @extend %description-12;
  margin-bottom: 4rem;
  text-transform: uppercase;
}

.detail__information__title {
  @extend %title-120;

  max-width: 60rem;
}

.detail__information__content {
  padding-left: 18rem;

  @include media('<phone') {
    padding-left: 0;
  }
}
.detail__information__highlights {
  margin-top: 5rem;
}

.detail__information__highlight {
  align-items: center;
  display: flex;

  &:not(:first-child) {
    margin-top: 4rem;
  }
}

.detail__information__highlight__icon {
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  margin-right: 3.75rem;

  @include media('<phone') {
    height: 9.2rem;
    width: 9.2rem;
    margin-right: 5.8rem;
  }
}

.detail__information__highlight__text {
  @extend %description-16;
}

.detail__information__list {
  margin-top: 4rem;
}

.detail__information__item {
  display: flex;

  &:not(:first-child) {
    margin-top: 4rem;
  }
}

.detail__information__item__title {
  @extend %description-12;
  padding-right: 2.5rem;
  width: 10rem;

  @include media('<phone') {
    width: 15rem;
    padding-right: 5rem;
  }
}

.detail__information__item__description {
  @extend %description-16;

  letter-spacing: 0.03rem;
  max-width: 44rem;
}

.detail__information__link {
  margin-top: 3rem;
  display: inline-block;

  @extend %title-30;
}

.detail__button {
  @extend %description-14;

  width: 12.4rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  bottom: 5rem;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;

  @include media('<phone') {
    width: 26.8rem;
    height: 26.8rem;
    bottom: 0;
  }
}

.detail__button__icon {
  @extend %cover;
}
