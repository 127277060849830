.collections {
  @extend %page;

  z-index: 0;

  // background: $color-quicksand;
  // color: $color-white;
}
.collections__wrapper {
  @extend %cover;
  overflow: hidden;
}

.collections__titles {
  @extend %vertical-titles;

  transition: transform 1s ease;

  @include media('<phone') {
    display: none;
  }
}

.collections__titles__label {
  @extend %vertical-titles__label;
}

.collections__titles__title {
  @extend %vertical-titles__title;
}

.collections__gallery {
  @extend %cover;
  z-index: 1;
}

.collections__gallery__wrapper {
  align-items: center;
  display: flex;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-44.7rem * 0.5, -50%);
}

.collections__gallery__link {
  display: block;
  margin: 0 5.3rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.collections__gallery__media {
  height: 63.1rem;
  position: relative;
  width: 44.7rem;
  visibility: hidden;
}

.collections__gallery__media__image {
  @extend %cover;

  object-fit: cover;
}

.collections__content {
  @extend %cover;

  @include media('<phone') {
    display: none;
  }
}

.collections__article {
  position: absolute;
  left: 8rem;
  bottom: 8rem;
  opacity: 0;
  transition: opacity 0.4s ease;

  &--active {
    opacity: 1;
    transition-delay: 0.4s;
  }
}

.collections__article__title {
  @extend %title-60;
}

.collections__article__description {
  @extend %description-16;

  max-width: 46rem;
}

.collections__mobile {
  display: none;
  position: absolute;
  bottom: 5rem;

  @include media('<phone') {
    display: flex;
  }
}

.collections__mobile__item {
  padding-left: 5rem;
  width: 100vw;
}

.collections__mobile__item__label {
  font-size: 2rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.collections__mobile__item__title {
  font-family: $font-george-x;
  font-size: 12rem;
}
