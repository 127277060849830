.places
{
  @extend %page;
  background-color: #ffddcd;
  overflow: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
      display: none;
  }
}


@include media ('<phone') {
  .places {
  @extend %page;
  background-color: #ffddcd;
  overflow: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
      display: none;
  }}
}



.places__wrapper {
  top: 0;
  position: absolute;
  width: 100%;
  overflow:hidden;
  scroll-behavior: smooth;
  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
      display: none;
  }
}


.places::-webkit-scrollbar {
  display: none;
}



@include media ('<phone') {

  .places__wrapper {
  top: 0;
  position: absolute;
  width: 100%;
  overflow:hidden;
  scroll-behavior: smooth;
  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
      display: none;
  }
}
}


.places__title {
  position: relative;
  font-family: $font-nd-tupa-nova;
  font-size: 20rem;
  display: flex;
  align-content: center;
  justify-content: center;
  -webkit-text-stroke: 1px;
  text-shadow: 3px 3px #ff9b9b;
  -webkit-text-stroke-color: #ffc600;
  color: #5a86d4;
  text-align: center; /* center text along x-axis */
}

@include media ('<phone') {

  .places__title {
    position: relative;
    font-family: $font-nd-tupa-nova;
    font-size: 25rem;
    display: flex;
    align-content: center;
    justify-content: center;
    -webkit-text-stroke: 1px;
    text-shadow: 3px 3px #ff9b9b;
    -webkit-text-stroke-color: #ffc600;
    color: #5a86d4;
    padding-top: 18%;
}

}


.places__deliverables {
  position: relative;
  font-size: 2rem;
  color: #002f90;
  width: 26%;
  font-family: $font-helvetica-neue-lt-pro;
  font-weight: 400;
  text-align: center;
  padding-bottom: 0%;
}

@include media ('<phone') {

  .places__deliverables {
    position: relative;
    font-size: 2.5rem;
    color: #002f90;
    width: 76%;
    font-family: $font-helvetica-neue-lt-pro;
    font-weight: 400;
    text-align: center;
    padding-bottom: 9%;
}

}

.container__deliverables {
  display: flex;
  flex-direction: column; /* Stack items along the y-axis */
  justify-content: center;
  align-items: center; /* To center align along x-axis */
  margin-top: 1%;
}

@include media ('<phone') {

  .container__deliverables {
    display: flex;
    flex-direction: column; /* Stack items along the y-axis */
    justify-content: center;
    align-items: center; /* To center align along x-axis */
    margin-top: 1%;
  }
}

.places__objectives {
  position: relative;
  text-align: center;
  font-size: 2rem;
  font-family: $font-helvetica-neue-lt-pro;
  color: #073b98;
  margin-top: 3%;
}

@include media ('<phone') {

  .places__objectives {
    position: relative;
    text-align: center;
    font-size: 3.5rem;
    font-family: $font-helvetica-neue-lt-pro;
    color: #073b98;
    margin-top: 3%;
}
}




.places__intro {
  position: relative;
  width: 55%;
  color: black;
  text-align: left;
  margin-top: 1%;
  font-family: $font-suisse-bp;
  padding-top: 1%;
}


@include media ('<phone') {

  .places__intro {
    position: relative;
    width: 90%;
    color: black;
    text-align: left;
    margin-top: 1%;
    font-family: $font-suisse-bp;
    padding-top: 5%;
  }
}

.places__preproduction {
  position: relative;
  top: 0%;
  color: #073b98;
  font-family: $font-helvetica-neue-lt-pro;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  padding-top: 8%;
}

@include media ('<phone') {

  .places__preproduction {
    position: relative;
    top: 0%;
    color: #073b98;
    font-family: $font-helvetica-neue-lt-pro;
    font-size: 3.5rem;
    display: flex;
    justify-content: center;
    padding-top: 8%;
}}

.places__moodboard {
  position: relative;
  width: 49%;
  margin-top: 0%;
  opacity: 1;
  border-radius: 5%;
  left: 0%;
  z-index: 1;
  margin-left: 0%;
}




@include media ('<phone') {

  .places__moodboard {
    position: relative;
    width: 88%;
    margin-top: -91%;
    opacity: 1;
    border-radius: 5%;
    left: 0%;
    z-index: 1;
    margin-left: 5%;
}

}


.places__caseContainer {
  display: flex;
  flex-direction: column; /* Stack items along the y-axis */
  justify-content: center;
  align-items: center; /* To center align along x-axis */
  margin-top: 1%;
}


@include media ('<phone') {

  .places__caseContainer {
    display: flex;
    flex-direction: column; /* Stack items along the y-axis */
    justify-content: center;
    align-items: center; /* To center align along x-axis */
    margin-top: 1%;
  }
}





.places__storyboard {
  position: relative;
  width: 51%;
  opacity: 1;
  top: 0%;
  align-self: flex-start;
}


@include media ('<phone') {

  .places__storyboard {
    position: relative;
    width: 127%;
    opacity: 1;
    top: 0%;
    transform: translate(-50px, 0px);
}

}




.places__storyoutlines {
  position: relative;
  width: 47%;
  top: 0%;
  opacity: 1;
  margin-left: 0%;
  transform: translate(150px, -21px);
  z-index: 3;
  padding-top: 9%;
}


@include media ('<phone') {

  .places__storyoutlines {
    position: relative;
    width: 100%;
    top: 0%;
    opacity: 1;
    margin-left: 0%;
    transform: translate(56px, 128px);
    z-index: 3;
    padding-top: 0%;
}


}

.places__index {
  position: relative;
  font-family: $font-nd-tupa-nova;
  font-size: 26rem;
  top: 0%;
  color: #5a86d4;
  text-shadow: 1px 1px #ff9b9b, 2px 2px #ff9b9b, 3px 3px #ff9b9b, 4px 4px #ff9b9b, 5px 5px #ff9b9b, 6px 6px #FF9B9B, 7px 7px #ff9b9b, 8px 8px #ff9b9b, 9px 9px #ff9b9b, 10px 10px #ff9b9b;
  margin-left: 39%;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffbdbd;
}

@include media ('<phone') {

  .places__index {
    position: relative;
    font-family: $font-nd-tupa-nova;
    font-size: 26rem;
    top: 0%;
    color: #5a86d4;
    text-shadow: 1px 1px #ff9b9b, 2px 2px #ff9b9b, 3px 3px #ff9b9b, 4px 4px #ff9b9b, 5px 5px #ff9b9b, 6px 6px #FF9B9B, 7px 7px #ff9b9b, 8px 8px #ff9b9b, 9px 9px #ff9b9b, 10px 10px #ff9b9b;
    margin-left: 39%;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffbdbd;
  }

}

.index1{
  margin-left:5%;
}


@include media ('<phone') {

  .index1{
    margin-left:5%;
  }


}

.index2{
  top:0%;

}


@include media ('<phone') {

  .index2{
    top:0%;

  }

}



.moodboardtext {
  position: relative;
  width: 100%;
  top: 0%;
  justify-content: space-evenly;
  display: flex;
  padding-top: 4%;
}

@include media ('<phone') {

  .moodboardtext {
    position: relative;
    width: 100%;
    top: 0%;
    justify-content: space-evenly;
    display: flex;
    padding-top: 35%;
}

}

#processPlaces1 {
  position: relative;
  width: 25%;
  border-radius: 19%;
}


@include media ('<phone') {


  #processPlaces1 {
    position: relative;
    width: 41%;
    border-radius: 19%;
}

}

#processPlaces2 {
  position: relative;
  width: 25%;
  border-radius: 19%;
}

@include media ('<phone') {

  #processPlaces2 {
    position: relative;
    width: 40%;
    border-radius: 19%;
}


}

.placesProcessContainer {
  display: flex;
  position: relative;
  justify-content: center;
  gap: 1%;
  padding-bottom: 10%;
}

@include media ('<phone') {

  .placesProcessContainer {
    display: flex;
    position: relative;
    justify-content: center;
    gap: 7%;
    padding-bottom: 10%;
}
}


.placesVideos {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1%;
  padding-bottom: 0%;
}

@include media ('<phone') {

  .placesVideos {
    display: flex;
    justify-content: center;
    width: 86%;
    flex-direction: column;
}

}


.places__video1 {

position:relative;
padding:22.5%;

}


@include media ('<phone') {

  .places__video1 {
    position: relative;
    margin-bottom: -10%;
    padding: 27.5%;
}


}


.places__video2 {
  position: relative;
  padding: 22.5%;
}
@include media ('<phone') {

.places__video2 {
    position: relative;
    padding: 42%;
}

}



.credits-table {
  width: 100%;
  border-collapse: collapse;
  line-height: 0px;
  display: flex;
  justify-content: center;
  padding-bottom: 3%;
  margin-left: 9%;
}




@include media ('<phone') {

  .credits-table {
    width: 80vw;
    border-collapse: collapse;
    line-height: 5px;
    display: flex;
    justify-content: center;
    margin-left: 5%;
    font-size: 3rem;
    padding-bottom: inherit;
}


}




.credits-table td.role {
  padding: inherit;
  font-weight: 600;
  color: black;
}

@include media ('<phone') {

  .credits-table td.role {
    padding: inherit;
    font-weight: 600;
    color: black;
  }

}

.credits-table td.name {
  padding-bottom: 8px;
  font-weight: 300;
  color: black;
  font-family: $font-suisse-bp;
  transform: translate(-196px, 0px);
  width: 39%;
  line-height: 122%;
}




@include media ('<phone') {

  .credits-table td.name {
    padding: 10px;
    font-weight: 300;
    color: black;
    font-family: $font-suisse-bp;
    transform: translate(27px, 0px);
  }

}


.placesCredits {
  position:relative;
  display:flex;
  justify-content: center;
}

@include media ('<phone') {

  .placesCredits {
    position:relative;
    display:flex;
    justify-content: center;
  }

}

.places__storyboardContainer{
  position: relative;
  display: flex;
  justify-content: center;
}


@include media ('<phone') {

  .places__storyboardContainer{
    position: relative;
    display: flex;
    justify-content: center;
  }

}



.places__storyboardText {
  position: relative;
  justify-content: flex-end;
  top: 0;
  color: black;
  padding-top: 10%;
  width: 27%;
  margin-left: -4%;
}


@include media ('<phone') {


  .places__storyboardText {
    position: relative;
    justify-content: flex-end;
    top: 0;
    color: black;
    padding-top: 76%;
    width: 100%;
    margin-left: -120%;
    padding-bottom: 10%;
}
}


.places__Role {
  font-family: $font-suisse-bp;
  position: relative;
  color: black;
  justify-content: center;
  display: flex;
  height: 100%;
  flex-direction: row;
}


@include media ('<phone') {


  .places__Role {
    font-family: $font-suisse-bp;
    position: relative;
    color: black;
    justify-content: center;
    display: flex;
    height: 100%;
    flex-direction: row;
  }
}



.places__RoleText {
  color: black;
  font-family: $font-helvetica-neue-lt-pro;
  font-size: 2rem;
  top: 0%;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}


@include media ('<phone') {

  .places__RoleText {
    color: black;
    font-family: $font-helvetica-neue-lt-pro;
    font-size: 2rem;
    top: 0%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }
}



.places__text {
  font-family: $font-suisse-bp;
  color: black;
  position: relative;
  top: 0%;
  width: 42%;
  display: flex;
  justify-content: center;
  font-weight: 300;
  padding-bottom: 5%;
}

@include media ('<phone') {

  .places__text {
    font-family: $font-suisse-bp;
    color: black;
    position: relative;
    top: 0%;
    width: 86%;
    display: flex;
    justify-content: center;
    font-weight: 300;
    padding-bottom: 5%;
}


}

.places__Role {
  font-family: $font-suisse-bp;
  position: relative;
  color: black;
  justify-content: center;
  display: flex;
  height: 100%;
  margin-top: 1%;
}


@include media ('<phone') {

  .places__Role {
    font-family: $font-suisse-bp;
    position: relative;
    color: black;
    justify-content: center;
    display: flex;
    height: 100%;
    margin-top: 1%;
  }

}