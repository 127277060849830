%description-12 {
  font-size: 1.2rem;

  @include media('<phone') {
    font-size: 3rem;
  }
}

%description-16 {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.5;

  @include media('<phone') {
    font-size: 2.8rem;
  }
}

%description-14 {
  font-size: 1.4rem;

  @include media('<phone') {
    font-size: 3rem;
  }
}



%description-helvetica-12 {
  font-size: 0.6rem;
  font-family: $font-helvetica-neue-lt-pro;
  line-height: 1.5;

  @include media('<phone') {
    font-size: 3rem;
  }
}



