/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.detail__button__icon, .detail__media__image, .collections__content, .collections__gallery__media__image, .collections__gallery, .collections__wrapper, .about__highlight__media__image, .about__gallery__media__image, .about__content__media__image, .home__link__icon, .home__gallery, .navigation, .preloader, .content, body, html, .inlabschibsted, .places, .thepark, .theNegroSpaceProgram, .detail, .collections, .about, .home {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.detail, .preloader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dg.ac {
  z-index: 99999 !important;
}

.detail__information__item__title, .detail__information__collection, .about__highlight__label, .about__content__label, .collections__titles__label, .home__titles__label {
  font-size: 1.2rem;
}
@media (max-width: 767px) {
  .detail__information__item__title, .detail__information__collection, .about__highlight__label, .about__content__label, .collections__titles__label, .home__titles__label {
    font-size: 3rem;
  }
}

.detail__information__item__description, .detail__information__highlight__text, .collections__article__description, body {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .detail__information__item__description, .detail__information__highlight__text, .collections__article__description, body {
    font-size: 2.8rem;
  }
}

.detail__button, .preloader__number {
  font-size: 1.4rem;
}
@media (max-width: 767px) {
  .detail__button, .preloader__number {
    font-size: 3rem;
  }
}

.navigation__list__link {
  font-family: "Helvetica Neue LT Pro";
  font-size: 2rem;
}
@media (max-width: 767px) {
  .navigation__list__link {
    font-size: 4.8rem;
  }
}

.detail__information__link {
  font-family: "George X";
  font-size: 3rem;
}
@media (max-width: 767px) {
  .detail__information__link {
    font-size: 5.2rem;
  }
}

.preloader__text span span {
  font-family: "Helvetica Neue LT Pro";
  font-size: 5.3rem;
  line-height: 1;
}

.collections__article__title {
  font-family: "George X";
  font-size: 6rem;
}

.about__highlight__title, .collections__titles__title, .home__titles__title {
  font-family: "George X";
  font-size: 20rem;
}

.detail__information__title {
  font-family: "George X";
  font-size: 12rem;
  line-height: 0.82;
}

.processGallery__wip, .home-nav {
  font-family: "Helvetica Neue LT Pro";
  font-size: 1.5rem;
}
@media (max-width: 767px) {
  .processGallery__wip, .home-nav {
    font-size: 2.6rem;
  }
}

.processGallery__wip li, .home_nav li, .navigation__list__link {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}

.processGallery__wip li:after, .home_nav li:after, .navigation__list__link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: currentColor;
  transition: transform 0.7s cubic-bezier(0.77, 0, 0.175, 1);
}

.processGallery__wip li:hover:after, .home_nav li:hover:after, .navigation__list__link:hover:after {
  transform: scaleX(1);
  transform-origin: left center;
}

.processGallery__wip li:after, .home_nav li:after, .navigation__list__link:after {
  transform: scaleX(0);
  transform-origin: right center;
}

.processGallery__wip li, .home_nav li, .navigation__list__link {
  display: inline-block;
}
.inlabschibsted, .places, .thepark, .theNegroSpaceProgram, .detail, .collections, .about, .home {
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.collections__titles, .home__titles {
  position: absolute;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  display: flex;
}

.collections__titles__label, .home__titles__label {
  transform: rotate(90deg);
  text-align: center;
  text-transform: uppercase;
  margin: 0 3rem;
  letter-spacing: 0.1;
}
@media (max-width: 767px) {
  .collections__titles__label, .home__titles__label {
    font-size: 1.2rem;
  }
}

.collections__titles__title, .home__titles__title {
  padding-top: 2rem;
}

@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .preface {
    top: 13vh;
  }

  .festival_notification {
    margin-top: -28%;
    margin-left: 70%;
  }

  .access {
    margin-top: 5%;
    margin-left: 0%;
  }

  button#arrowToAssets {
    margin-top: 142vh;
    transform: scale(2);
    margin-left: -9%;
  }

  svg#arrow2BackToContext {
    top: 84vh;
  }

  .animatic {
    top: 19vh;
  }

  #storyboardCharacterRun {
    top: 16vh;
  }

  .storyboard__Title, .menu__ac {
    margin-top: 36.5%;
    bottom: -102.4vh;
  }

  .poem {
    top: 40vh;
  }

  .subTitle__Storyboard {
    top: 31vh;
  }

  #storyboardVideo {
    margin-top: 19%;
  }

  #indicator-container {
    margin-top: 3%;
  }

  #arrowBackToProcess, #arrowToNextSlide {
    padding-top: 24vh;
  }

  .navtitle__character2 {
    margin-top: -3vh;
    top: 0vh;
  }

  .o.o3 {
    margin-top: -12vh;
    margin-left: 1.5%;
  }

  h2.navtitle__character1 {
    top: -1.75vh;
    width: 150%;
  }

  p.navdescription__character1 {
    top: 0.25vh;
    width: 111%;
  }

  .o4 {
    margin-top: -21vh;
  }

  h2.navtitle__spacecapsule {
    margin-top: -10vh;
    top: 4.5vh;
    left: -4.3vh;
  }
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .preface {
    top: 22vh;
  }

  .festival_notification {
    top: -13vh;
  }

  .case4 {
    margin-left: 61%;
    left: 4%;
  }

  .access {
    margin-top: -2vh;
    margin-left: -2vw;
  }

  button#arrowToAssets {
    margin-top: 95vh;
  }

  svg#arrow2BackToContext {
    margin-top: 3%;
  }

  .animatic {
    top: 25vh;
  }

  #storyboardCharacterRun {
    top: 27vh;
  }

  .storyboard__Title, .menu__ac {
    margin-bottom: 0vh;
  }

  .poem {
    margin-top: 3%;
  }

  .subTitle__Storyboard {
    margin-top: 8%;
  }

  #storyboardVideo {
    top: 7vh;
  }

  .processText {
    top: -2vh;
    left: -4vw;
  }

  #indicator-container {
    margin-top: 3%;
  }

  #arrowBackToProcess, #arrowToNextSlide {
    padding-top: 26vh;
  }

  .navtitle__character2 {
    width: 102%;
    margin-top: -3vh;
    top: -1vh;
  }

  .o2 {
    margin-top: -15vh;
  }

  .o.o3 {
    margin-top: -21vh;
  }

  h2.navtitle__character1 {
    top: -0.75vh;
  }
  h2.navtitle__character1 p.navdescription__character1 {
    top: 0.25vh;
  }

  .o4 {
    margin-top: -21vh;
  }

  h2.navtitle__spacecapsule {
    margin-top: -13vh;
    top: 7vh;
    left: -6vh;
  }
}
@media screen and (device-width: 1680px) and (device-height: 1050px) {
  .preface {
    top: 22vh;
  }

  .festival_notification {
    top: -13vh;
  }

  .access {
    margin-top: -2vh;
    margin-left: -2vw;
  }

  button#arrowToAssets {
    margin-top: 91vh;
  }

  svg#arrow2BackToContext {
    margin-top: 3%;
  }

  .animatic {
    top: 25vh;
  }

  #storyboardCharacterRun {
    top: 27vh;
  }

  .storyboard__Title, .menu__ac {
    margin-bottom: 0vh;
  }

  .poem {
    margin-top: 3%;
  }

  .subTitle__Storyboard {
    margin-top: 8%;
  }

  #storyboardVideo {
    top: 7vh;
  }

  .processText {
    top: 1.5vh;
    left: -1vw;
  }

  #indicator-container {
    margin-top: 3%;
  }

  #arrowBackToProcess, #arrowToNextSlide {
    padding-top: 24vh;
  }

  .navtitle__character2 {
    width: 102%;
    margin-top: -3vh;
    top: 0vh;
  }

  .o.o3 {
    margin-top: -12vh;
    margin-left: -1.5%;
  }

  h2.navtitle__character1 {
    top: -0.75vh;
  }
  h2.navtitle__character1 p.navdescription__character1 {
    top: 0.25vh;
  }

  .o4 {
    margin-top: -21vh;
  }

  h2.navtitle__spacecapsule {
    margin-top: -15vh;
    top: 12.9vh;
    left: -9vh;
  }
}
@media screen and (device-width: 1440px) and (device-height: 900px) {
  .preface {
    top: 22vh;
  }

  .festival_notification {
    margin-top: -28%;
    margin-left: 70%;
  }

  .access {
    margin-top: 5%;
    margin-left: 0%;
  }

  button#arrowToAssets {
    margin-top: 91vh;
  }

  svg#arrow2BackToContext {
    top: 84vh;
  }

  .animatic {
    top: 19vh;
  }

  #storyboardCharacterRun {
    top: 24vh;
  }

  .storyboard__Title, .menu__ac {
    margin-top: 36.5%;
    bottom: -102.4vh;
  }

  .poem {
    top: 40vh;
  }

  .subTitle__Storyboard {
    top: 31vh;
  }

  #storyboardVideo {
    margin-top: 19%;
  }

  .processText {
    top: 3.5vh;
    left: 0vw;
  }

  #indicator-container {
    margin-top: 3%;
  }

  #arrowBackToProcess, #arrowToNextSlide {
    padding-top: 24vh;
  }

  .navtitle__character2 {
    width: 102%;
    margin-top: -3vh;
    top: 0vh;
  }

  .o.o3 {
    margin-top: -12vh;
    margin-left: -1.5%;
  }

  h2.navtitle__character1 {
    top: -0.75vh;
  }
  h2.navtitle__character1 p.navdescription__character1 {
    top: 0.25vh;
  }

  .o4 {
    margin-top: -21vh;
  }

  h2.navtitle__spacecapsule {
    margin-top: -15vh;
    top: 15.5vh;
    left: -10vh;
  }
}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 29, 2021 */
@font-face {
  font-family: "George X";
  src: url("../fonts/george_x-webfont.woff2") format("woff2"), url("../fonts/george_x-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Suisse BP";
  src: url("../fonts/suissebpintl-light-webfont.woff2") format("woff2"), url("../fonts/suissebpintl-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Suisse BP";
  src: url("../fonts/suissebpintl-regular-webfont.woff2") format("woff2"), url("../fonts/suissebpintl-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Suisse BP";
  src: url("../fonts/suissebpintl-ultralight-webfont.woff2") format("woff2"), url("../fonts/suissebpintl-ultralight-webfont.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Nd Tupa Nova";
  src: url("../fonts/NdTupaNovaTrial-Heavy.woff2") format("woff2"), url("../fonts/NdTupaNovaTrial-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Neue LT Pro";
  src: url("../fonts/HelveticaNeueLTPro-MdEx.woff2") format("woff2"), url("../fonts/HelveticaNeueLTPro-MdEx.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "HelveticaNeueLT Pro 43 LtEx";
  src: url("../fonts/HelveticaNeueLTPro-LtEx.woff2") format("woff2"), url("../fonts/HelveticaNeueLTPro-LtEx.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
*,
*::after,
*::before {
  box-sizing: border-box;
  user-select: none;
  cursor: auto;
}

html {
  font-size: 0.5208333333vw;
  position: fixed;
  line-height: 1;
  overflow: hidden;
  background: #2D81E0;
  color: #f9f1e7;
}
@media (max-width: 767px) {
  html {
    font-size: 1.3333333333vw;
  }
}

body {
  font-family: "Suisse BP";
  position: fixed;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

button {
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  outline: none;
}

img {
  opacity: 0;
  transition: opacity 0.4s ease;
  vertical-align: middle;
}
img.loaded {
  opacity: 1;
}

canvas {
  pointer-events: none;
  position: relative;
  z-index: 1;
}

[data-animation=paragraph] span,
[data-animation=label] span,
[data-animation=title] span {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}

.word {
  display: inline-block;
  margin-right: 0.25em;
  /* Adjust this value for the desired spacing between words */
}

.preloader {
  background: #2D81E0;
  color: #ffffff;
  text-align: center;
  z-index: 4;
  overflow: hidden;
  cursor: none;
}

.preloader__text {
  opacity: 0;
}

.preloader__text {
  width: 100%;
  font-size: 5.2rem;
  max-width: 200rem;
  line-height: 1.5;
}
.preloader__text span {
  display: inline-block;
  opacity: 1;
}
.preloader__text span span {
  margin-right: 0em;
}
@media (max-width: 767px) {
  .preloader__text {
    font-size: 2rem;
    width: 100vw;
  }
}

.preloader__number {
  position: absolute;
  overflow: hidden;
  bottom: 5.1rem;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .preloader__number {
    font-size: 2.5rem;
    margin-top: 0%;
    bottom: 15%;
  }
}

.preloader__number__text {
  display: inline-block;
}

.space-span {
  display: inline-block;
  width: 0.5em;
}

.navigation {
  color: #f9f1e7;
  z-index: 3;
  position: fixed;
  pointer-events: none;
}

.navigation__link {
  font-size: 0;
  position: absolute;
  left: 8rem;
  top: 4rem;
  width: 12.8rem;
  pointer-events: auto;
  height: 2rem;
  transition: opacity 0.4s ease;
}
.navigation__link:hover {
  opacity: 0.5;
}
@media (max-width: 767px) {
  .navigation__link .navigation__link {
    left: 5rem;
    top: 5rem;
    width: 18.9rem;
    height: 4.3rem;
    pointer-events: auto;
    /* Add this line */
  }
}

.navigation__link__icon {
  display: block;
  color: white;
}

.navigation__list {
  left: 4rem;
}

.navigation__list__item {
  position: fixed;
  right: 4rem;
  top: 4rem;
  justify-content: right;
  pointer-events: auto;
}

.navigation__list__link {
  color: white;
  display: block;
  line-height: 1;
}

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.header-canvas {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin-left: 0%;
  margin-top: 0%;
}

.video-hidden {
  opacity: 0;
}

video {
  transition: opacity 0.5s;
}

.mycredits {
  position: absolute;
  z-index: 2;
  height: 100;
  width: 100%;
  margin-left: 4%;
  font-size: 1.5rem;
  bottom: 1%;
  font-family: "Helvetica Neue LT Pro";
}

@media (max-width: 767px) {
  .mycredits {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    height: auto;
    align-items: flex-end;
  }
}
@keyframes animateHeight {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
.vertical__line {
  position: sticky;
  left: 0;
  width: 45px;
  background: white;
  background: -webkit-linear-gradient(180deg, white -45%, #ff6464 0%, #ff4a4a 66%, rgba(255, 195, 195, 0) 100%);
  background: -moz-linear-gradient(180deg, white -45%, #ff6464 0%, #ff4a4a 66%, rgba(255, 195, 195, 0) 100%);
  background: linear-gradient(180deg, white -45%, #ff6464 0%, #ff4a4a 66%, rgba(255, 195, 195, 0) 100%);
  z-index: 2;
  animation: animateHeight 2s;
  /* 2 seconds */
  animation-fill-mode: forwards;
  height: 0;
}

@media (max-width: 767px) {
  .vertical__line {
    visibility: hidden;
    display: none;
  }
}
@media (max-width: 1023px) {
  .vertical__line {
    visibility: hidden;
    display: none;
  }
}
.home {
  overflow: hidden;
  text-align: center;
}
.home::before, .home::after {
  content: "";
  height: 24rem;
  width: 100%;
  z-index: 1;
  position: fixed;
  left: 0;
  pointer-events: all;
}
.home::before {
  overflow: hidden;
  background: #2d81e0;
  background: -moz-linear-gradient(0deg, rgba(201, 113, 100, 0) 0%, #2D81E0 100%, #2d81e0 100%);
  background: -webkit-linear-gradient(0deg, rgba(201, 113, 100, 0) 0%, #2d81e0 100%, #2D81E0 201, 113, 100, 1 100%);
  background: linear-gradient(0deg, rgba(201, 113, 100, 0) 0%, #2D81E0 100%, #2d81e0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c97164",endColorstr="#c97164",GradientType=1);
  top: 0;
  pointer-events: all;
}
.home::after {
  overflow: hidden;
  background: #2d81e0;
  background: -moz-linear-gradient(180deg, rgba(255, 34, 0, 0) 0%, #2D81E0 100%, #2D81E0 100%);
  background: -webkit-linear-gradient(180deg, rgba(201, 113, 100, 0) 0%, #2D81E0 100%, #2D81E0 100%);
  background: linear-gradient(180deg, rgba(201, 113, 100, 0) 0%, #ffc3c3 100%, #ffc3c3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c97164",endColorstr="#c97164",GradientType=1);
  bottom: 0;
  pointer-events: all;
}

.home__wrapper {
  height: 100%;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.home__wrapper ::-webkit-scrollbar {
  display: none;
}

.home__gallery {
  visibility: hidden;
  width: 250.8rem;
  height: 172.5rem;
  background-color: transparent;
}

.bg {
  display: none;
  position: absolute;
  transition: opacity 0.25s;
  background-color: #2d81e0;
  /* here is the new line */
  /* here is the new line */
}

@media (max-width: 767px) {
  .bg {
    display: block;
    background-color: #2d81e0;
    width: 1200%;
    height: 1200%;
    transition: opacity 0.25s;
    opacity: 1;
    position: fixed;
  }
}
.home__gallery__media {
  position: absolute;
  background-color: transparent;
  visibility: hidden;
}
.home__gallery__media:nth-child(1) {
  width: 27.8rem;
  height: 41.8rem;
  top: 16.6rem;
  left: 9.4rem;
  background-color: transparent;
}
.home__gallery__media:nth-child(2) {
  width: 27.8rem;
  height: 41.8rem;
  top: 19.4rem;
  left: 54rem;
  background-color: transparent;
}
.home__gallery__media:nth-child(3) {
  width: 27.8rem;
  height: 41.8rem;
  top: 10.9rem;
  left: 106.3rem;
  background-color: transparent;
}
.home__gallery__media:nth-child(4) {
  width: 40.6rem;
  height: 60.8rem;
  top: 0;
  left: 154.1rem;
  background-color: transparent;
}
.home__gallery__media:nth-child(5) {
  width: 27.8rem;
  height: 41.8rem;
  top: 10.6rem;
  left: 207rem;
  background-color: transparent;
}
.home__gallery__media:nth-child(6) {
  width: 37.5rem;
  height: 52.3rem;
  top: 69.3rem;
  left: 0;
  background-color: transparent;
}
.home__gallery__media:nth-child(7) {
  width: 40.6rem;
  height: 60.8rem;
  top: 66.4rem;
  left: 46.7rem;
  background-color: transparent;
}
.home__gallery__media:nth-child(8) {
  width: 39.7rem;
  height: 58.2rem;
  top: 64.9rem;
  left: 101.4rem;
  background-color: transparent;
}
.home__gallery__media:nth-child(9) {
  width: 27.8rem;
  height: 41.8rem;
  top: 64rem;
  left: 160rem;
  background-color: transparent;
}
.home__gallery__media:nth-child(10) {
  width: 39.7rem;
  height: 58.2rem;
  top: 64.9rem;
  left: 202.1rem;
  background-color: transparent;
}
.home__gallery__media:nth-child(11) {
  width: 27.8rem;
  height: 41.8rem;
  top: 129.1rem;
  left: 13rem;
  background-color: transparent;
}
.home__gallery__media:nth-child(12) {
  width: 27.8rem;
  height: 41.8rem;
  top: 130.6rem;
  background-color: transparent;
}
.home__gallery__media:nth-child(14) {
  width: 27.8rem;
  height: 41.8rem;
  top: 111.1rem;
  background-color: transparent;
}
.home__gallery__media:nth-child(15) {
  width: 27.8rem;
  height: 41.8rem;
  top: 128.8rem;
  background-color: transparent;
}

.home__gallery__media__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s;
}

.home__link {
  letter-spacing: 0.05;
  width: 28.84em;
  height: 6rem;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  text-transform: uppercase;
  bottom: 10rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.home__link .explore-btn {
  font-family: "Helvetica Neue LT Pro";
  font-size: 4rem;
  z-index: 3;
}

@media (max-width: 767px) {
  .home__link {
    bottom: 20rem;
  }
}
.header__wrapper {
  right: 2%;
  position: absolute;
}

.nav__wrapper {
  scroll-behavior: smooth;
  scroll-snap-align: end;
  height: 100vh;
  z-index: 0;
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.nav__wrapper.visible {
  opacity: 1;
}

@media (max-width: 767px) {
  .nav {
    display: none;
    pointer-events: none;
  }
}
@media (max-width: 1023px) {
  .nav {
    display: none;
    pointer-events: none;
  }
}
.nav__bg {
  position: absolute;
  top: 80%;
  width: 300%;
  height: 200%;
  background: #2d81e0;
  background: linear-gradient(180deg, rgba(45, 129, 224, 0.366625874) 0%, rgba(45, 129, 224, 0) 0%, #2d81e0 7%, #2d81e0 100%);
  background: -webkit-linear-gradient(180deg, rgba(45, 129, 224, 0.366625874) 0%, rgba(45, 129, 224, 0) 0%, #2d81e0 7%, #2d81e0 100%);
  background: -moz-linear-gradient(180deg, rgba(45, 129, 224, 0.366625874) 0%, rgba(45, 129, 224, 0) 0%, #2d81e0 7%, #2d81e0 100%);
  z-index: -1;
  opacity: 1;
}

#wrap {
  position: relative;
  width: 200%;
  height: 100%;
  top: 100vh;
  left: 0%;
  opacity: 0;
  transition: opacity 0.5s;
}
@media only screen and (min-width: 992px) {
  #wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    align-items: flex-start;
    margin-left: -5%;
    text-align: left;
    top: 99vh;
  }
}
@media (max-width: 767px) {
  #wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    align-items: flex-start;
    margin-left: -19%;
    text-align: left;
    top: 99vh;
  }
}

.n2 {
  margin-top: 7%;
}
@media (max-width: 1023px) {
  .n2 {
    top: 43%;
    margin-left: 90%;
  }
}
@media (max-width: 767px) {
  .n2 {
    top: 43%;
    margin-left: 66%;
  }
}

.n0, .n1, .n2, .n3, .n4, .n5 {
  pointer-events: all;
  z-index: 1001;
}

.n {
  scroll-snap-align: start;
  position: absolute;
  top: 30%;
  width: 230px;
  transition: opacity 0.1s;
  opacity: 1;
}
@media (max-width: 1023px) {
  .n {
    margin-left: 24%;
    margin-top: 9%;
  }
}

@media (max-width: 767px) {
  .n {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 75%;
    margin-top: -6%;
    text-align: left;
  }
}
.home-nav {
  font-size: 4rem;
  position: absolute;
  top: 150%;
  /* adjust this to 50% */
  right: 3.5vh;
  z-index: 100;
  border: none;
  width: 300px;
  list-style: none;
  text-align: right;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column-reverse;
  opacity: 0;
  transform: translateY(-50%);
  /* adjust this to -50% */
}

.onTour {
  font-size: 0.75rem;
  font-family: "Helvetica Neue LT Pro";
}

@media (max-width: 767px) {
  .onTour {
    display: block;
    pointer-events: none;
  }
}
.onTourBox {
  width: 6rem;
  background-color: #0f2669;
  z-index: -1;
  margin-lefT: 0%;
  margin-top: 2%;
  border-radius: 2px;
  z-index: 1;
  padding-left: 1.5%;
  transition: background-color 0.3s;
}

.onTourBox:hover {
  background-color: #1bbe2c;
}

#sound {
  position: relative;
  opacity: 1;
  width: 3.5%;
  z-index: 1003;
  pointer-events: all;
  left: 14%;
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  #sound {
    top: 0vh;
    left: 21vw;
  }
}

@media (max-width: 767px) {
  #sound {
    display: none;
  }
}
@media (max-width: 767px) {
  .onTourBox {
    position: relative;
    height: 1rem;
    width: 6rem;
    background: #00d32a;
    z-index: 1;
    margin-lefT: 0%;
    margin-top: -2%;
    border-radius: 2px;
    z-index: -1;
    padding-left: 1%;
  }
}
.home_nav li {
  font-size: 35px;
  border: none;
  color: white;
  transition: color 1s;
}

@media (max-width: 767px) {
  ul.home_nav li {
    display: none;
    pointer-events: none;
  }
}
li:hover {
  color: #ffeab9;
  border: 2px;
}

@media (max-width: 767px) {
  li:hover {
    display: none;
    pointer-events: none;
  }
}
#top-btn {
  font-family: "Nd Tupa Nova";
  font-size: 5.3rem;
  position: absolute;
  top: 138%;
  right: 16%;
  fill: #2D81E0;
  stroke: white;
  stroke-width: 1px;
  z-index: 20;
  border: none;
  width: 300px;
  list-style: none;
  text-align: right;
  margin: 0;
  padding: 0;
  opacity: 0.4;
  transition: all 1s ease;
}

.topBtnCircle {
  display: none;
}

@media (max-width: 767px) {
  .topBtnCircle {
    display: block;
    fill: white;
  }
}
@media (max-width: 767px) {
  #top-btn {
    font-family: "Nd Tupa Nova";
    font-size: 5.3rem;
    position: absolute;
    top: 169%;
    right: 9%;
    fill: #2D81E0;
    stroke: white;
    stroke-width: 1px;
    z-index: 20;
    border: none;
    width: 100px;
    list-style: none;
    text-align: right;
    margin: 0;
    padding: 0;
    opacity: 1;
    transition: all 1s ease;
    fill: white;
  }
}
@media (max-width: 1023px) {
  #top-btn {
    left: 63%;
    top: 164%;
  }
}
#top-btn:hover {
  fill: white;
  stroke-width: 0;
  opacity: 1;
}

#top-btn.visible {
  opacity: 1;
}

.arrow__icon {
  position: absolute;
  z-index: 20;
  left: 45%;
  width: 100%;
  pointer-events: all;
}

#block {
  position: absolute;
  height: 100px;
  width: 100px;
  background: black;
  z-index: 5;
}

#container {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  z-index: -1;
  pointer-events: none;
}

.iconsContainer {
  display: flex;
  height: 100%;
}

.social-media-icons {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 42.5%;
  left: 60%;
  z-index: 2;
  pointer-events: none;
}

@media screen and (device-width: 1680px) and (device-height: 1050px) {
  .social-media-icons {
    left: 54%;
  }
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .social-media-icons {
    left: 54%;
  }
}
@media (max-width: 767px) {
  .social-media-icons {
    display: none;
  }
}
#MailIcon {
  position: absolute;
  height: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  z-index: 2;
  pointer-events: visible;
}

#InstagramIcon {
  position: absolute;
  height: 100%;
  height: 100%;
  top: 0%;
  left: 5%;
  z-index: 2;
  pointer-events: visible;
}

#linkedInIcon {
  position: absolute;
  height: 100%;
  height: 100%;
  top: 0%;
  left: 10%;
  z-index: 2;
  pointer-events: visible;
}

#MailIcon,
#InstagramIcon,
#linkedInIcon {
  transition: transform 0.15s ease-in-out;
}

#MailIcon:hover,
#InstagramIcon:hover,
#linkedInIcon:hover {
  transform: scale(0.1);
}

@media (max-width: 767px) {
  .social-media-icons, #MailIcon, #InstagramIcon, #linkedInIcon {
    display: none;
    pointer-events: none;
  }
}
.openCase {
  font-family: "Nd Tupa Nova";
  background: #ff6464;
  border-radius: 1.5px;
  padding: 0px 3px 1px;
  font-size: 1.4rem;
  box-shadow: 0 1px #000000, 0 1.05px #000000, 1.05px 1px #000000, 1.05px 1.05px #000000, 1.1px 1.1px #000000, 1.1px 1.15px #000000, 1.15px 1.2px #000000, 1.15px 1.25px #000000, 1.2px 1.3px #000000;
  -webkit-text-stroke: black;
  -webkit-text-stroke-width: 0.1px;
  transition: background-color 0.3s;
}
@media (max-width: 1023px) {
  .openCase {
    margin-top: 0%;
    margin-left: 0;
  }
}

.case1, .case2, .case3, .case4, .case5 {
  position: relative;
}
@media (max-width: 1023px) {
  .case1, .case2, .case3, .case4, .case5 {
    left: -76%;
  }
}
@media (max-width: 767px) {
  .case1, .case2, .case3, .case4, .case5 {
    left: 0%;
  }
}

@media (max-width: 1023px) {
  h2.navtitle__INLabSchibsted {
    margin-top: 41%;
  }
}
.openCase:hover {
  background-color: orangered;
  /* Change the background color on hover */
}

.case1 {
  position: relative;
  margin-top: 2%;
  margin-left: 65%;
  font-size: 1.4rem;
}

@media (max-width: 1023px) {
  .case1 {
    top: 0%;
    left: -85%;
  }
}
@media (max-width: 767px) {
  .case1 {
    position: relative;
    margin-top: 2%;
    left: -74%;
    font-size: 1.4rem;
    top: -1vh;
  }
}
.case2 {
  position: relative;
  margin-left: 65%;
  font-size: 1.4rem;
  pointer-events: all;
  z-index: 1001;
}

@media (max-width: 767px) {
  .case2 {
    position: relative;
    margin-top: 0%;
    margin-left: 0%;
    padding-left: 5px;
    padding-right: 5px;
    width: fit-content;
    transform: translate(0px, -8px);
  }
}
.case3 {
  position: relative;
  margin-top: 3%;
  padding-left: 5px;
  padding-right: 5px;
  width: fit-content;
  margin-left: 65%;
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .case3 {
    left: 4%;
    margin-left: 61%;
  }
}

@media (max-width: 767px) {
  .case3 {
    position: relative;
    margin-top: 0%;
    padding-left: 5px;
    padding-right: 5px;
    width: fit-content;
    font-size: 1.4rem;
    margin-left: 0%;
    transform: translate(0px, -10px);
  }
}
.case4 {
  margin-top: 32%;
  padding-left: 5px;
  padding-right: 5px;
  width: fit-content;
  margin-left: 65%;
  font-size: 1.4rem;
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .case4 {
    margin-left: 61%;
    left: 4%;
  }
}

@media (max-width: 767px) {
  .case4 {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 76%;
  }
}
@media (max-width: 1023px) {
  .case4 {
    position: relative;
    margin-top: 0%;
    padding-left: 5px;
    padding-right: 5px;
    width: fit-content;
    left: -53%;
  }
}
.case5 {
  position: absolute;
  padding-left: 5px;
  padding-right: 5px;
  width: fit-content;
  margin-top: 0%;
  margin-left: 14%;
  font-size: 1.4rem;
  transform: translate(0px, -11px);
}

@media (max-width: 767px) {
  .case5 {
    position: relative;
    margin-top: 0%;
    margin-left: 0%;
    padding-left: 5px;
    padding-right: 5px;
    width: fit-content;
    font-size: 1.4rem;
  }
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .case4 {
    margin-left: 61%;
    left: 4%;
  }
}
.n0 {
  margin-top: -4%;
  margin-left: 0%;
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .n0 {
    margin-top: 0%;
  }
}
@media (max-width: 767px) {
  .n0 {
    margin-top: 0%;
    margin-left: 33%;
  }
}
@media (max-width: 1023px) {
  .n0 {
    top: 38%;
    left: 29%;
  }
}

/* Columns */
.n1 {
  top: 46%;
  left: 0%;
}
@media (max-width: 767px) {
  .n1 {
    margin-top: 10%;
    margin-left: 63%;
  }
}
@media (max-width: 1023px) {
  .n1 {
    top: 43%;
    left: 0%;
  }
}

.n3 {
  top: 60%;
}
@media (max-width: 767px) {
  .n3 {
    top: 45%;
    left: 50%;
  }
}
@media (max-width: 1023px) {
  .n3 {
    margin-top: 6%;
    margin-left: 13%;
  }
}

.n4 {
  top: 75%;
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .n4 {
    top: 66%;
  }
}
@media (max-width: 1023px) {
  .n4 {
    top: 69%;
  }
}
@media (max-width: 767px) {
  .n4 {
    top: 58%;
    left: 26%;
  }
}

.n5 {
  top: 70%;
}

.navtitle__places {
  position: relative;
  margin-top: 27%;
  left: -9%;
}

@media (max-width: 1023px) {
  .navdescription__Mountains {
    left: -65%;
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  .navdescription__Mountains {
    left: -65%;
    font-size: 0.75rem;
  }
}

@media only screen and (min-width: 992px) {
  .navtitle__INLabSchibsted, .navtitle__Mountains, .navtitle__thenegrospaceprogram, .navtitle__ThePark, .navtitle__places {
    text-shadow: 0 1px #000000, 0 1.05px #000000, 1.05px 1px #000000, 1.05px 1.05px #000000, 1.1px 1.1px #000000, 1.1px 1.15px #000000, 1.15px 1.2px #000000, 1.15px 1.25px #000000, 1.2px 1.3px #000000;
  }

  .navtitle__places {
    position: relative;
    top: 0%;
    left: 65%;
    z-index: 1;
    opacity: 1;
    color: #FF6464;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: black;
    font-family: "Nd Tupa Nova";
    font-size: 2.7rem;
  }

  .navtitle__ThePark {
    position: relative;
    top: 0%;
    left: 65%;
    z-index: 1;
    opacity: 1;
    color: #FF6464;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: black;
    font-family: "Nd Tupa Nova";
    font-size: 2.7rem;
  }

  .navdescription__places {
    font-family: "Helvetica Neue LT Pro";
    height: 100%;
    width: 100%;
    position: relative;
    font-size: 1rem;
    margin-top: 2%;
    margin-left: 65%;
    z-index: 1;
    opacity: 1;
    color: #ffffff;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
    font-size: 0.7rem;
  }

  .navdescription__ThePark {
    font-family: "Helvetica Neue LT Pro";
    height: 100%;
    width: 68%;
    position: relative;
    font-size: 1rem;
    margin-top: 2%;
    margin-left: 65%;
    z-index: 1;
    opacity: 1;
    color: #ffffff;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
    font-size: 0.7rem;
  }

  .projectDetails1 {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;
    margin-left: 38%;
    justify-content: center;
    align-items: baseline;
    gap: 2px;
  }
}
@media only screen and (min-width: 992px) and (device-width: 2560px) and (device-height: 1600px) {
  .projectDetails1 {
    width: 125%;
    margin-left: 28%;
  }
}
@media only screen and (min-width: 992px) {
  .year1 {
    font-family: "Helvetica Neue LT Pro";
    font-size: 0.7rem;
    color: black;
    margin-left: -1rem;
  }
}
@media only screen and (min-width: 992px) {
  .navtitle__thenegrospaceprogram {
    font-family: "Nd Tupa Nova";
    position: relative;
    margin-top: -1%;
    margin-left: 65%;
    z-index: 1;
    opacity: 1;
    color: #FF6464;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: black;
    font-size: 2.7rem;
    line-height: 2.7rem;
  }
}
@media only screen and (min-width: 992px) {
  .year5 {
    font-family: "Helvetica Neue LT Pro";
    font-size: 0.75rem;
    width: 100%;
    margin-left: 13%;
    position: relative;
    text-align: left;
    margin-top: 0;
    border-radius: 2px;
    color: black;
  }
}
@media only screen and (min-width: 992px) {
  .year2 {
    font-family: "Helvetica Neue LT Pro";
    font-size: 0.75rem;
    width: 100%;
    margin-left: 13%;
    position: relative;
    text-align: left;
    margin-top: 0;
    border-radius: 2px;
    color: black;
  }
}
@media only screen and (min-width: 992px) {
  .yearBox2 {
    height: 1.1rem;
    width: 16%;
    margin-top: 3%;
    margin-left: 0%;
    background: #FF6464;
    z-index: -1;
    border-radius: 2px;
    display: flex;
    justify-content: flex-start;
    padding-left: 1%;
  }
}
@media only screen and (min-width: 992px) {
  .yearBox3 {
    position: relative;
    height: 1.1rem;
    width: 7rem;
    margin-top: 2%;
    margin-left: 65%;
    background: #FF6464;
    z-index: -1;
    border-radius: 0.1rem;
  }
}
@media only screen and (min-width: 992px) {
  .yearBox4 {
    position: relative;
    height: 1.1rem;
    width: 7rem;
    margin-top: 0%;
    margin-left: 65%;
    background: #FF6464;
    z-index: -1;
    border-radius: 0.1rem;
  }
}
@media only screen and (min-width: 992px) {
  .yearBox5 {
    position: relative;
    height: 1.1rem;
    width: 7rem;
    margin-top: 0%;
    margin-left: 65%;
    background: #FF6464;
    z-index: -1;
    border-radius: 0.1rem;
  }
}
@media only screen and (min-width: 992px) {
  .navdescription__thenegrospaceprogram {
    font-family: "Helvetica Neue LT Pro";
    height: 100%;
    width: 100%;
    position: relative;
    margin-top: 1%;
    margin-left: 65%;
    z-index: 1;
    opacity: 1;
    font-size: 0.7rem;
    color: #ffffff;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
    padding-bottom: 2%;
  }
}
@media only screen and (min-width: 992px) {
  .year {
    font-family: "Helvetica Neue LT Pro";
  }
}
@media only screen and (min-width: 992px) {
  .yearBox1 {
    position: relative;
    height: 1.1rem;
    width: 7rem;
    margin-top: 0%;
    margin-left: 65%;
    background: #FF6464;
    z-index: -1;
    border-radius: 1px;
  }
}
@media only screen and (min-width: 992px) {
  .navtitle__Mountains {
    font-family: "Nd Tupa Nova";
    position: relative;
    height: 100%;
    width: 100px;
    margin-top: 0%;
    margin-left: 65%;
    z-index: 1;
    opacity: 1;
    color: #FF6464;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: black;
    font-size: 2.7rem;
    line-height: 2.7rem;
    padding-bottom: 0.5rem;
  }
}
@media only screen and (min-width: 992px) {
  .navtitle__INLabSchibsted {
    font-family: "Nd Tupa Nova";
    position: relative;
    height: 100%;
    width: 100px;
    margin-top: 0%;
    margin-left: 65%;
    z-index: 1;
    opacity: 1;
    color: #FF6464;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: black;
    font-size: 2.7rem;
    line-height: 2.7rem;
  }
}
@media only screen and (min-width: 992px) {
  .navdescription__INLabSchibsted {
    font-family: "Helvetica Neue LT Pro";
    height: 100%;
    width: 100%;
    position: relative;
    margin-top: 1%;
    margin-left: 65%;
    z-index: 1;
    opacity: 1;
    color: #ffffff;
    text-align: left;
    transition: opacity 0.5s ease-in-out;
    font-size: 0.6rem;
  }
}
@media only screen and (min-width: 992px) {
  .yearBox3 {
    position: relative;
    height: 1.1rem;
    width: 7rem;
    margin-top: 2%;
    margin-left: 65%;
    background: #FF6464;
    z-index: -1;
    border-radius: 0.1rem;
  }
}
@media only screen and (min-width: 992px) {
  .year3 {
    font-family: "Helvetica Neue LT Pro";
    color: black;
    position: absolute;
    height: 1.1rem;
    width: 14rem;
    margin-top: -0.15rem;
    margin-left: -4.5rem;
    z-index: -1;
    font-size: 0.9rem;
  }
}
.year4 {
  font-family: "Helvetica Neue LT Pro";
  color: black;
  font-size: 0.9rem;
  width: fit-content;
  height: 100%;
  padding: 0px;
}

.navdescription__Mountains {
  font-family: "Helvetica Neue LT Pro";
  height: 100%;
  width: 100%;
  position: relative;
  margin-top: 1%;
  margin-left: 65%;
  z-index: 1;
  opacity: 1;
  font-size: 0.7rem;
  color: #ffffff;
  text-align: left;
  transition: opacity 0.1s ease-in-out;
  padding-bottom: 2%;
}

@media (max-width: 1023px) {
  .navtitle__places,
.navtitle__thenegrospaceprogram,
.navtitle__INLabSchibsted,
.navtitle__ThePark,
.navtitle__Mountains {
    font-family: "Nd Tupa Nova";
    text-shadow: 0 1px black, 0 1.05px black, 1.05px 1px black, 1.05px 1.05px black, 1.1px 1.1px black, 1.1px 1.15px black, 1.15px 1.2px black, 1.15px 1.25px black, 1.2px 1.3px black;
    color: #FF6464;
    font-size: 7rem;
    line-height: 6.5rem;
    width: 54%;
    position: relative;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .navtitle__places,
.navtitle__thenegrospaceprogram,
.navtitle__INLabSchibsted,
.navtitle__ThePark,
.navtitle__Mountains {
    font-family: "Nd Tupa Nova";
    color: #FF6464;
    font-size: 2.4rem;
    line-height: 2.2rem;
    width: 19%;
    position: relative;
  }
}

@media (max-width: 767px) {
  /* Wrap container */
  .navtitle__places {
    position: relative;
    margin-top: 27%;
    left: -9%;
    width: fit-content;
  }

  .navtitle__ThePark {
    position: relative;
    margin-top: 0%;
    margin-left: 22%;
    font-size: 3rem;
    margin-bottom: 1%;
  }

  /* Nav descriptions unified style*/
  .navdescription__places,
.navdescription__thenegrospaceprogram,
.navdescription__INLabSchibsted,
.navdescription__ThePark .navdescription__Mountains {
    font-family: "Helvetica Neue LT Pro";
    font-size: 0.7rem;
    margin-bottom: 15px;
    /* Change the space between navdescription and the next row element */
  }

  /* Nav descriptions separate style*/
  .navdescription__places {
    margin-left: -9%;
    width: 80%;
    margin-top: 1%;
  }

  .navdescription__thenegrospaceprogram {
    margin-right: 0%;
  }

  .navdescription__INLabSchibsted {
    margin-right: 0%;
    margin-top: 1.5%;
  }

  .navdescription__ThePark {
    margin-left: 23%;
    margin-top: 1%;
    font-size: 0.75rem;
  }

  /* Row elements */
  .year,
.yearBox,
.status,
.statusBox {
    margin-bottom: 15px;
    /* Change the space between rows */
  }

  .status {
    font-family: "Helvetica Neue LT Pro";
    font-size: 0.7rem;
    margin-top: 0.8px;
    margin-left: 5px;
    color: #000000;
  }

  .statusBox {
    position: absolute;
    height: 1.3rem;
    width: 6rem;
    background: #00d32a;
    z-index: -1;
    margin-lefT: 22%;
    margin-top: 18.4%;
    border-radius: 5px;
    z-index: -1;
  }

  .year1,
.year2,
.year3,
.year4,
.year5 {
    font-family: "Helvetica Neue LT Pro";
    font-size: 0.7rem;
    margin-top: 1.5%;
    height: 100%;
    color: black;
  }

  .yearBox1 {
    margin-left: -9%;
  }

  .yearBox2 {
    width: 20%;
  }

  .year1 {
    margin-left: -9%;
    margin-top: -2.5%;
  }

  .year2 {
    margin-left: 13%;
    margin-top: 0%;
  }

  .year3 {
    margin-left: 13%;
  }

  .year4 {
    margin-left: 13%;
  }

  .yearBox1,
.yearBox2,
.yearBox3,
.yearBox4,
.yearBox5 {
    position: relative;
    height: 1rem;
    width: 7rem;
    background: #ff6464;
    z-index: -1;
    margin-top: 2%;
    border-radius: 5px;
    padding-left: 3px;
    font-size: 0.75rem;
  }

  .yearBox4 {
    padding-left: 0%;
  }

  .year4 {
    margin-left: 22%;
    padding-left: 5px;
    padding-top: 0px;
    width: 8rem;
  }

  .projectDetails1 {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    margin-left: -42%;
    justify-content: center;
    align-items: baseline;
    gap: 2px;
    margin-top: 0;
    margin-bottom: 2px;
  }
}
@media (max-width: 1023px) {
  /* Wrap container */
  .navtitle__ThePark {
    position: relative;
    margin-top: 0%;
    margin-left: 22%;
    margin-bottom: 1%;
  }

  /* Nav descriptions unified style*/
  .navdescription__places,
.navdescription__thenegrospaceprogram,
.navdescription__INLabSchibsted,
.navdescription__ThePark,
.navdescription__Mountains {
    font-family: "Helvetica Neue LT Pro";
    margin-bottom: 15px;
    display: flex;
    text-align: left;
  }

  /* Nav descriptions separate style*/
  .navdescription__places {
    margin-left: -9%;
    width: 80%;
    margin-top: 1%;
  }

  .navdescription__thenegrspaceprogram {
    margin-right: 0%;
  }

  .navdescription__INLabSchibsted {
    margin-right: 0%;
    margin-top: 1.5%;
  }

  .navdescription__ThePark {
    margin-left: 23%;
    margin-top: 1%;
  }

  /* Row elements */
  .year,
.yearBox,
.status,
.statusBox {
    margin-bottom: 15px;
    /* Change the space between rows */
  }

  .status {
    font-family: "Helvetica Neue LT Pro";
    font-size: 0.7rem;
    margin-top: 0.8px;
    margin-left: 5px;
    color: #000000;
  }

  .statusBox {
    position: absolute;
    height: 1.3rem;
    width: 6rem;
    background: #00d32a;
    z-index: -1;
    margin-lefT: 22%;
    margin-top: 18.4%;
    border-radius: 5px;
    z-index: -1;
  }

  .year1,
.year2,
.year3,
.year4,
.year5 {
    font-family: "Helvetica Neue LT Pro";
    font-size: 0.7rem;
    margin-top: 1.5%;
    height: 100%;
    color: black;
  }

  .yearBox1 {
    margin-left: -9%;
  }

  .yearBox2 {
    width: 20%;
  }

  .year1 {
    margin-left: -9%;
    margin-top: -2.5%;
  }

  .year2 {
    margin-left: 13%;
    margin-top: 0%;
  }

  .year3 {
    margin-left: 0%;
  }

  .year4 {
    margin-left: 13%;
  }

  .yearBox1,
.yearBox2,
.yearBox3,
.yearBox4,
.yearBox5 {
    position: relative;
    height: auto;
    background: #ff6464;
    z-index: -1;
    margin-top: 2%;
    border-radius: 5px;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 0.75rem;
    width: fit-content;
  }

  .yearBox4 {
    padding-left: 0%;
  }

  .year4 {
    margin-left: 22%;
    padding-left: 5px;
    padding-top: 0px;
  }

  .projectDetails1 {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    margin-left: -14%;
    justify-content: flex-start;
    align-items: baseline;
    gap: 2px;
    margin-top: 0;
    margin-bottom: 2px;
  }
}
.about__wrapper {
  padding-top: 10rem;
}

.about__title.title-1 {
  font-size: 17rem;
  font-family: "Nd Tupa Nova";
  text-shadow: 0 1px black, 0 1.05px black, 1.05px 1px black, 1.05px 1.05px black, 1.1px 1.1px black, 1.1px 1.15px black, 1.15px 1.2px black, 1.15px 3.25px black, 4.2px 4.3px black;
  color: #FF6464;
  line-height: 130%;
  margin-top: -11%;
}

.about__title.title-2 {
  font-size: 5rem;
}

.about__title {
  max-width: 107rem;
  margin: 0 auto;
  text-align: center;
  line-height: 1;
  font-size: 5rem;
  color: white;
  line-height: 64px;
  font-family: "Helvetica Neue LT Pro";
}
@media (max-width: 767px) {
  .about__title {
    max-width: 67.7rem;
  }
}

.about__content {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 20rem 0;
}
.about__content:first-child {
  margin-bottom: 0;
}
.about__content:last-child {
  margin-bottom: 0;
}
.about__content:nth-child(5) {
  justify-content: flex-end;
}
.about__content:nth-child(8) {
  justify-content: end;
}
@media (max-width: 767px) {
  .about__content:nth-child(5) {
    justify-content: flex-end;
  }
  .about__content:nth-child(8) {
    justify-content: flex-start;
  }
}

.about__content--left {
  position: relative;
}

.about__content--right {
  position: relative;
  transform: translate(17%, -250%);
  margin-bottom: 0%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.about__content__wrapper {
  display: flex;
}
.about__content--right .about__content__wrapper {
  flex-direction: row-reverse;
}
@media (max-width: 767px) {
  .about__content:nth-child(3) .about__content__wrapper {
    flex-direction: column-reverse;
  }
  .about__content:nth-child(5) .about__content__wrapper {
    flex-direction: column;
  }
  .about__content:nth-child(8) .about__content__wrapper {
    flex-direction: column-reverse;
  }
  .about__content:nth-child(9) .about__content__wrapper {
    flex-direction: column-reverse;
  }
}

.about__content__box {
  display: flex;
}
@media (max-width: 768px) {
  .about__content__box {
    margin: auto;
    width: 60.9rem;
  }
  .about__content:nth-child(3) .about__content__box {
    margin-top: 10rem;
  }
  .about__content:nth-child(5) .about__content__box {
    margin-bottom: 10rem;
  }
  .about__content:nth-child(8) .about__content__box {
    margin-top: 10rem;
  }
  .about__content:nth-child(9) .about__content__box {
    margin-top: 10rem;
  }
}

.about__content__label {
  width: 19rem;
  text-transform: uppercase;
  font-family: "Helvetica Neue LT Pro";
  color: #ffffff;
  font-size: 2rem;
}
@media (max-width: 768px) {
  .about__content__label {
    font-size: 2.4rem;
    padding-right: 2.5rem;
    width: 15rem;
  }
}

.about__content__description {
  width: 36.2rem;
  color: white;
  font-family: "Suisse BP";
  font-weight: normal;
}
.about__content__description p:not(:first-child) {
  margin-top: 2.5rem;
}
@media (max-width: 768px) {
  .about__content__description {
    width: calc(100% - 15rem);
  }
}

.about__content__media {
  position: relative;
}
.about__content:nth-child(3) .about__content__media {
  width: 53.8rem;
  margin-left: 18rem;
  height: 88.8rem;
}
.about__content:nth-child(5) .about__content__media {
  height: 60rem;
  margin-left: 18rem;
  width: 96rem;
}
.about__content:nth-child(8) .about__content__media {
  width: 84.2rem;
  margin-right: 12rem;
  height: 57rem;
}
.about__content:nth-child(9) .about__content__media {
  height: 85.8rem;
  margin-left: 22rem;
  width: 60.8rem;
}
@media (max-width: 768px) {
  .about__content:nth-child(3) .about__content__media {
    margin-left: 0;
    width: 60.9rem;
    height: 91.4rem;
  }
  .about__content:nth-child(5) .about__content__media {
    height: 50rem;
    margin-left: 0;
    width: 100vw;
  }
  .about__content:nth-child(8) .about__content__media {
    width: 100vw;
    margin-right: 0;
    height: 57rem;
  }
  .about__content:nth-child(9) .about__content__media {
    height: 85.8rem;
    margin-left: 0;
    width: 60.8rem;
  }
}

.description-4 {
  width: fit-content;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .description-4 {
    margin-bottom: 13vh;
    height: 100%;
    width: 100%;
    font-size: 2.7rem;
    white-space: nowrap;
    margin-left: -27%;
    margin-top: 4%;
  }
}
@media (max-width: 767px) {
  .description-5 {
    height: 100%;
    width: 100%;
    white-space: nowrap;
    margin-left: -23%;
    margin-top: 4%;
  }
}
@media (max-width: 767px) {
  .description-6 {
    margin-top: 6vh;
  }
}

.about__content__media__image {
  object-fit: cover;
  border-radius: 250px 250px 0px 0px;
  object-fit: cover;
}

.about__gallery {
  height: 70.6rem;
  position: relative;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
}
.about__gallery:first-child {
  margin-bottom: 0;
}
.about__gallery:last-child {
  margin-bottom: 0;
}

.about__gallery__wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
}

.about__gallery__media {
  position: relative;
  height: 43.7rem;
  width: 30.9rem;
  margin: 0 4rem;
}

.about__gallery__media__image {
  object-fit: cover;
}

.about__highlight {
  margin: 20rem 0;
}
.about__highlight:first-child {
  margin-bottom: 0;
}
.about__highlight:last-child {
  margin-bottom: 0;
}

.about__highlight__wrapper {
  height: 104rem;
  width: 144rem;
  margin: auto;
  position: relative;
}
@media (max-width: 768px) {
  .about__highlight__wrapper {
    height: 188.2rem;
    width: 100%;
  }
  .about__highlight:nth-child(4) .about__highlight__wrapper {
    height: 119.3rem;
    width: 100%;
  }
}

.about__highlight__label {
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 9rem;
  transform: translateX(-50%);
  text-align: center;
  letter-spacing: 0.1rem;
  width: 9.6rem;
  display: none;
}
@media (max-width: 768px) {
  .about__highlight__label {
    font-size: 2.4rem;
    margin-top: 6.5rem;
    width: 19rem;
    top: 92rem;
  }
}

.about__highlight__title {
  padding-bottom: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Nd Tupa Nova";
  color: #ff6464;
  text-shadow: 0 1px black, 0 1.05px black, 1.05px 1px black, 1.05px 1.05px black, 3.1px 4.1px black, 1.1px 4.15px black, 2.15px 6.2px black, 4.15px 8.25px black, 6.2px 9.3px black;
  text-align: CENTER;
  line-height: 100%;
  z-index: 1;
}
@media (max-width: 767px) {
  .about__highlight__title {
    font-size: 22rem;
    margin-top: 5.5rem;
    transform: translate(-50%, -50%);
    line-height: 100%;
    padding-bottom: 0%;
    text-align: center;
  }
}

.highlight-title-2 {
  font-size: 10rem;
  width: 100%;
  text-align: center;
}
@media (max-width: 767px) {
  .highlight-title-2 {
    font-size: 6rem;
    width: 100%;
    text-align: center;
    text-shadow: none;
    color: white;
  }
}

.about__highlight__media {
  position: absolute;
  height: 76rem;
  width: 50%;
}
.about__highlight:nth-child(4) .about__highlight__media {
  width: 36rem;
  height: 52.8rem;
}
.about__highlight__media:first-child {
  top: 0;
  left: 0;
}
.about__highlight__media:last-child {
  bottom: 0;
  right: 0;
}
@media (max-width: 768px) {
  .about__highlight:last-child .about__highlight__media {
    width: 54rem;
    height: 76rem;
  }
  .about__highlight:last-child .about__highlight__media {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 767px) {
  .about__highlight:last-child .about__highlight__media {
    width: 99rem;
    height: 97rem;
  }
}
@media (max-width: 767px) {
  .about__highlight__media {
    width: 100%;
  }
}
.about__highlight__media__image {
  object-fit: cover;
}

.collections {
  z-index: 0;
}

.collections__wrapper {
  overflow: hidden;
}

.collections__titles {
  transition: transform 1s ease;
}
@media (max-width: 767px) {
  .collections__titles {
    display: none;
  }
}

.collections__gallery {
  z-index: 1;
}

.collections__gallery__wrapper {
  align-items: center;
  display: flex;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-22.35rem, -50%);
}

.collections__gallery__link {
  display: block;
  margin: 0 5.3rem;
}
.collections__gallery__link:first-child {
  margin-left: 0;
}
.collections__gallery__link:last-child {
  margin-right: 0;
}

.collections__gallery__media {
  height: 63.1rem;
  position: relative;
  width: 44.7rem;
  visibility: hidden;
}

.collections__gallery__media__image {
  object-fit: cover;
}

@media (max-width: 767px) {
  .collections__content {
    display: none;
  }
}

.collections__article {
  position: absolute;
  left: 8rem;
  bottom: 8rem;
  opacity: 0;
  transition: opacity 0.4s ease;
}
.collections__article--active {
  opacity: 1;
  transition-delay: 0.4s;
}

.collections__article__description {
  max-width: 46rem;
}

.collections__mobile {
  display: none;
  position: absolute;
  bottom: 5rem;
}
@media (max-width: 767px) {
  .collections__mobile {
    display: flex;
  }
}

.collections__mobile__item {
  padding-left: 5rem;
  width: 100vw;
}

.collections__mobile__item__label {
  font-size: 2rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.collections__mobile__item__title {
  font-family: "George X";
  font-size: 12rem;
}

@media (max-width: 767px) {
  .detail {
    height: auto;
  }
}

.detail__wrapper {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .detail__wrapper {
    display: block;
    margin: auto;
    padding: 16rem 0;
    width: 66.1rem;
  }
}

.detail__media {
  width: 55.4rem;
  height: 78.2rem;
  position: relative;
}
@media (max-width: 767px) {
  .detail__media {
    width: 100%;
    height: 93.4rem;
    margin: 0 auto 7.8rem;
  }
}

.detail__media__image {
  object-fit: cover;
}

.detail__information {
  padding-left: 8.2rem;
}
@media (max-width: 767px) {
  .detail__information {
    padding-left: 0;
  }
}

.detail__information__collection {
  margin-bottom: 4rem;
  text-transform: uppercase;
}

.detail__information__title {
  max-width: 60rem;
}

.detail__information__content {
  padding-left: 18rem;
}
@media (max-width: 767px) {
  .detail__information__content {
    padding-left: 0;
  }
}

.detail__information__highlights {
  margin-top: 5rem;
}

.detail__information__highlight {
  align-items: center;
  display: flex;
}
.detail__information__highlight:not(:first-child) {
  margin-top: 4rem;
}

.detail__information__highlight__icon {
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  margin-right: 3.75rem;
}
@media (max-width: 767px) {
  .detail__information__highlight__icon {
    height: 9.2rem;
    width: 9.2rem;
    margin-right: 5.8rem;
  }
}

.detail__information__list {
  margin-top: 4rem;
}

.detail__information__item {
  display: flex;
}
.detail__information__item:not(:first-child) {
  margin-top: 4rem;
}

.detail__information__item__title {
  padding-right: 2.5rem;
  width: 10rem;
}
@media (max-width: 767px) {
  .detail__information__item__title {
    width: 15rem;
    padding-right: 5rem;
  }
}

.detail__information__item__description {
  letter-spacing: 0.03rem;
  max-width: 44rem;
}

.detail__information__link {
  margin-top: 3rem;
  display: inline-block;
}

.detail__button {
  width: 12.4rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  bottom: 5rem;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
}
@media (max-width: 767px) {
  .detail__button {
    width: 26.8rem;
    height: 26.8rem;
    bottom: 0;
  }
}

:root {
  --title-color: rgba(255, 255, 255, 0.25);
}

.dg.main {
  z-index: 1000 !important;
}

.fade-in {
  opacity: 0;
  transition: opacity 3s ease-in;
}

.scrollContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* Hide the overflowing content */
}

.storyboard {
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0%;
  width: 100vw;
  z-index: 5;
  display: flex;
}

.subTitle__Storyboard {
  font-family: "Helvetica Neue LT Pro";
  color: white;
  position: absolute;
  font-size: 3.8rem;
  top: 171px;
  left: 54%;
  width: 100%;
  z-index: 3;
  opacity: 1;
}
@media (max-width: 1023px) {
  .subTitle__Storyboard {
    font-family: "Helvetica Neue LT Pro";
    color: white;
    position: absolute;
    font-size: 11.8rem;
    top: 171px;
    left: 0%;
    width: 100%;
    z-index: 3;
    opacity: 1;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }
}

@media (max-width: 1023px) {
  .storyboard__Text {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
  }
}
.scrollContent {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0;
}

.theNegroSpaceProgram_wrapper,
.case,
.context,
.concept {
  height: 100vh;
}

/* Each section should be at least the height of the viewport */
.scrollContent section {
  height: 100vh;
}

.theNegroSpaceProgram {
  background: black;
  overflow: none;
}

.theNegroSpaceProgram__wrapper {
  animation: fadeIn 4s ease-in forwards;
}

#headerVideo {
  margin-top: 0%;
  width: 100%;
}
@media (max-width: 1023px) {
  #headerVideo {
    margin-top: 0%;
    width: 100%;
  }
}

video {
  position: absolute;
  z-index: 0;
  pointer-events: none;
  width: 100%;
  height: auto;
  opacity: 0;
  z-index: -2;
  animation: fadeIn 4s ease-in forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.theNegroSpaceProgram__wrapper {
  top: 0;
  position: absolute;
  width: 100%;
  overflow: none;
}

.theNegroSpaceProgram__title {
  font-family: "Nd Tupa Nova";
  font-size: 17.5rem;
  position: absolute;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.preface {
  font-family: "Helvetica Neue LT Pro";
  font-size: 5.5rem;
  position: absolute;
  margin-top: 29%;
  display: flex;
  height: 100%;
  width: 100%;
  opacity: 1;
  margin-left: 0%;
  justify-content: flex-start;
}
@media (max-width: 1023px) {
  .preface {
    font-family: "Helvetica Neue LT Pro";
    font-size: 5.5rem;
    position: absolute;
    margin-top: 0%;
    display: flex;
    height: 100%;
    width: 100%;
    opacity: 1;
    margin-left: 0%;
  }
}

.preface_Title,
.preface_Title2 {
  position: absolute;
  font-size: 13rem;
  width: 100vw;
  margin-top: 10%;
  margin-left: 0%;
  z-index: 1;
  height: auto;
  background-color: black;
  color: rgba(255, 255, 255, 0.25);
  transition: color 0.5s ease-in-out;
  padding-bottom: 4px;
}
@media (max-width: 1023px) {
  .preface_Title,
.preface_Title2 {
    position: absolute;
    font-size: 20rem;
    width: 100vw;
    margin-top: 82vh;
    margin-left: 0%;
    z-index: 1;
    background-color: black;
    color: rgba(255, 255, 255, 0.25);
    transition: color 0.5s ease-in-out;
    padding-bottom: 16vh;
  }
}

.preface_Title.active,
.preface_Title2.active {
  color: white;
}

.preface_Title:hover:not(.no-hover),
.preface_Title2:hover:not(.no-hover) {
  color: white;
}

.preface_Title2 {
  margin-left: 50%;
}
@media (max-width: 1023px) {
  .preface_Title2 {
    margin-left: 0%;
    padding-bottom: 0%;
    margin-top: 114%;
  }
}

@media (max-width: 1023px) {
  .preface_Title {
    margin-left: 0%;
    position: relative;
    margin-top: 116%;
    z-index: 0;
    background: black;
    padding-top: 10%;
  }
}

.storyboard__Title,
.menu__ac {
  font-family: "Helvetica Neue LT Pro";
  position: fixed;
  font-size: 13rem;
  width: auto;
  z-index: 1;
  height: auto;
  background-color: #00000000;
  color: rgba(255, 255, 255, 0.25);
  transition: color 0.5s ease-in-out;
  flex-wrap: wrap;
  padding: 30px 200px 31px 150px;
  margin-top: 35.5%;
  position: fixed;
  bottom: -103vh;
}

.storyboard__Title.active,
.menu__ac.active {
  color: white;
}

.storyboard__Title:hover:not(.no-hover),
.menu__ac:hover:not(.no-hover) {
  color: white;
}

.storyboard__Title {
  position: fixed;
  align-content: stretch;
  display: flex;
  z-index: 2;
  width: 42%;
  padding: 0px 0px 0px 0px;
  pointer-events: all;
  padding-bottom: 2%;
  justify-content: flex-start;
  margin-left: 10%;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .storyboard__Title {
    padding-bottom: 1%;
    margin-left: 10%;
  }
}
@media (max-width: 1023px) {
  .storyboard__Title {
    position: fixed;
    align-content: stretch;
    display: flex;
    align-items: flex-end;
    z-index: 2;
    width: 100%;
    padding: 30px 170px 32px 150px;
    pointer-events: all;
    padding-bottom: 19%;
    font-size: 10rem;
    justify-content: flex-start;
    margin-left: 2%;
  }
}

.menu__ac {
  position: fixed;
  align-content: stretch;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 60%;
  flex-direction: row;
  margin-left: 40vw;
  padding: 30px 215px 31px 150px;
  pointer-events: all;
  z-index: 10;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .menu__ac {
    padding-bottom: 1%;
    margin-left: 31%;
  }
}
@media (max-width: 1023px) {
  .menu__ac {
    position: fixed;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 29%;
    flex-direction: row;
    margin-left: 0vw;
    padding: 0px 0px 0px 0px;
    pointer-events: all;
    padding-bottom: 19%;
    margin-left: 3%;
    font-size: 10rem;
    left: 151%;
    flex-wrap: wrap;
  }
}

.case {
  position: absolute;
  top: 100vh;
  width: 200vw;
  background: #000000;
  z-index: 2;
  height: 50vh;
}

.context {
  position: absolute;
  top: 0vh;
  width: 100vw;
  height: 50vh;
  background: #000000;
  z-index: 2;
}

#arrow2BackToContext {
  position: absolute;
  width: 10vw;
  top: 79vh;
  left: 0vw;
  fill: rgba(255, 255, 255, 0);
  transition: all 1s ease;
}
@media (max-width: 1023px) {
  #arrow2BackToContext {
    display: none;
  }
}

.arrow2BackToContext1 {
  stroke: white;
  stroke-width: 0.5%;
}

.arrow2BackToContext2 {
  fill: none;
  stroke: none;
  display: none;
}
@media (max-width: 767px) {
  .arrow2BackToContext2 {
    display: block;
    fill: white;
  }
}

#arrow2ToAssets,
#arrow2Animatic {
  display: none;
}

#arrowToAnimaticAnimation {
  position: absolute;
  width: 100%;
  top: 0;
  display: none;
}
@media (max-width: 767px) {
  #arrowToAnimaticAnimation {
    display: block;
    margin-top: 160%;
    width: 34%;
    position: absolute;
    margin-left: 28%;
  }
}

.context__flexbox {
  position: absolute;
  display: flex;
  height: 100%;
  padding: 15px;
  gap: 50px;
  top: 0vh;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100vw;
  align-content: center;
  align-items: stretch;
}
@media (max-width: 1023px) {
  .context__flexbox {
    position: absolute;
    display: flex;
    height: 91%;
    padding: 15px;
    gap: 6px;
    top: 0vh;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100vw;
    align-content: center;
    align-items: center;
  }
}

.concept {
  position: absolute;
  top: 0px;
  width: 100vw;
  background: #000000;
  z-index: 2;
  height: 100vh;
  margin-top: -100vh;
  margin-left: 100vw;
}

.poem {
  font-size: 1.55rem;
  position: absolute;
  top: 30vh;
  width: 100vw;
  color: white;
  left: 55vw;
  line-height: auto;
  z-index: 14;
}
@media (max-width: 1023px) {
  .poem {
    font-size: 3.4rem;
    position: absolute;
    top: 56vh;
    width: 104vw;
    color: white;
    left: 24vw;
    line-height: auto;
    z-index: 14;
  }
}

#storyboardVideo {
  position: absolute;
  width: 35%;
  margin-top: 13%;
  margin-left: 12vw;
  border: 0.5px solid #ffffff;
  border-radius: 60px;
}
@media (max-width: 1023px) {
  #storyboardVideo {
    position: relative;
    width: 72%;
    margin-top: 36%;
    margin-left: 0vw;
    border: 0.5px solid #ffffff;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.process {
  position: absolute;
  top: 0px;
  width: 100vw;
  z-index: 4;
  opacity: 1.5;
  height: 100vh;
  margin-top: -100vh;
  margin-left: 200vw;
  pointer-events: none;
}

.blackOverlay {
  position: sticky;
  width: 100vw;
  z-index: 3;
  opacity: 1.5;
  height: 100vh;
  margin-top: -200vh;
  margin-left: 200vw;
  pointer-events: none;
  background: black;
  background: -moz-linear-gradient(180deg, black 5%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 60%, black 95%);
  background: -webkit-linear-gradient(180deg, black 5%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 60%, black 95%);
  background: linear-gradient(180deg, black 5%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 60%, black 95%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
}

@media (max-width: 767px) {
  .blackOverlay {
    position: sticky;
    width: 100vw;
    z-index: 3;
    opacity: 1.5;
    height: 100vh;
    margin-top: -200vh;
    margin-left: 200vw;
    pointer-events: none;
    background: rgba(0, 0, 0, 0);
    background: -moz-linear-gradient(180deg, black 5%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 60%, black 95%);
    background: linear-gradient(180deg, transparent 5%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 60%, black 95%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
  }
}
.blackProcessBg {
  position: absolute;
  height: 230vh;
  width: 100vw;
  background-color: black;
  z-index: 0;
  margin-top: -100vh;
  margin-left: 200vw;
}

.resultsContainer {
  position: absolute;
  top: 0;
  width: 50vw;
}

@media (max-width: 767px) {
  .resultsContainer {
    position: absolute;
    top: 0px;
    width: 100vw;
  }
}
.results {
  position: absolute;
  top: 0px;
  width: 100vw;
  background: #000000;
  z-index: 2;
  opacity: 1.5;
  height: 100vh;
  margin-top: -100vh;
  margin-left: 300vw;
}

@media (max-width: 767px) {
  .results {
    position: absolute;
    top: 0px;
    width: 100vw;
    background: #000000;
    z-index: 2;
    opacity: 1.5;
    height: 100vh;
    margin-top: -100vh;
    margin-left: 300vw;
  }
}
.Breakdown__title {
  font-family: "Nd Tupa Nova";
  font-size: 11.5rem;
  position: relative;
  width: 100vw;
  display: flex;
  height: 100vh;
  justify-content: center;
  top: 50vh;
}

.manifesto_text1,
.manifesto_text2,
.manifesto_text3 {
  font-family: "Helvetica Neue LT Pro";
  font-size: 1.1rem;
  width: 90vw;
  height: auto;
}
@media (max-width: 1023px) {
  .manifesto_text1,
.manifesto_text2,
.manifesto_text3 {
    font-size: 2.2rem;
  }
}

#arrowBackToContext,
#arrowToAssets {
  margin-top: 84vh;
  display: none;
  pointer-events: none;
}

#arrowToCase {
  position: absolute;
  height: 50%;
  margin-left: 6%;
  margin-top: 1%;
  fill: white;
  z-index: 67;
  pointer-events: all;
}

.festival_notification {
  position: absolute;
  font-family: "Helvetica Neue LT Pro";
  font-size: 3.1rem;
  width: 18vw;
  text-align: center;
  margin-top: -18%;
  margin-left: 73%;
  z-index: 1;
  background-color: black;
  color: white;
  height: 11vh;
  border-radius: 3px;
  border-style: outset;
  border-color: white;
  border-width: medium;
  transition: background-color 0.2s ease-in-out;
}
@media (max-width: 1023px) {
  .festival_notification {
    position: absolute;
    font-family: "Helvetica Neue LT Pro";
    font-size: 8.1rem;
    width: 49%;
    text-align: center;
    margin-top: 0%;
    margin-left: 0%;
    z-index: 1;
    background-color: black;
    color: white;
    height: 11vh;
    border-radius: 10px;
    border-style: outset;
    border-color: white;
    border-width: medium;
    height: auto;
    transform: translate(25vw, 50vw);
  }
}

.festival_notification:hover {
  background-color: #1a98ff;
}

.access {
  position: absolute;
  height: auto;
  width: 138%;
  top: 12rem;
  text-align: left;
  left: 22%;
  font-size: 1.3rem;
}

.face__canvas {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

@media (max-width: 1023px) {
  #arrowBackToContext {
    position: fixed;
    padding: 40px 40px;
    fill: white;
    z-index: 2;
    stroke-width: 2px;
    stroke: white;
    stroke-opacity: 0.25;
    transition: all 1s ease;
    pointer-events: all;
    height: 100%;
    display: flex;
    align-content: center;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 19vw;
    margin-left: -75%;
  }
}

.arrowBackToContext {
  pointer-events: all;
}

#arrowBackToContext:hover {
  fill: white;
  stroke-width: 0;
  stroke: white;
  stroke-opacity: 1;
  pointer-events: all;
}

#arrowToAssets {
  position: fixed;
  fill: rgba(255, 255, 255, 0);
  z-index: 20;
  stroke-width: 2px;
  stroke: white;
  transition: all 1s ease;
  padding: 40px 40px;
  top: -9vh;
  display: flex;
  height: 100vh;
  width: 12vw;
  flex-direction: row;
  justify-content: flex-end;
  pointer-events: all;
  left: 190vw;
}
@media (max-width: 1023px) {
  #arrowToAssets {
    position: fixed;
    fill: white;
    z-index: 2;
    stroke-width: 2px;
    stroke: white;
    transition: all 1s ease;
    padding: 40px 40px;
    top: -5.5vh;
    display: flex;
    height: 100vh;
    width: 19vw;
    flex-direction: row;
    justify-content: flex-end;
    pointer-events: all;
    left: 183%;
  }
}

.arrowToAssets {
  pointer-events: all;
}

#arrowToAssets:hover {
  fill: white;
  stroke-width: 0;
  stroke: white;
  stroke-opacity: 1;
  pointer-events: all;
  stroke: white;
  stroke-width: 0px;
}

#storyboardCharacterRun {
  position: absolute;
  width: 20%;
  margin-top: 50%;
  margin-left: 18%;
  border: 0.5px solid #c8c8c8;
}
@media (max-width: 1023px) {
  #storyboardCharacterRun {
    position: absolute;
    width: 36%;
    margin-top: 155%;
    margin-left: -50%;
    border: 0.5px solid #c8c8c8;
  }
}

.process__menu,
.process__menu2 {
  font-family: "Helvetica Neue LT Pro";
  position: fixed;
  bottom: -100vh;
  font-size: 13rem;
  width: 100vw;
  margin-left: 0%;
  z-index: 1;
  height: auto;
  color: rgba(255, 255, 255, 0.25);
  transition: color 0.5s ease-in-out;
}

.process__menu {
  margin-left: 0%;
  padding-left: 0%;
}

.process__menu2 {
  margin-left: 71%;
}
@media (max-width: 1023px) {
  .process__menu2 {
    margin-left: 57%;
    padding-bottom: 11%;
    display: none;
  }
}

body {
  margin: 0;
  /* Remove default body margin */
  height: 100vh;
  /* Set body height to full viewport height */
  display: flex;
  /* Use flexbox for vertical centering */
  flex-direction: column;
  /* Align children vertically */
}

.processButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin-top: 41%;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .processButtons {
    margin-top: 38%;
  }
}
@media (max-width: 1023px) {
  .processButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 75%;
    margin-top: 119%;
    margin-left: 21%;
  }
}

#arrowBackToProcess {
  fill: rgba(255, 255, 255, 0);
  z-index: 7;
  stroke-width: 2px;
  stroke: white;
  stroke-opacity: 1;
  transition: all 1s ease;
  pointer-events: all;
  grid-column: 1;
  margin-left: -40%;
}

#arrowToNextSlide {
  fill: rgba(255, 255, 255, 0);
  stroke: white;
  stroke-width: 2px;
  justify-self: start;
  z-index: 7;
  margin-left: 40%;
  transition: all 1s ease;
}

#arrowBackToProcess.active,
#arrowToNextSlide.active {
  fill: white;
}

@media (max-width: 767px) {
  #arrowBackToProcess {
    fill: white;
    justify-self: start;
  }
}
#arrowBackToProcess:hover:not(.no-hover),
#arrowToNextSlide:hover:not(.no-hover),
#arrow2BackToContext:hover:not(.no-hover) {
  fill: white;
}

#arrowBackToProcess,
#arrowToNextSlide {
  bottom: 16.5vh;
  padding-bottom: 100px;
  width: 100%;
  padding-bottom: 148%;
  width: 100%;
  height: 100%;
  pointer-events: all;
  margin-top: -4%;
}
@media (max-width: 1023px) {
  #arrowBackToProcess,
#arrowToNextSlide {
    pointer-events: all;
    padding-bottom: 148%;
    width: 50%;
    height: 100%;
    z-index: 20;
    display: block;
    fill: white;
  }
}
@media (max-width: 767px) {
  #arrowBackToProcess,
#arrowToNextSlide {
    pointer-events: all;
    width: 30%;
    padding-bottom: 171%;
    width: 100%;
    height: 100%;
  }
}

.arrowBackToProcess,
.arrowToNextSlide {
  width: 14%;
}
@media (max-width: 1023px) {
  .arrowBackToProcess,
.arrowToNextSlide {
    width: 56%;
  }
}
@media (max-width: 767px) {
  .arrowBackToProcess,
.arrowToNextSlide {
    width: 50%;
    margin-left: 50%;
  }
}

@media (max-width: 767px) {
  #arrowToNextSlide {
    fill: white;
  }
}
@media (max-width: 767px) {
  .processButtons {
    grid-template-columns: 1fr 1fr;
    align-items: end;
    height: 100%;
    margin-top: 100%;
    margin-left: 4.5%;
  }
}
.tryFaceRig {
  font-family: "Helvetica Neue LT Pro";
  position: absolute;
  width: 32%;
  margin-left: 65%;
  margin-top: 48.3%;
  z-index: 100;
  border: 1px solid;
  font-size: 0.65rem;
  padding: 1px 3px;
  border-radius: 2px;
  background-color: rgba(0, 170, 3, 0.5);
  pointer-events: all;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .tryFaceRig {
    width: max-content;
    top: 6vh;
  }
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .tryFaceRig {
    top: 2vh;
    width: fit-content;
    white-space: nowrap;
  }
}
@media screen and (device-width: 1680px) and (device-height: 1050px) {
  .tryFaceRig {
    top: 2vh;
    width: fit-content;
    white-space: nowrap;
  }
}
@media (max-width: 1023px) {
  .tryFaceRig {
    font-family: "Helvetica Neue LT Pro";
    position: absolute;
    width: 30%;
    margin-left: 86%;
    margin-top: 33.3%;
    z-index: 100;
    border: 1px solid;
    font-size: 1.45rem;
    padding: 1px 3px;
    border-radius: 2px;
    background-color: rgba(0, 170, 3, 0.5);
    pointer-events: all;
  }
}

.faceRigDescription {
  position: absolute;
  width: 100%;
  font-family: "Suisse BP";
  margin-top: 116%;
  font-size: 0.45rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 130%;
  z-index: 0;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .faceRigDescription {
    top: 8vw;
    left: 5.5vw;
    width: max-content;
  }
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .faceRigDescription {
    top: 10vh;
    left: 42%;
  }
}
@media screen and (device-width: 1680px) and (device-height: 1050px) {
  .faceRigDescription {
    top: 10vh;
    left: 42%;
  }
}
@media (max-width: 1023px) {
  .faceRigDescription {
    margin-top: 90%;
    font-size: 1.2rem;
    margin-left: 100%;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .faceRigDescription {
    margin-top: 60%;
  }
}

@media (max-width: 767px) {
  .tryFaceRig {
    position: relative;
    width: auto;
    margin-left: 50%;
    margin-top: 30.3%;
    z-index: 7;
    border: 1px solid;
    font-size: 0.8rem;
    padding: 1px 3px;
    border-radius: 2px;
    background-color: rgba(0, 170, 3, 0.5);
    font-family: "Helvetica Neue LT Pro";
  }
}
@media (max-width: 767px) {
  #progress-bar {
    width: 0%;
    height: 20px;
    background: green;
  }
}
.leaveFaceRig {
  position: absolute;
  width: 31%;
  margin-left: 355px;
  margin-top: 117%;
  z-index: 7;
  font-size: 0.8rem;
  padding: 1px 3px;
  font-family: "Helvetica Neue LT Pro";
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .leaveFaceRig {
    top: 16vh;
    left: 9vw;
    width: 75%;
  }
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .leaveFaceRig {
    margin-top: 230%;
  }
}
@media screen and (device-width: 1680px) and (device-height: 1050px) {
  .leaveFaceRig {
    margin-top: 152%;
  }
}
@media screen and (device-width: 1440px) and (device-height: 900px) {
  .leaveFaceRig {
    left: -7vw;
    top: 6vh;
  }
}
@media (max-width: 1023px) {
  .leaveFaceRig {
    position: absolute;
    width: 28%;
    margin-left: 107%;
    margin-top: 127%;
    z-index: 7;
    font-size: 0.8rem;
    padding: 1px 3px;
    fill: white;
  }
}

@media (max-width: 767px) {
  .leaveFaceRig {
    position: absolute;
    width: 100%;
    margin-left: 0vw;
    height: 100;
    z-index: 5;
  }
}
.animatic {
  position: absolute;
  margin-top: 60%;
  left: 50%;
  width: 30%;
}
@media (max-width: 1023px) {
  .animatic {
    position: absolute;
    margin-top: 158%;
    font-size: 3.4rem;
  }
}
@media (max-width: 767px) {
  .animatic {
    position: absolute;
    margin-top: 263%;
    width: 73%;
    margin-left: -37%;
  }
}

#lottie-animation {
  display: none;
}

@media (max-width: 767px) {
  #lottie-animation {
    position: absolute;
    width: 30%;
    z-index: 5;
    margin-top: -9%;
    left: 43%;
    display: block;
  }
}
.processText {
  font-family: "Helvetica Neue LT Pro";
  position: absolute;
  height: auto;
  color: white;
  z-index: 5;
  margin-top: -42%;
  margin-left: -50%;
  width: 100%;
  font-size: 0.7rem;
}
@media screen and (device-width: 1920px) and (device-height: 1080px) {
  .processText {
    top: 4vh;
    left: -12%;
  }
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .processText {
    top: -0.5vh;
    left: -7vw;
    width: fit-content;
  }
}
@media (max-width: 1023px) {
  .processText {
    position: absolute;
    height: auto;
    color: white;
    z-index: 5;
    margin-top: -76%;
    margin-left: -12%;
    width: 72%;
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .processText {
    position: absolute;
    width: auto;
    height: auto;
    color: white;
    z-index: 5;
    margin-top: 30%;
    margin-left: 40%;
    width: 39%;
    font-size: 0.8rem;
  }
}
.cinema4D2,
.unrealengine2,
.marvelousdesigner2,
.substancePainterSC,
.houdini,
.afterEffects {
  background: white;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 22px;
  padding-top: 1px;
  font-size: 0.6rem;
}

@media (max-width: 767px) {
  .cinema4D2,
.unrealengine2,
.marvelousdesigner2,
.substancePainterSC,
.houdini,
.afterEffects {
    background: white;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 22px;
    padding-top: 1px;
  }
}
.myNewElement {
  position: absolute;
  width: 100%;
  top: 0%;
}

#characterWebcam {
  position: absolute;
  width: 100%;
  top: 0%;
}

.character__canvas {
  position: absolute;
  width: 100%;
  top: -30%;
  left: 88%;
  height: 100vh;
  z-index: 1;
}

@media (max-width: 767px) {
  .character__canvas {
    position: absolute;
    width: 100vw;
    top: -35%;
    left: 100%;
    height: 110vh;
    z-index: 3;
    pointer-events: none;
  }
}
#indicator-container {
  position: fixed;
  top: 34vh;
  left: 208%;
  height: 100%;
  z-index: 10;
}
@media (max-width: 1023px) {
  #indicator-container {
    display: flex;
    position: fixed;
    top: 13vh;
    left: 238%;
    height: 100%;
    z-index: 10;
  }
}

@media (max-width: 767px) {
  #indicator-container {
    position: fixed;
    top: 5vh;
    left: 233%;
    height: 100%;
    z-index: 10;
    width: 100vw;
  }
}
.indicator {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  margin: 0 10px;
  transition: background-color 0.5s ease;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .indicator {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    margin: 0 10px;
    transition: background-color 0.5s ease;
    margin-left: 1px;
  }
}
#canvasContainer {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0%;
  z-index: 2;
  /* Add other styles as needed */
}

.startWebcamButton {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  z-index: 5;
  position: absolute;
  top: 1%;
  left: 101.7%;
  border-radius: 10px;
  width: 4%;
  font-family: "Helvetica Neue LT Pro";
}
@media (max-width: 1023px) {
  .startWebcamButton {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    z-index: 5;
    position: absolute;
    top: 1%;
    left: 101.7%;
    border-radius: 10px;
    width: 10%;
  }
}

@media (max-width: 767px) {
  .startWebcamButton {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 9px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 2rem;
    margin: 1px 1px;
    cursor: pointer;
    z-index: 5;
    position: absolute;
    top: 22%;
    left: 204vw;
    border-radius: 10px;
    font-family: "Helvetica Neue LT Pro";
    pointer-events: all;
    width: 16%;
  }
}
.dg.ac {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  height: 0;
  z-index: 0;
  display: none;
}

.lil-gui.root > .title {
  background: #000000;
  color: #ffffff;
}

.lil-gui.root > .children {
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 1rem;
  font-size: 1.24rem;
}

@media (max-width: 767px) {
  .lil-gui.root > .children {
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 2.2rem;
  }
}
.lil-gui.root {
  display: flex;
  flex-direction: column;
  width: var(--width, 50vw);
}

@media (max-width: 767px) {
  .lil-gui.root {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
}
.lil-gui.autoPlace {
  max-height: 85vh;
  position: absolute;
  right: -96vw;
  top: 6%;
  z-index: 4;
  font-family: "Helvetica Neue LT Pro";
}

@media (max-width: 767px) {
  .lil-gui.autoPlace {
    max-height: 75vh;
    position: absolute;
    right: -100vw;
    top: 104%;
    z-index: 2;
    font-family: "Helvetica Neue LT Pro";
  }
}
.lil-gui.root > .title {
  background: #000000;
  color: var(--title-text-color);
  font-size: 5rem;
  padding-bottom: 10%;
}

@media (max-width: 767px) {
  .lil-gui.root > .title {
    background: #b85672;
    color: var(--title-text-color);
    font-size: 3rem;
    padding-bottom: 10%;
  }
}
.lil-gui .title {
  --title-height: calc(var(--widget-height) + var(--spacing)*17.25);
  -webkit-tap-highlight-color: transparent;
  text-decoration-skip: objects;
  cursor: pointer;
  font-weight: 600;
  height: var(--title-height);
  line-height: calc(var(--title-height) - 4px);
  outline: none;
  padding: 0 var(--padding);
  font-size: 4rem;
}

@media (max-width: 767px) {
  .lil-gui .title {
    --title-height: calc(var(--widget-height) + var(--spacing)*5.25);
    -webkit-tap-highlight-color: transparent;
    text-decoration-skip: objects;
    cursor: pointer;
    font-weight: 600;
    height: var(--title-height);
    line-height: calc(var(--title-height) - 4px);
    outline: none;
    padding: 0px 35vw var(--padding);
  }
}
.lil-gui {
  --background-color: #1f1f1f;
  --text-color: #ebebeb;
  --title-background-color: #111;
  --title-text-color: #ebebeb;
  --widget-color: #000000;
  --hover-color: #151515;
  --focus-color: #373737;
  --number-color: #ffffbf;
  --string-color: #b37e7e;
  --font-size: 11px;
  --input-font-size: 11px;
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif;
  --font-family-mono: Menlo, Monaco, Consolas, "Droid Sans Mono", monospace;
  --padding: 4px;
  --spacing: 4px;
  --widget-height: 31px;
  --name-width: 31%;
  --slider-knob-width: 6px;
  --slider-input-width: 0%;
  --color-input-width: 27%;
  --slider-input-min-width: 45px;
  --color-input-min-width: 45px;
  --folder-indent: 7px;
  --widget-padding: 0 0 0 3px;
  --widget-border-radius: 3px;
  --checkbox-size: calc(var(--widget-height)*0.75);
  --scrollbar-width: 23px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-align: left;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
}

.lil-gui .controller {
  align-items: center;
  display: flex;
  margin: var(--spacing) 0;
  padding: 2px var(--padding);
}

@media (max-width: 767px) {
  .lil-gui {
    --background-color: #535353;
    --text-color: #ebebeb;
    --title-background-color: #000;
    --title-text-color: #ffffff;
    --widget-color: #424242;
    /* --hover-color: #4f4f4f; */
    --focus-color: #fde2e2;
    --number-color: #ffcfcf;
    --string-color: #a2db3c;
    --font-size: 11px;
    --input-font-size: 11px;
    --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif;
    --font-family-mono: Menlo, Monaco, Consolas,
      "Droid Sans Mono", monospace;
    --padding: 4px;
    --spacing: 4px;
    --widget-height: 20px;
    --name-width: 45%;
    --slider-knob-width: 13px;
    --slider-input-width: 0%;
    --color-input-width: 0%;
    --slider-input-min-width: 45px;
    --color-input-min-width: 45px;
    --folder-indent: 0px;
    /* --widget-padding: 0 0 0 3px; */
    --widget-border-radius: 30px;
    --checkbox-size: calc(var(--widget-height)*0.75);
    --scrollbar-width: 18px;
    background-color: #7d7d7d;
    color: var(--text-color);
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-style: normal;
    font-weight: 200;
    line-height: 11px;
    text-align: left;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
  }
}
.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .image-grid {
    margin-top: 6%;
  }
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .image-grid {
    margin-top: 10%;
  }
}
@media screen and (device-width: 1680px) and (device-height: 1050px) {
  .image-grid {
    margin-top: 15%;
  }
}
@media screen and (device-width: 1440px) and (device-height: 900px) {
  .image-grid {
    margin-top: 15%;
  }
}
@media (max-width: 1023px) {
  .image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 10px;
    margin: 50%;
    position: relative;
    width: 100%;
    margin-top: 35%;
    margin-left: 25vw;
  }
}

@media (max-width: 767px) {
  .image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 0px;
    margin-top: 0%;
    margin-left: 0%;
  }
}
.image-grid img {
  width: 100%;
  height: auto;
  opacity: 1;
}

@media (max-width: 767px) {
  .image-grid img {
    width: 100%;
    height: auto;
    opacity: 1;
  }
}
.stills {
  position: absolute;
  width: 28%;
  top: 1%;
  left: 70%;
  font-size: 3rem;
  font-family: "Helvetica Neue LT Pro";
  font-size: 2rem;
  background: white;
  color: black;
  border-radius: 1rem;
  padding-left: 17px;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .stills {
    top: 1%;
    left: 36%;
  }
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .stills {
    top: 7%;
    left: 36%;
  }
}
@media screen and (device-width: 1680px) and (device-height: 1050px) {
  .stills {
    top: 6%;
    left: 37%;
  }
}
@media (max-width: 1023px) {
  .stills {
    position: absolute;
    width: 79%;
    top: 9%;
    left: 60%;
    font-size: 3rem;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    background: white;
    color: black;
    border-radius: 1rem;
    padding-left: 17px;
  }
}

@media (max-width: 767px) {
  .stills {
    position: absolute;
    width: 48vw;
    top: 11vh;
    left: -5vw;
    font-size: 3rem;
    font-family: "Helvetica Neue LT Pro";
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    background: rgba(255, 255, 255, 0);
    color: white;
    display: none;
  }
}
#arrowBackToGallery,
#arrowToCredits {
  position: absolute;
  width: 100%;
  top: 81%;
  fill: white;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  #arrowBackToGallery,
#arrowToCredits {
    margin-top: 12%;
  }
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  #arrowBackToGallery,
#arrowToCredits {
    top: 91%;
  }
}
@media screen and (device-width: 1680px) and (device-height: 1050px) {
  #arrowBackToGallery,
#arrowToCredits {
    top: 91%;
  }
}
@media (max-width: 767px) {
  #arrowBackToGallery,
#arrowToCredits {
    position: absolute;
    width: 100%;
    top: 74.6vh;
    fill: white;
  }
}
@media (max-width: 1023px) {
  #arrowBackToGallery,
#arrowToCredits {
    position: absolute;
    width: 100%;
    top: 129%;
    fill: white;
    margin-left: -21%;
  }
}
#arrowBackToGallery {
  position: absolute;
  width: 100%;
  left: 57vw;
}
@media (max-width: 1023px) {
  #arrowBackToGallery {
    position: absolute;
    left: 40%;
  }
}
@media (max-width: 767px) {
  #arrowBackToGallery {
    position: absolute;
    left: 25%;
    width: 100%;
    top: 104%;
    fill: white;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }
}

#arrowToCredits {
  position: absolute;
  width: 100%;
  left: 180%;
}
@media (max-width: 767px) {
  #arrowToCredits {
    position: absolute;
    width: 100%;
    fill: white;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    left: 17%;
    top: 104%;
  }
}

@media (max-width: 767px) {
  .arrowToCredits {
    width: 16vw;
  }
}
@media (max-width: 1023px) {
  .arrowToCredits,
.arrowBackToGallery {
    width: 17%;
  }
}
@media (max-width: 767px) {
  #loading-text {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em;
    color: white;
    text-align: center;
    opacity: 1;
    transition: opacity 1s ease-out;
    z-index: 3;
    width: 81%;
  }
}
#BackUp {
  position: absolute;
  top: 0%;
  width: 100%;
}

@media (max-width: 767px) {
  #BackUp {
    position: absolute;
    width: 19%;
    left: 32vw;
    top: -10vh;
  }
}
.credits {
  position: absolute;
  top: 0px;
  width: 100vw;
  background: #000000;
  z-index: 3;
  opacity: 1.5;
  height: 100vh;
  margin-top: -100vh;
  margin-left: 400vw;
}

@media (max-width: 767px) {
  .credits {
    position: absolute;
    top: 0px;
    width: 100vw;
    background: #000000;
    z-index: 2;
    opacity: 1.5;
    height: 100vh;
    margin-top: -100vh;
    margin-left: 350vw;
  }
}
@media (max-width: 767px) {
  .credits {
    position: absolute;
    top: 0px;
    width: 100vw;
    background: #000000;
    z-index: 8;
    opacity: 1.5;
    height: 100vh;
    margin-top: -100vh;
    margin-left: 200%;
  }
}
.laurels {
  position: absolute;
  top: 27%;
  width: 35%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  left: 32vw;
}
@media (max-width: 1023px) {
  .laurels {
    position: absolute;
    top: 22%;
    width: 54%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    left: 21vw;
  }
}

@media (max-width: 767px) {
  .laurels {
    position: absolute;
    top: 17vh;
    width: 85%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    left: 9vw;
  }
}
.laurels img {
  opacity: 1;
  width: 100%;
}

.thankYou {
  position: absolute;
  top: 27vh;
  left: 56vw;
  width: 82%;
  font-size: 3rem;
  font-family: "Helvetica Neue LT Pro";
}
@media (max-width: 1023px) {
  .thankYou {
    top: 85%;
    left: 37%;
    width: 130%;
    font-size: 4.5rem;
  }
}
@media (max-width: 767px) {
  .thankYou {
    position: absolute;
    top: 72%;
    left: 17px;
    width: 86%;
    font-size: 2.5rem;
  }
}

.thankYouNames {
  position: absolute;
  top: 21vh;
  left: 0;
  width: 100%;
  font-size: 1.5rem;
  font-family: "Suisse BP";
}
@media (max-width: 1023px) {
  .thankYouNames {
    width: 100%;
    top: 130%;
    fill: white;
    margin-left: 10%;
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .thankYouNames {
    position: absolute;
    top: 122%;
    left: 27px;
    width: 100%;
    font-size: 11px;
    font-family: "Suisse BP";
  }
}

.PremiereInterview {
  position: absolute;
  width: 100%;
  top: 59%;
  left: 15%;
  z-index: 3;
}
@media (max-width: 1023px) {
  .PremiereInterview {
    position: absolute;
    width: 243%;
    top: 33%;
    left: 11%;
    z-index: 3;
  }
}
@media (max-width: 767px) {
  .PremiereInterview {
    position: absolute;
    top: 29%;
    left: 66%;
    width: 100%;
  }
}

.framePremiere img {
  position: absolute;
  width: 100%;
  top: 0%;
  left: 0%;
}

@media (max-width: 767px) {
  .framePremiere img {
    position: absolute;
    top: 0%;
    left: 0%;
  }
}
.PremiereInterview img {
  opacity: 1;
  width: 10%;
  -webkit-box-shadow: -18px 18px 0px 2px #000, -18px 18px 0px 3px white;
  -moz-box-shadow: -18px 18px 0px 2px #000, -18px 18px 0px 3px white;
  box-shadow: -18px 18px 0px 2px #000, -18px 18px 0px 3px white;
  border: 1px solid #ffffff;
}

@media (max-width: 767px) {
  .PremiereInterview img {
    opacity: 1;
    width: 26%;
    -webkit-box-shadow: -5px 5px 0px 2px #000, -5px 5px 0px 3px white;
    -moz-box-shadow: -5px 5px 0px 2px #000, -5px 5px 0px 3px white;
    box-shadow: -5px 5px 0px 2px #000, -5px 5px 0px 3px white;
    border: 1px solid #ffffff;
  }
}
.TempoInterview {
  position: absolute;
  top: 45%;
  left: 2%;
  width: 48%;
}
@media (max-width: 1023px) {
  .TempoInterview {
    position: absolute;
    top: 33%;
    left: 43%;
    width: 83%;
    height: 83%;
  }
}
@media (max-width: 767px) {
  .TempoInterview {
    position: absolute;
    top: 31%;
    left: 15%;
  }
}

.frameTempo img {
  opacity: 1;
  width: 40%;
  -webkit-box-shadow: -18px 18px 0px 2px #000, -18px 18px 0px 3px white;
  -moz-box-shadow: -18px 18px 0px 2px #000, -18px 18px 0px 3px white;
  box-shadow: -18px 18px 0px 2px #000, -18px 18px 0px 3px white;
  border: 1px solid #ffffff;
}

@media (max-width: 1023px) {
  .frameTempo img {
    width: 53%;
  }
}
@media (max-width: 767px) {
  .frameTempo img {
    opacity: 1;
    width: 50%;
    -webkit-box-shadow: -7px 7px 0px 2px #000, -7px 7px 0px 3px white;
    -moz-box-shadow: -7px 7px 0px 2px #000, -7px 7px 0px 3px white;
    box-shadow: -7px 7px 0px 2px #000000, -7px 7px 0px 3px white;
    border: 1px solid #ffffff;
  }
}
.screenings {
  position: absolute;
  width: 46%;
  font-size: 5rem;
  lefT: 27%;
  top: 16%;
  font-family: "Helvetica Neue LT Pro";
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .screenings {
    left: 33%;
    top: 16%;
  }
}
@media screen and (device-width: 1650px) and (device-height: 1050px) {
  .screenings {
    left: 33%;
    top: 16%;
  }
}
@media (max-width: 1023px) {
  .screenings {
    position: absolute;
    width: 81%;
    font-size: 8rem;
    left: 14%;
    top: 16%;
    font-family: "Helvetica Neue LT Pro";
  }
}

@media (max-width: 767px) {
  .screenings {
    position: absolute;
    width: 100%;
    font-size: 3.9rem;
    lefT: 8%;
    top: 11%;
    font-family: "Helvetica Neue LT Pro";
  }
}
.screeningsText {
  position: absolute;
  width: 100%;
  font-size: 2rem;
  top: 426%;
  z-index: 5;
  left: 10%;
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .screeningsText {
    left: 0%;
    top: 426%;
  }
}
@media screen and (device-width: 1650px) and (device-height: 1050px) {
  .screeningsText {
    left: 0%;
    top: 426%;
  }
}
@media (max-width: 1023px) {
  .screeningsText {
    position: absolute;
    width: 156%;
    font-size: 3rem;
    top: 290%;
    z-index: 5;
    left: 0%;
    margin-top: 60%;
  }
}

@media (max-width: 767px) {
  .screeningsText {
    position: absolute;
    width: 88%;
    font-size: 1.9rem;
    top: 10vh;
    z-index: 5;
    left: 1%;
  }
}
#arrowToResults {
  position: absolute;
  width: 100%;
  left: 3%;
  fill: white;
  top: 81%;
}

@media (max-width: 767px) {
  #arrowToResults {
    position: absolute;
    width: 100vw;
    fill: white;
    flex-wrap: nowrap;
    justify-content: flex-start;
    left: 0%;
    margin-top: -10%;
    margin-left: -30%;
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .arrowBackToResults {
    position: absolute;
    width: 18.5%;
  }
}
@media (max-width: 767px) {
  .scrollContainer {
    position: fixed;
    top: 20;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    /* Hide the overflowing content */
  }

  .storyboard {
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 0%;
    width: 100vw;
    z-index: 5;
    display: flex;
  }

  .subTitle__Storyboard {
    font-family: "Helvetica Neue LT Pro";
    color: white;
    position: absolute;
    font-size: 4.2rem;
    top: 11vh;
    left: 0vh;
    width: 100%;
    z-index: 3;
    opacity: 1;
  }

  .scrollContent {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }

  .theNegroSpaceProgram_wrapper,
.case,
.context,
.concept {
    height: 100vh;
  }

  /* Each section should be at least the height of the viewport */
  .scrollContent section {
    height: 100vh;
  }

  .theNegroSpaceProgram {
    background: black;
    overflow: none;
  }

  .theNegroSpaceProgram__wrapper {
    animation: fadeIn 4s ease-in forwards;
  }

  .headerVideo__mobile {
    display: block;
    position: absolute;
    z-index: 0;
    pointer-events: none;
    width: 120%;
    height: auto;
    opacity: 0;
    z-index: -2;
    animation: fadeIn 4s ease-in forwards;
  }

  #headerVideo {
    position: absolute;
    width: 100%;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .theNegroSpaceProgram__wrapper {
    top: 0;
    position: absolute;
    width: 100%;
    overflow: none;
  }

  .theNegroSpaceProgram__title {
    font-family: "Nd Tupa Nova";
    font-size: 17.5rem;
    position: absolute;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .preface {
    font-family: "Helvetica Neue LT Pro";
    font-size: 5.5rem;
    position: absolute;
    margin-top: 29%;
    display: flex;
    height: 100%;
    width: 100%;
    opacity: 1;
    margin-left: 0%;
  }

  .preface_Title,
.preface_Title2 {
    position: absolute;
    font-size: 13rem;
    width: 100vw;
    top: 76vh;
    z-index: 1;
    height: 100vh;
    background-color: black;
    color: rgba(255, 255, 255, 0.25);
    transition: color 0.5s ease-in-out;
    padding-bottom: 4px;
  }

  .preface_Title.active,
.preface_Title2.active {
    color: white;
  }

  .preface_Title:hover:not(.no-hover),
.preface_Title2:hover:not(.no-hover) {
    color: white;
  }

  .preface_Title2 {
    margin-left: 0%;
    top: 1vh;
    font-size: 12rem;
    z-index: 0;
    padding-bottom: -23vw;
  }

  .preface_Title {
    position: absolute;
    top: 9vh;
    left: -12vw;
    font-size: 10rem;
    padding-top: 0%;
  }

  .storyboard__Title,
.menu__ac {
    font-family: "Helvetica Neue LT Pro";
    position: fixed;
    font-size: 13rem;
    width: auto;
    z-index: 1;
    height: auto;
    background-color: #00000000;
    color: rgba(255, 255, 255, 0.25);
    transition: color 0.5s ease-in-out;
    flex-wrap: wrap;
    padding: 30px 200px 31px 150px;
    margin-top: 35.5%;
    position: fixed;
    bottom: -103vh;
  }

  .storyboard__Title.active,
.menu__ac.active {
    color: white;
  }

  .storyboard__Title:hover:not(.no-hover),
.menu__ac:hover:not(.no-hover) {
    color: white;
  }

  .storyboard__Title {
    position: absolute;
    z-index: 2;
    width: auto;
    pointer-events: all;
    font-size: 7rem;
    left: -36vw;
    height: 100%;
    top: 45vh;
    display: none;
    pointer-events: none;
  }

  .menu__ac {
    position: fixed;
    width: 100vw;
    margin-left: 37vw;
    pointer-events: all;
    top: -21.2vh;
    height: 100vh;
    font-size: 4rem;
    display: none;
    pointer-events: none;
  }

  .case {
    position: absolute;
    top: 100vh;
    width: 200vw;
    background: #000000;
    z-index: 2;
    height: 50vh;
  }

  .context {
    position: absolute;
    top: 0vh;
    width: 100vw;
    height: 50vh;
    background: #000000;
    z-index: 2;
    overflow: scroll;
  }

  .context__flexbox {
    position: absolute;
    display: flex;
    height: auto;
    padding: 15px;
    top: -2vh;
    flex-direction: column;
    width: 100vw;
    align-content: center;
    align-items: stretch;
    justify-content: space-around;
    overflow: scroll;
  }

  .concept {
    position: absolute;
    top: 0px;
    width: 100vw;
    background: #000000;
    z-index: 2;
    height: 100vh;
    margin-top: -100vh;
    margin-left: 100vw;
  }

  .poem {
    font-size: 2rem;
    position: absolute;
    top: 46vh;
    width: 100vw;
    color: white;
    line-height: auto;
    z-index: 14;
    left: 19vw;
    font-family: "Helvetica Neue LT Pro";
  }

  #arrow2ToAssets {
    position: absolute;
    width: 34vw;
    top: 74vh;
    left: 60vw;
    fill: white;
    display: block;
  }

  #arrow2Animatic {
    position: absolute;
    width: 34vw;
    top: 74vh;
    left: 28vw;
    fill: white;
    display: none;
  }

  #arrow2BackToContext {
    position: absolute;
    width: 22vw;
    top: 77vh;
    left: 7vw;
    fill: white;
    display: block;
  }

  #storyboardVideo {
    position: absolute;
    width: 90%;
    margin-top: 37%;
    margin-left: 5vw;
    border-radius: 24px;
  }

  .process {
    position: absolute;
    top: 0px;
    width: 100vw;
    z-index: 4;
    opacity: 1.5;
    height: 100vh;
    margin-top: -100vh;
    margin-left: 200vw;
    pointer-events: none;
  }

  .blackProcessBg {
    position: absolute;
    height: 200vh;
    width: 100vw;
    background-color: black;
    z-index: 0;
    margin-top: -100vh;
    margin-left: 200vw;
  }

  .results {
    position: absolute;
    top: 0px;
    width: 100vw;
    background: #000000;
    z-index: 2;
    opacity: 1.5;
    height: 150vh;
    margin-top: -100vh;
    margin-left: 300vw;
  }

  .Breakdown__title {
    font-family: "Nd Tupa Nova";
    font-size: 11.5rem;
    position: relative;
    width: 100vw;
    display: flex;
    height: 100vh;
    justify-content: center;
    top: 50vh;
  }

  .manifesto_text1,
.manifesto_text2,
.manifesto_text3 {
    font-family: "Helvetica Neue LT Pro";
    font-size: 1.1rem;
    width: 118vw;
    height: 21%;
    font-size: 1.8rem;
    line-height: 2;
  }

  #arrowBackToContext,
#arrowToAssets {
    margin-top: 84vh;
    display: none;
    pointer-events: none;
  }

  #arrowToCase svg {
    position: absolute;
    margin-left: 1vw;
    margin-top: -28.5vh;
    fill: white;
    z-index: 54;
    pointer-events: all;
    width: 50px;
  }

  .festival_notification {
    position: absolute;
    font-family: "Helvetica Neue LT Pro";
    font-size: 3.1rem;
    width: 41vw;
    text-align: center;
    margin-top: 50%;
    margin-left: 32%;
    z-index: 1;
    background-color: black;
    color: white;
    height: 10vh;
    border-radius: 3px;
    border-style: outset;
    border-color: white;
    border-width: medium;
    opacity: 0.5;
  }
}
@media (max-width: 767px) and (max-width: 1023px) {
  .festival_notification {
    position: absolute;
    font-family: "Helvetica Neue LT Pro";
    font-size: 3.1rem;
    width: 41vw;
    text-align: center;
    margin-top: 50%;
    margin-left: 32%;
    z-index: 1;
    background-color: black;
    color: white;
    height: 10vh;
    border-radius: 3px;
    border-style: outset;
    border-color: white;
    border-width: medium;
    opacity: 0.5;
    transform: translate(0px, 0px);
  }
}
@media (max-width: 767px) {
  .access {
    position: absolute;
    height: auto;
    width: 138%;
    top: 11rem;
    text-align: left;
    left: 19%;
    font-size: 1.3rem;
  }
}
@media (max-width: 767px) {
  .face__canvas {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }
}
@media (max-width: 767px) {
  #arrowBackToContext svg {
    position: absolute;
    fill: white;
    z-index: 2;
    stroke-width: 2px;
    stroke: white;
    stroke-opacity: 0.25;
    transition: all 1s ease;
    pointer-events: all;
    height: 100%;
    width: 36vw;
    left: 0vw;
    top: -8.3vh;
  }
}
@media (max-width: 767px) {
  .arrowBackToContext {
    pointer-events: all;
  }
}
@media (max-width: 767px) {
  #arrowBackToContext:hover {
    fill: white;
    stroke-width: 0;
    stroke: white;
    stroke-opacity: 1;
    pointer-events: all;
  }
}
@media (max-width: 767px) {
  #arrowToAssets svg {
    display: none;
    pointer-events: none;
  }
}
@media (max-width: 767px) {
  .arrowToAssets {
    pointer-events: all;
  }
}
@media (max-width: 767px) {
  #arrowToAssets:hover {
    fill: white;
    stroke-width: 0;
    stroke: white;
    stroke-opacity: 1;
    pointer-events: all;
    stroke: white;
    stroke-width: 0px;
  }
}
@media (max-width: 767px) {
  #storyboardCharacterRun {
    position: absolute;
    width: 43%;
    margin-top: 211%;
    margin-left: -1%;
    border: 0.5px solid #c8c8c8;
  }
}
@media (max-width: 767px) {
  .process__menu,
.process__menu2 {
    display: none;
    pointer-events: none;
  }
}
@media (max-width: 767px) {
  .arrowBackToProcess {
    pointer-events: all;
  }
}
@media (max-width: 767px) {
  .arrowBackToProcess {
    pointer-events: all;
  }
}
@media (max-width: 767px) {
  #container2 {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 2;
    pointer-events: none;
    top: -100vh;
    margin-left: -28vw;
  }
}
@media (max-width: 767px) {
  .navdescription__nebula {
    font-family: "Helvetica Neue LT Pro";
    height: 100%;
    width: 82%;
    position: absolute;
    margin-top: 59px;
    margin-left: 98px;
    z-index: 1;
    opacity: 1;
    font-size: 0.7rem;
    color: #ffffff;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
  }
}
#container2 {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 2;
  pointer-events: none;
  top: -100vh;
  left: 114%;
}
@media (max-width: 1023px) {
  #container2 {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 2;
    pointer-events: none;
    top: -89vh;
    left: 100%;
  }
}

.o {
  scroll-snap-align: start;
  position: absolute;
  margin-top: -3vh;
  height: 0px;
  width: 180px;
  background: #ffffff;
  pointer-events: all;
  z-index: 10;
  left: -3%;
}

@media (max-width: 767px) {
  .o {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50%;
    margin-top: 19%;
    text-align: left;
  }
}
.o1 {
  top: 50%;
  margin-top: -30%;
  margin-left: -24%;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .o1 {
    top: 47vw;
    left: -6vw;
  }
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .o1 {
    top: 34%;
  }
}
@media screen and (device-width: 1680px) and (device-height: 1050px) {
  .o1 {
    top: 36%;
  }
}
@media screen and (device-width: 1440px) and (device-height: 900px) {
  .o1 {
    top: 47vw;
  }
}
@media (max-width: 1023px) {
  .o1 {
    top: 4%;
    margin-top: -30%;
    margin-left: -30%;
  }
}

@media (max-width: 767px) {
  .o1 {
    top: 31.5%;
  }
}
.o2 {
  top: -6%;
  pointer-events: all;
  z-index: 100;
  left: -27%;
}
@media screen and (device-width: 1680px) and (device-height: 1050px) {
  .o2 {
    top: -10%;
  }
}
@media screen and (device-width: 1440px) and (device-height: 900px) {
  .o2 {
    margin-top: -4%;
  }
}

@media (max-width: 767px) {
  .o2 {
    top: 100px;
    margin-left: 121%;
  }
}
.o3 {
  top: 0%;
  left: -30%;
}
@media (max-width: 1023px) {
  .o3 {
    top: -15%;
    left: -30%;
  }
}

@media (max-width: 767px) {
  .o3 {
    top: 300px;
    left: 0%;
  }
}
.o4 {
  top: 6%;
  left: -26%;
}
@media screen and (device-width: 1920px) and (device-height: 1080px) {
  .o4 {
    top: 3%;
    left: -26%;
  }
}

@media (max-width: 767px) {
  .o4 {
    top: -5%;
    left: 0%;
  }
}
.o5 {
  top: 600px;
}

@media (max-width: 767px) {
  .o5 {
    top: 600px;
  }
}
@media (max-width: 767px) {
  #container2 {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 2;
    pointer-events: none;
    top: -100vh;
    left: 114%;
    top: -103vh;
  }
}
#clip-path-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  clip-path: inset(-100vh -50vw 30vh 45vw);
  pointer-events: none;
  top: -13%;
  left: 77%;
  z-index: 4;
}

#negrospaceprogram__wrap {
  position: relative;
  width: 200%;
  height: 200%;
  top: 19vh;
  margin-top: -32%;
  z-index: 5;
  margin-left: 109%;
  pointer-events: inherit;
}

@media (max-width: 767px) {
  #negrospaceprogram__wrap {
    top: -69vh;
    left: -113vw;
    height: 136%;
    width: 100%;
  }
}
.processGallery__wip {
  display: flex;
  position: absolute;
  top: -100vh;
  z-index: 3;
  border: none;
  width: 12%;
  list-style: none;
  text-align: left;
  padding: 0;
  left: 100%;
  background-color: #000000;
  padding-left: 40px;
  padding-top: 50vh;
  padding-bottom: 0%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  flex-direction: column-reverse;
  align-items: baseline;
  display: none;
}

@media (max-width: 767px) {
  .processGallery__wip {
    font-family: "Helvetica Neue LT Pro";
    z-index: 3;
    position: absolute;
    top: -98vh;
    left: 92vw;
    background: none;
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    width: 100%;
    display: none;
    pointer-events: none;
  }
}
.processGallery__wip li {
  font-size: 35px;
  border: none;
  color: white;
  transition: color 1s;
}

@media (max-width: 767px) {
  .processGallery__wip li {
    font-size: 2rem;
    padding-left: 9px;
  }
}
@media (max-width: 1023px) {
  .processGallery__wip li {
    font-size: 2rem;
    padding-left: 9px;
  }
}
.navdescription__character2 {
  height: 100%;
  width: 100%;
  position: relative;
  margin-top: 2%;
  z-index: 1;
  opacity: 1;
  color: #ffffff;
  text-align: left;
  transition: opacity 0.1s ease-in-out;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .navdescription__character2 {
    width: max-content;
  }
}
@media (max-width: 1023px) {
  .navdescription__character2 {
    height: 100%;
    width: 100%;
    position: relative;
    margin-top: 2%;
    z-index: 1;
    opacity: 1;
    color: #ffffff;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
    margin-left: 63%;
  }
}

@media (max-width: 767px) {
  .navdescription__character2 {
    height: 100%;
    width: 100%;
    position: relative;
    margin-top: -17%;
    z-index: 1;
    opacity: 1;
    color: #ffffff;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
  }
}
.navtitle__nebula {
  font-family: "Nd Tupa Nova";
  position: relative;
  margin-top: 0%;
  margin-left: 65%;
  z-index: 1;
  opacity: 1;
  color: rgba(255, 255, 255, 0);
  text-align: left;
  transition: opacity 0.1s ease-in-out;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: white;
  font-size: 2.7rem;
  line-height: 2.7rem;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .navtitle__nebula {
    top: -0.25vw;
  }
}
@media (max-width: 1023px) {
  .navtitle__nebula {
    position: relative;
  }
}
@media (max-width: 767px) {
  .navtitle__nebula {
    font-family: "Nd Tupa Nova";
    position: relative;
    margin-top: -6%;
    margin-left: 100%;
    z-index: 1;
    opacity: 1;
    color: rgba(0, 0, 0, 0);
    text-align: left;
    transition: opacity 0.1s ease-in-out;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: white;
    font-size: 2.7rem;
    line-height: 2.7rem;
  }
}

.navtitle__spacecapsule, .navtitle__character1, .navtitle__character2 {
  font-family: "Nd Tupa Nova";
  color: #ffffff00;
  font-size: 2.4rem;
  margin-top: -72%;
  line-height: 2.2rem;
  width: 100%;
  margin-left: 116%;
  height: 100%;
  -webkit-text-stroke-color: white;
  -webkit-text-stroke-width: 0.2px;
  position: relative;
}
@media (max-width: 1023px) {
  .navtitle__spacecapsule, .navtitle__character1, .navtitle__character2 {
    font-size: 2.4rem;
    margin-top: -52%;
    line-height: 2.2rem;
    width: 100%;
    margin-left: 110%;
    height: 100%;
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 0.2px;
    position: relative;
  }
}

@media (max-width: 767px) {
  .navtitle__spacecapsule, .navtitle__character1, .navtitle__character2 {
    font-family: "Nd Tupa Nova";
    color: #ffffff00;
    font-size: 2.4rem;
    margin-top: 28%;
    line-height: 2.2rem;
    width: 100%;
    margin-left: 67%;
    height: 100%;
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 0.5px;
  }
}
.navtitle__spacecapsule {
  position: relative;
  left: -4.6vw;
  top: 12.5vh;
}
@media (max-width: 1023px) {
  .navtitle__spacecapsule {
    position: relative;
    left: -4.2vw;
    top: -3.2vh;
  }
}
@media (max-width: 767px) {
  .navtitle__spacecapsule {
    position: relative;
    left: 11vw;
    top: 0%;
    font-size: 2.7rem;
    -webkit-text-stroke-width: 0.2px;
  }
}

.navtitle__character1 {
  margin-top: 0%;
  margin-left: 87%;
}
@media (max-width: 767px) {
  .navtitle__character1 {
    margin-top: -23%;
    margin-left: 78%;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 0.2px;
    font-size: 2.7rem;
  }
}

.navdescription__nebula {
  font-family: "Helvetica Neue LT Pro";
  height: 100%;
  position: relative;
  margin-top: 3%;
  margin-left: 98px;
  z-index: 1;
  opacity: 1;
  font-size: 0.7rem;
  color: #ffffff;
  text-align: left;
  transition: opacity 0.1s ease-in-out;
  width: 100%;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .navdescription__nebula {
    width: max-content;
  }
}
@media (max-width: 767px) {
  .navdescription__nebula {
    margin-top: 43px;
    z-index: 1;
    opacity: 1;
    font-size: 0.7rem;
    color: #ffffff;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
    left: 56%;
    width: 35%;
  }
}

.navdescription__nebula, .navdescription__character1, .navdescription__character2, .navdescription__spacecapsule {
  font-family: "Suisse BP";
  font-size: 0.35rem;
  margin-left: 65%;
  line-height: auto;
  position: relative;
}
@media (max-width: 1023px) {
  .navdescription__nebula, .navdescription__character1, .navdescription__character2, .navdescription__spacecapsule {
    font-family: "Suisse BP";
    font-size: 0.75rem;
    line-height: auto;
    position: relative;
  }
}
@media (max-width: 767px) {
  .navdescription__nebula, .navdescription__character1, .navdescription__character2, .navdescription__spacecapsule {
    font-size: 0.5rem;
    margin-top: 1%;
    margin-left: 39%;
    line-height: 4px;
    font-family: "Suisse BP";
  }
}

.navdescription__character1 {
  margin-left: 86%;
  width: 100%;
  height: 100%;
  margin-top: 8%;
}
@media (max-width: 767px) {
  .navdescription__character1 {
    margin-left: 84%;
    width: 100%;
    height: 100%;
    margin-top: 6%;
  }
}

@media (max-width: 767px) {
  .navdescription__character1 {
    margin-left: 79%;
    width: 100%;
    height: 100%;
    margin-top: 8%;
  }
}
.navtitle__character2 {
  font-family: "Nd Tupa Nova";
  position: relative;
  margin-top: 0%;
  margin-left: 65%;
  z-index: 1;
  opacity: 1;
  text-align: left;
  transition: opacity 0.1s ease-in-out;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: white;
  font-size: 2.7rem;
  line-height: 2.7rem;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .navtitle__character2 {
    width: 102%;
    margin-top: -3vh;
    top: -1.5vh;
  }
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .navtitle__character2 {
    width: max-content;
  }
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .navtitle__character2 {
    width: 102%;
    margin-top: -3vh;
  }
}
@media screen and (device-width: 1440px) and (device-height: 900px) {
  .navtitle__character2 {
    width: 102%;
    margin-top: 0vh;
  }
}
@media (max-width: 1023px) {
  .navtitle__character2 {
    font-family: "Nd Tupa Nova";
    position: relative;
    margin-top: -30%;
    margin-left: 65%;
    z-index: 1;
    opacity: 1;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: white;
    font-size: 2.7rem;
    line-height: 2.7rem;
  }
}

@media (max-width: 767px) {
  .navtitle__character2 {
    font-size: 2.7rem;
    position: absolute;
    height: 100%;
    width: 100px;
    margin-top: 4%;
    margin-left: 40%;
    z-index: 1;
    opacity: 1;
    color: rgba(0, 0, 0, 0);
    text-align: left;
    transition: opacity 0.1s ease-in-out;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #fffcfc;
    line-height: 2.7rem;
  }
}
.navdescription__spacecapsule {
  margin-left: 65%;
  width: 100%;
  height: 100%;
  margin-top: 3%;
}
@media (max-width: 1023px) {
  .navdescription__spacecapsule {
    margin-left: 91%;
    width: 100%;
    height: 100%;
    margin-top: 3%;
  }
}

@media (max-width: 767px) {
  .navdescription__spacecapsule {
    margin-left: 93%;
    width: 100%;
    height: 100%;
    margin-top: 1%;
    position: relative;
  }
}
.software,
.softwareBox {
  margin-bottom: 15px;
  /* Change the space between rows */
}

.status {
  font-family: "Helvetica Neue LT Pro";
  font-size: 0.7rem;
  margin-top: 0.8px;
  margin-left: 5px;
  color: #000000;
}

.statusBox {
  position: absolute;
  height: 1.3rem;
  width: 6rem;
  background: #00d32a;
  z-index: -1;
  margin-lefT: 22%;
  margin-top: 18.4%;
  border-radius: 5px;
  z-index: -1;
}

.software1, .software2, .software3, .software4 {
  font-family: "Helvetica Neue LT Pro";
  font-size: 0.7rem;
  margin-top: 1.5%;
  height: 100%;
  color: black;
}

.software1 {
  margin-left: 13%;
}

.software2 {
  margin-left: 13%;
  margin-top: -3%;
}

.software3 {
  margin-left: 13%;
}

.software4 {
  margin-left: 13%;
}

.softwareBox2, .softwareBox3, .softwareBox4, .softwareBox5 {
  position: relative;
  height: 1.1rem;
  width: max-content;
  margin-top: 17%;
  margin-left: 65%;
  z-index: -1;
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 39%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  color: black;
}
@media (max-width: 1023px) {
  .softwareBox2, .softwareBox3, .softwareBox4, .softwareBox5 {
    position: relative;
    height: 1.1rem;
    width: 14rem;
    margin-top: 8%;
    margin-left: 65%;
    z-index: -1;
    border-radius: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 39%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    color: black;
  }
}
@media (max-width: 767px) {
  .softwareBox2, .softwareBox3, .softwareBox4, .softwareBox5 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 47%;
    font-size: 0.55rem;
    color: black;
    font-family: "Helvetica Neue LT Pro";
    position: relative;
    height: 1.3rem;
    z-index: -1;
    border-radius: 5px;
    margin-left: 39%;
  }
}

.softwareBox3 {
  position: relative;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .softwareBox3 {
    width: 11rem;
  }
}
@media (max-width: 767px) {
  .softwareBox3 {
    margin-top: 14%;
  }
}

.softwareBox4 {
  position: relative;
  width: 16rem;
  margin-top: 16%;
  margin-left: 85%;
  z-index: -1;
  height: 100%;
  gap: 1px;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .softwareBox4 {
    width: 111%;
    padding-bottom: 21%;
  }
}
@media screen and (device-width: 1440px) and (device-height: 900px) {
  .softwareBox4 {
    width: 11rem;
    margin-bottom: 2.3vh;
  }
}
@media (max-width: 1023px) {
  .softwareBox4 {
    position: relative;
    width: 28rem;
    margin-top: 8%;
    margin-left: 90%;
    z-index: -1;
    height: 100%;
    gap: 1px;
  }
}

@media (max-width: 767px) {
  .softwareBox4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 55%;
    font-size: 0.55rem;
    color: black;
    margin-left: 79%;
    margin-top: -14%;
    align-content: space-between;
    position: relative;
    font-family: "Helvetica Neue LT Pro";
  }
}
.softwareBox5 {
  position: relative;
  height: 1.1rem;
  width: max-content;
  margin-top: 90%;
  margin-left: 65%;
  z-index: -1;
}
@media screen and (device-width: 1920px) and (device-height: 1080px) {
  .softwareBox5 {
    width: max-content;
    top: 0%;
    left: 0%;
    padding-top: 12%;
    padding-bottom: 5%;
  }
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .softwareBox5 {
    width: max-content;
  }
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .softwareBox5 {
    width: 47%;
  }
}
@media screen and (device-width: 1680px) and (device-height: 1050px) {
  .softwareBox5 {
    width: 33%;
  }
}
@media screen and (device-width: 1440px) and (device-height: 900px) {
  .softwareBox5 {
    width: 29%;
    margin-top: 80%;
  }
}
@media (max-width: 1023px) {
  .softwareBox5 {
    position: relative;
    height: 1.1rem;
    width: 19%;
    margin-top: -12%;
    margin-left: 92%;
    z-index: -1;
  }
}

@media (max-width: 767px) {
  .softwareBox5 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 28%;
    font-size: 0.55rem;
    color: black;
    font-family: "Helvetica Neue LT Pro";
    margin-left: 93%;
    margin-top: 10%;
  }
}
.software3 {
  font-family: "Helvetica Neue LT Pro";
  color: white;
  position: absolute;
  height: 1.1rem;
  width: 14rem;
  margin-top: 0;
  margin-left: -6.5rem;
  z-index: -1;
}

.software {
  font-family: "Helvetica Neue LT Pro";
}

.softwareBox1 {
  position: relative;
  height: 1.1rem;
  width: 7rem;
  margin-top: 34px;
  margin-left: 65%;
  z-index: -1;
  border-radius: 1px;
}

.softwareBox2 {
  font-family: "Helvetica Neue LT Pro";
  font-size: 8px;
  width: max-content;
  height: 9px;
  position: relative;
  margin-left: 117px;
  margin-top: 2%;
  border-radius: 2px;
}
@media screen and (device-width: 3840px) and (device-height: 2160px) {
  .softwareBox2 {
    width: max-content;
    padding-bottom: 1vh;
  }
}
@media screen and (device-width: 2560px) and (device-height: 1600px) {
  .softwareBox2 {
    width: 56%;
  }
}
@media screen and (device-width: 1680px) and (device-height: 1050px) {
  .softwareBox2 {
    width: 39%;
  }
}
@media screen and (device-width: 1440px) and (device-height: 900px) {
  .softwareBox2 {
    width: 34%;
    margin-top: 2%;
  }
}
@media (max-width: 767px) {
  .softwareBox2 {
    font-size: 0.6rem;
    width: 26%;
    margin-left: 94%;
    position: relative;
    text-align: left;
    margin-top: 2%;
    border-radius: 2px;
    color: black;
  }
}

.software1 {
  font-family: "Helvetica Neue LT Pro";
  font-size: 0.7rem;
  color: black;
  margin-left: -1rem;
}

.thepark {
  background: #FEC4C4;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.thepark ::-webkit-scrollbar {
  display: none;
}

.thepark::-webkit-scrollbar {
  display: none;
}

.thepark__wrapper {
  top: 0;
  position: absolute;
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.thepark__wrapper ::-webkit-scrollbar {
  display: none;
}

@media (max-width: 767px) {
  .thepark__wrapper {
    top: 0;
    position: absolute;
    width: 100%;
    overflow: scroll;
    height: 100%;
  }
}
.thepark__title {
  position: relative;
  font-family: "Nd Tupa Nova";
  font-size: 20rem;
  display: flex;
  align-content: center;
  justify-content: center;
  -webkit-text-stroke: 2px;
  -webkit-text-stroke-color: #ffa2a2;
  text-shadow: 3px 3px #b65c5c;
}

@media (max-width: 767px) {
  .thepark__title {
    position: relative;
    margin-top: 21%;
  }
}
.thepark__video {
  width: 100%;
  height: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3%;
  position: relative;
  padding-bottom: 7%;
}

@media (max-width: 767px) {
  .thepark__video {
    width: 100%;
    display: flex;
    margin-top: -24%;
    display: flex;
    align-content: center;
  }
}
.thepark__deliverables {
  position: relative;
  width: 37%;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  font-size: 1.5rem;
  font-family: "Helvetica Neue LT Pro";
  color: #2e408a;
  padding-top: 2%;
}

@media (max-width: 767px) {
  .thepark__deliverables {
    position: relative;
    width: 84%;
    margin-top: -22%;
    color: black;
    font-family: "Helvetica Neue LT Pro";
    height: auto;
    font-size: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: 8%;
    margin-top: -22%;
  }
}
.thepark__container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
}

.thepark_intro {
  position: relative;
  width: 100%;
  margin-top: 0%;
  display: flex;
  justify-content: center;
  padding-top: 2%;
  font-family: "Suisse BP";
  color: #2e408a;
  padding-bottom: 7%;
}

@media (max-width: 767px) {
  .thepark_intro {
    position: relative;
    width: 75%;
    opacity: 1;
    height: auto;
    font-size: 2.3rem;
    color: black;
    font-family: system-ui;
    margin-top: 4%;
  }
}
.headOf3D {
  position: relative;
  width: 100%;
  height: auto;
  -webkit-text-stroke: 2px;
  -webkit-text-stroke-color: #ffc7c7;
  text-shadow: 3px 3px #ff5757;
  font-size: 10rem;
  font-family: "Nd Tupa Nova";
  display: flex;
  justify-content: center;
}

@media (max-width: 1023px) {
  .headOf3D {
    position: relative;
    width: 100%;
    height: auto;
    -webkit-text-stroke: 2px;
    -webkit-text-stroke-color: #ffc7c7;
    text-shadow: 3px 3px #ff5757;
    font-size: 10rem;
    font-family: "Nd Tupa Nova";
    display: flex;
    justify-content: center;
    font-size: 15rem;
  }
}
.headOf3Dimage {
  position: relative;
  width: 29%;
  opacity: 1;
  padding-top: 3%;
  border-radius: 50px;
}
@media (max-width: 1023px) {
  .headOf3Dimage {
    position: relative;
    width: 50%;
    opacity: 1;
    padding-top: 3%;
    border-radius: 50px;
  }
}
@media (max-width: 767px) {
  .headOf3Dimage {
    position: relative;
    width: 89%;
    opacity: 1;
    padding-top: 3%;
    border-radius: 50px;
  }
}

@media (max-width: 767px) {
  .headOf3D {
    position: relative;
    width: 100%;
    height: auto;
    -webkit-text-stroke: 2px;
    -webkit-text-stroke-color: #ffc7c7;
    text-shadow: 3px 3px #ff5757;
    font-size: 13rem;
    font-family: "Nd Tupa Nova";
    display: flex;
    justify-content: center;
    padding-bottom: 5%;
    padding-top: 12%;
  }
}
.myTask {
  position: relative;
  width: 40%;
  height: auto;
  display: flex;
  justify-content: center;
  color: #2e408a;
  font-family: "Suisse BP";
  align-items: center;
}

@media (max-width: 767px) {
  .myTask {
    position: relative;
    width: 100%;
    height: auto;
    color: black;
    padding-top: 7%;
    padding-bottom: 7%;
  }
}
@media (max-width: 767px) {
  .charactersImage {
    position: relative;
    width: 100%;
    opacity: 1;
  }
}
.thepark__containerMyTask {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  justify-content: center;
  gap: 7%;
  background: #ffd2d2;
  filter: drop-shadow(3px 1px 2px #ae8585);
  padding-bottom: 4%;
}

@media (max-width: 767px) {
  .thepark__containerMyTask {
    display: flex;
    position: relative;
    flex-direction: column-reverse;
    padding-bottom: 4%;
    align-items: center;
    flex-wrap: nowrap;
    align-items: center;
  }
}
.thepark_container1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
}

.thepark_characterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  padding-bottom: 10%;
}

.charactersImage {
  position: relative;
  width: 89%;
  opacity: 1;
}

.thepark_characterDescription {
  position: relative;
  display: flex;
  width: 70%;
  color: #2e408a;
  font-weight: 300;
}

@media (max-width: 1023px) {
  .thepark_characterDescription {
    position: relative;
    display: flex;
    width: 70%;
    color: #2e408a;
    font-weight: 300;
    font-size: 4rem;
  }
}
@media (max-width: 767px) {
  .thepark_characterDescription {
    position: relative;
    display: flex;
    width: 89%;
    color: #2e408a;
    font-weight: 300;
    font-size: 3rem;
  }
}
.thepark_videoContainer {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 10%;
  gap: 50px;
}

.thepark__sidebysideVideo {
  position: relative;
  top: 0%;
  display: flex;
  width: 50%;
}

@media (max-width: 767px) {
  .thepark__sidebysideVideo {
    position: relative;
    top: 0%;
    display: flex;
    width: 93%;
    border-radius: 43px;
  }
}
.thepark__videoText {
  position: relative;
  display: flex;
  width: 50%;
  color: #2e408a;
  font-weight: 300;
}

@media (max-width: 767px) {
  .thepark__videoText {
    position: relative;
    display: flex;
    width: 91%;
    color: #2e408a;
    font-weight: 300;
  }
}
.placesCredits {
  position: relative;
  display: flex;
  justify-content: center;
}

.theparkCredits {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: 12%;
}

@media (max-width: 767px) {
  .theparkCredits {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: -100%;
    position: relative;
    margin-top: 200%;
  }
}
@media (max-width: 767px) {
  .myTask {
    position: relative;
    width: 90%;
    height: auto;
    color: black;
    padding-top: 7%;
    padding-bottom: 7%;
  }
}
.credits-table2 {
  width: 58%;
  border-collapse: collapse;
  line-height: 0px;
  display: flex;
  justify-content: center;
  padding-bottom: 3%;
  margin-left: 9%;
}

@media (max-width: 1023px) {
  .credits-table2 {
    width: 100%;
    border-collapse: collapse;
    line-height: 0px;
    display: flex;
    padding-bottom: 3%;
    margin-left: 33%;
    flex-direction: column;
    align-items: stretch;
  }
}
@media (max-width: 767px) {
  .credits-table2 {
    width: 80vw;
    border-collapse: collapse;
    line-height: 5px;
    display: flex;
    justify-content: center;
    margin-left: 5%;
    font-size: 3rem;
    padding-bottom: inherit;
  }
}
.credits-table2 td.role2 {
  padding: inherit;
  font-weight: 600;
  color: black;
}

@media (max-width: 767px) {
  .credits-table2 td.role2 {
    padding: inherit;
    font-weight: 600;
    color: black;
  }
}
.credits-table2 td.name2 {
  padding-bottom: 8px;
  font-weight: 300;
  color: black;
  font-family: "Suisse BP";
  transform: translate(-196px, 0px);
  width: 50%;
  line-height: 122%;
}

@media (max-width: 767px) {
  .credits-table2 td.name2 {
    padding: 10px;
    font-weight: 300;
    color: black;
    font-family: "Suisse BP";
    transform: translate(10px, 0px);
    font-size: 2.5rem;
  }
}
.places {
  background-color: #ffddcd;
  overflow: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.places ::-webkit-scrollbar {
  display: none;
}

@media (max-width: 767px) {
  .places {
    background-color: #ffddcd;
    overflow: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .places ::-webkit-scrollbar {
    display: none;
  }
}
.places__wrapper {
  top: 0;
  position: absolute;
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.places__wrapper ::-webkit-scrollbar {
  display: none;
}

.places::-webkit-scrollbar {
  display: none;
}

@media (max-width: 767px) {
  .places__wrapper {
    top: 0;
    position: absolute;
    width: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
    /* Hide scrollbar */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .places__wrapper ::-webkit-scrollbar {
    display: none;
  }
}
.places__title {
  position: relative;
  font-family: "Nd Tupa Nova";
  font-size: 20rem;
  display: flex;
  align-content: center;
  justify-content: center;
  -webkit-text-stroke: 1px;
  text-shadow: 3px 3px #ff9b9b;
  -webkit-text-stroke-color: #ffc600;
  color: #5a86d4;
  text-align: center;
  /* center text along x-axis */
}

@media (max-width: 767px) {
  .places__title {
    position: relative;
    font-family: "Nd Tupa Nova";
    font-size: 25rem;
    display: flex;
    align-content: center;
    justify-content: center;
    -webkit-text-stroke: 1px;
    text-shadow: 3px 3px #ff9b9b;
    -webkit-text-stroke-color: #ffc600;
    color: #5a86d4;
    padding-top: 18%;
  }
}
.places__deliverables {
  position: relative;
  font-size: 2rem;
  color: #002f90;
  width: 26%;
  font-family: "Helvetica Neue LT Pro";
  font-weight: 400;
  text-align: center;
  padding-bottom: 0%;
}

@media (max-width: 767px) {
  .places__deliverables {
    position: relative;
    font-size: 2.5rem;
    color: #002f90;
    width: 76%;
    font-family: "Helvetica Neue LT Pro";
    font-weight: 400;
    text-align: center;
    padding-bottom: 9%;
  }
}
.container__deliverables {
  display: flex;
  flex-direction: column;
  /* Stack items along the y-axis */
  justify-content: center;
  align-items: center;
  /* To center align along x-axis */
  margin-top: 1%;
}

@media (max-width: 767px) {
  .container__deliverables {
    display: flex;
    flex-direction: column;
    /* Stack items along the y-axis */
    justify-content: center;
    align-items: center;
    /* To center align along x-axis */
    margin-top: 1%;
  }
}
.places__objectives {
  position: relative;
  text-align: center;
  font-size: 2rem;
  font-family: "Helvetica Neue LT Pro";
  color: #073b98;
  margin-top: 3%;
}

@media (max-width: 767px) {
  .places__objectives {
    position: relative;
    text-align: center;
    font-size: 3.5rem;
    font-family: "Helvetica Neue LT Pro";
    color: #073b98;
    margin-top: 3%;
  }
}
.places__intro {
  position: relative;
  width: 55%;
  color: black;
  text-align: left;
  margin-top: 1%;
  font-family: "Suisse BP";
  padding-top: 1%;
}

@media (max-width: 767px) {
  .places__intro {
    position: relative;
    width: 90%;
    color: black;
    text-align: left;
    margin-top: 1%;
    font-family: "Suisse BP";
    padding-top: 5%;
  }
}
.places__preproduction {
  position: relative;
  top: 0%;
  color: #073b98;
  font-family: "Helvetica Neue LT Pro";
  font-size: 2rem;
  display: flex;
  justify-content: center;
  padding-top: 8%;
}

@media (max-width: 767px) {
  .places__preproduction {
    position: relative;
    top: 0%;
    color: #073b98;
    font-family: "Helvetica Neue LT Pro";
    font-size: 3.5rem;
    display: flex;
    justify-content: center;
    padding-top: 8%;
  }
}
.places__moodboard {
  position: relative;
  width: 49%;
  margin-top: 0%;
  opacity: 1;
  border-radius: 5%;
  left: 0%;
  z-index: 1;
  margin-left: 0%;
}

@media (max-width: 767px) {
  .places__moodboard {
    position: relative;
    width: 88%;
    margin-top: -91%;
    opacity: 1;
    border-radius: 5%;
    left: 0%;
    z-index: 1;
    margin-left: 5%;
  }
}
.places__caseContainer {
  display: flex;
  flex-direction: column;
  /* Stack items along the y-axis */
  justify-content: center;
  align-items: center;
  /* To center align along x-axis */
  margin-top: 1%;
}

@media (max-width: 767px) {
  .places__caseContainer {
    display: flex;
    flex-direction: column;
    /* Stack items along the y-axis */
    justify-content: center;
    align-items: center;
    /* To center align along x-axis */
    margin-top: 1%;
  }
}
.places__storyboard {
  position: relative;
  width: 51%;
  opacity: 1;
  top: 0%;
  align-self: flex-start;
}

@media (max-width: 767px) {
  .places__storyboard {
    position: relative;
    width: 127%;
    opacity: 1;
    top: 0%;
    transform: translate(-50px, 0px);
  }
}
.places__storyoutlines {
  position: relative;
  width: 47%;
  top: 0%;
  opacity: 1;
  margin-left: 0%;
  transform: translate(150px, -21px);
  z-index: 3;
  padding-top: 9%;
}

@media (max-width: 767px) {
  .places__storyoutlines {
    position: relative;
    width: 100%;
    top: 0%;
    opacity: 1;
    margin-left: 0%;
    transform: translate(56px, 128px);
    z-index: 3;
    padding-top: 0%;
  }
}
.places__index {
  position: relative;
  font-family: "Nd Tupa Nova";
  font-size: 26rem;
  top: 0%;
  color: #5a86d4;
  text-shadow: 1px 1px #ff9b9b, 2px 2px #ff9b9b, 3px 3px #ff9b9b, 4px 4px #ff9b9b, 5px 5px #ff9b9b, 6px 6px #FF9B9B, 7px 7px #ff9b9b, 8px 8px #ff9b9b, 9px 9px #ff9b9b, 10px 10px #ff9b9b;
  margin-left: 39%;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffbdbd;
}

@media (max-width: 767px) {
  .places__index {
    position: relative;
    font-family: "Nd Tupa Nova";
    font-size: 26rem;
    top: 0%;
    color: #5a86d4;
    text-shadow: 1px 1px #ff9b9b, 2px 2px #ff9b9b, 3px 3px #ff9b9b, 4px 4px #ff9b9b, 5px 5px #ff9b9b, 6px 6px #FF9B9B, 7px 7px #ff9b9b, 8px 8px #ff9b9b, 9px 9px #ff9b9b, 10px 10px #ff9b9b;
    margin-left: 39%;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffbdbd;
  }
}
.index1 {
  margin-left: 5%;
}

@media (max-width: 767px) {
  .index1 {
    margin-left: 5%;
  }
}
.index2 {
  top: 0%;
}

@media (max-width: 767px) {
  .index2 {
    top: 0%;
  }
}
.moodboardtext {
  position: relative;
  width: 100%;
  top: 0%;
  justify-content: space-evenly;
  display: flex;
  padding-top: 4%;
}

@media (max-width: 767px) {
  .moodboardtext {
    position: relative;
    width: 100%;
    top: 0%;
    justify-content: space-evenly;
    display: flex;
    padding-top: 35%;
  }
}
#processPlaces1 {
  position: relative;
  width: 25%;
  border-radius: 19%;
}

@media (max-width: 767px) {
  #processPlaces1 {
    position: relative;
    width: 41%;
    border-radius: 19%;
  }
}
#processPlaces2 {
  position: relative;
  width: 25%;
  border-radius: 19%;
}

@media (max-width: 767px) {
  #processPlaces2 {
    position: relative;
    width: 40%;
    border-radius: 19%;
  }
}
.placesProcessContainer {
  display: flex;
  position: relative;
  justify-content: center;
  gap: 1%;
  padding-bottom: 10%;
}

@media (max-width: 767px) {
  .placesProcessContainer {
    display: flex;
    position: relative;
    justify-content: center;
    gap: 7%;
    padding-bottom: 10%;
  }
}
.placesVideos {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1%;
  padding-bottom: 0%;
}

@media (max-width: 767px) {
  .placesVideos {
    display: flex;
    justify-content: center;
    width: 86%;
    flex-direction: column;
  }
}
.places__video1 {
  position: relative;
  padding: 22.5%;
}

@media (max-width: 767px) {
  .places__video1 {
    position: relative;
    margin-bottom: -10%;
    padding: 27.5%;
  }
}
.places__video2 {
  position: relative;
  padding: 22.5%;
}

@media (max-width: 767px) {
  .places__video2 {
    position: relative;
    padding: 42%;
  }
}
.credits-table {
  width: 100%;
  border-collapse: collapse;
  line-height: 0px;
  display: flex;
  justify-content: center;
  padding-bottom: 3%;
  margin-left: 9%;
}

@media (max-width: 767px) {
  .credits-table {
    width: 80vw;
    border-collapse: collapse;
    line-height: 5px;
    display: flex;
    justify-content: center;
    margin-left: 5%;
    font-size: 3rem;
    padding-bottom: inherit;
  }
}
.credits-table td.role {
  padding: inherit;
  font-weight: 600;
  color: black;
}

@media (max-width: 767px) {
  .credits-table td.role {
    padding: inherit;
    font-weight: 600;
    color: black;
  }
}
.credits-table td.name {
  padding-bottom: 8px;
  font-weight: 300;
  color: black;
  font-family: "Suisse BP";
  transform: translate(-196px, 0px);
  width: 39%;
  line-height: 122%;
}

@media (max-width: 767px) {
  .credits-table td.name {
    padding: 10px;
    font-weight: 300;
    color: black;
    font-family: "Suisse BP";
    transform: translate(27px, 0px);
  }
}
.placesCredits {
  position: relative;
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .placesCredits {
    position: relative;
    display: flex;
    justify-content: center;
  }
}
.places__storyboardContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .places__storyboardContainer {
    position: relative;
    display: flex;
    justify-content: center;
  }
}
.places__storyboardText {
  position: relative;
  justify-content: flex-end;
  top: 0;
  color: black;
  padding-top: 10%;
  width: 27%;
  margin-left: -4%;
}

@media (max-width: 767px) {
  .places__storyboardText {
    position: relative;
    justify-content: flex-end;
    top: 0;
    color: black;
    padding-top: 76%;
    width: 100%;
    margin-left: -120%;
    padding-bottom: 10%;
  }
}
.places__Role {
  font-family: "Suisse BP";
  position: relative;
  color: black;
  justify-content: center;
  display: flex;
  height: 100%;
  flex-direction: row;
}

@media (max-width: 767px) {
  .places__Role {
    font-family: "Suisse BP";
    position: relative;
    color: black;
    justify-content: center;
    display: flex;
    height: 100%;
    flex-direction: row;
  }
}
.places__RoleText {
  color: black;
  font-family: "Helvetica Neue LT Pro";
  font-size: 2rem;
  top: 0%;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .places__RoleText {
    color: black;
    font-family: "Helvetica Neue LT Pro";
    font-size: 2rem;
    top: 0%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }
}
.places__text {
  font-family: "Suisse BP";
  color: black;
  position: relative;
  top: 0%;
  width: 42%;
  display: flex;
  justify-content: center;
  font-weight: 300;
  padding-bottom: 5%;
}

@media (max-width: 767px) {
  .places__text {
    font-family: "Suisse BP";
    color: black;
    position: relative;
    top: 0%;
    width: 86%;
    display: flex;
    justify-content: center;
    font-weight: 300;
    padding-bottom: 5%;
  }
}
.places__Role {
  font-family: "Suisse BP";
  position: relative;
  color: black;
  justify-content: center;
  display: flex;
  height: 100%;
  margin-top: 1%;
}

@media (max-width: 767px) {
  .places__Role {
    font-family: "Suisse BP";
    position: relative;
    color: black;
    justify-content: center;
    display: flex;
    height: 100%;
    margin-top: 1%;
  }
}
.inlabschibsted {
  visibility: visible;
}

.inlabschibsted__title {
  position: absolute;
  font-size: 18rem;
  top: -4%;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  font-family: "Nd Tupa Nova";
  text-shadow: 0 1px #ef8bc7, 0 1.05px #ef8bc7, 1.05px 1px #ef8bc7, 1.05px 1.05px #ef8bc7, 3.1px 4.1px #ef8bc7, 1.1px 4.15px #ef8bc7, 2.15px 6.2px #ef8bc7, 4.15px 8.25px #ef8bc7, 6.2px 9.3px #ef8bc7;
}

.inlabschibsted__wrapper {
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.inlabschibsted__wrapper ::-webkit-scrollbar {
  display: none;
}

.inlabschibsted__wrapper::-webkit-scrollbar {
  display: none;
}

.bg_inLabSchibsted {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #c5c5c5;
}

.inlabPrefaceContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 15%;
  flex-direction: column;
  gap: 1px;
  align-content: space-around;
  align-items: center;
}

.inLabSchibstedcaseBackground {
  position: relative;
  width: 89%;
  opacity: 1;
  z-index: 1;
  top: 0%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 2.3rem;
  font-family: "Helvetica Neue LT Pro";
  padding: -1%;
}

.inLabSchibstedcaseDescription {
  position: relative;
  width: 100%;
  opacity: 1;
  z-index: 1;
  display: flex;
  justify-content: center;
  font-size: 1.75rem;
  font-family: "Helvetica Neue LT Pro";
}

.inLabSchibstedUnderHeading {
  position: relative;
  width: 86%;
  opacity: 1;
  z-index: 1;
  display: flex;
  justify-content: center;
  font-family: "HelveticaNeueLT Pro 43 LtEx";
  font-size: 2rem;
}

#schibstedHeader {
  position: absolute;
  width: 100%;
  opacity: 1;
  z-index: 1;
  top: -10;
}

.textBox1 {
  width: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
  border: 2px solid #ffffff6b;
  flex-wrap: wrap;
  z-index: 3;
  position: relative;
  transform: translate(50%, 10px);
  height: 43%;
  border-radius: 44px;
  background: #f08cc875;
  gap: 20px;
  padding-top: 2.5%;
}

.inlabSchibstedlinktoWebsite {
  position: relative;
  display: flex;
  justify-content: flex-end;
  font-size: 1.75rem;
  z-index: 2;
  opacity: 1;
  padding-right: 10%;
  margin-top: 0%;
  flex-wrap: wrap;
  height: 0%;
  margin-top: 6%;
  font-family: "Helvetica Neue LT Pro";
  transition: color 0.3s;
  color: #ffd4d4;
  z-index: 9999;
  pointer-events: all;
}

.inlabSchibstedlinktoWebsite:hover {
  color: #ffffff;
}

.arrowSchibstedContainer {
  position: absolute;
  display: flex;
  width: 100%;
  z-index: 11;
  bottom: 27.5%;
  justify-content: center;
  flex-wrap: wrap;
}

#arrowToSchibstedCase {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 6%;
  z-index: 2;
  margin-top: 3%;
  -webkit-text-stroke: 1px;
  fill: rgba(255, 255, 255, 0);
  stroke-width: 2%;
  stroke: white;
  transition: fill 0.3s ease-in-out;
}

#arrowToSchibstedCase:hover {
  fill: white;
}

.inlabSchibstedConceptOverview {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 3;
  background: #f2f1f2;
  background: -moz-linear-gradient(0deg, white 0%, #edb3d6 27%, #ef8bc7 73%, rgba(239, 139, 199, 0) 100%);
  background: linear-gradient(0deg, #dadada -1%, #edb3d6 42%, #ef8bc7 73%, rgba(239, 139, 199, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ef8bc7",GradientType=1);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.inlabSchibstedConceptTitle {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 11rem;
  padding-top: 0%;
  font-family: "Nd Tupa Nova";
  top: -7%;
}

.inlabSchibstedConceptIntro {
  position: relative;
  display: flex;
  justify-content: center;
  width: 25%;
  font-family: "HelveticaNeueLT Pro 43 LtEx";
  font-size: 1.75rem;
  margin-top: -1%;
}

.inlabSchibstedConceptList {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-top: 5%;
  align-items: center;
}

.inlabSchibstedConceptList > div {
  transition: all 0.3s ease;
}

.inlabSchibstedConceptList > div:hover {
  border: 2px solid #ef8dc8;
  display: flex;
  width: 17%;
  height: 200%;
  background: #ffffff;
  border-radius: 37px;
  color: #ef8dc8;
  padding: 10px;
  filter: drop-shadow(2px 4px 6px pink);
}

.newsBoxCurationLT, .aNewSoundLT, .newsTherapyLT, .pointOfViewsLT, .surpriseMeLT, .weAreHereLT {
  font-family: "HelveticaNeueLT Pro 43 LtEx";
  position: relative;
  width: 25%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 2.25rem;
}

.newsboxCuration {
  position: relative;
  background: #dadada;
  z-index: 0;
  height: 130%;
}

#newsBoxCurationVideo {
  width: 50%;
  height: auto;
  position: relative;
  margin-left: 0%;
  border-radius: 200px;
  transform: scale(0.75);
  filter: drop-shadow(2px 4px 6px black);
  margin-top: 4%;
}

.newsboxCurationTitle {
  position: absolute;
  width: 100%;
  top: 0%;
  z-index: 50;
  font-family: "Helvetica Neue LT Pro";
  font-size: 9rem;
  color: #6e4275;
  line-height: 94%;
  padding-left: 4%;
  padding-top: 1.5%;
}

.textBox2 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 20.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: 7%;
}

.newsBoxCurationParagraphContainer {
  position: absolute;
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 10;
  gap: 2%;
  margin-top: 24%;
  padding-left: 6%;
  height: 100%;
  font-size: 1.9rem;
  font-weight: 400;
  font-family: "Suisse BP";
}

.newsBoxCurationConcept {
  position: relative;
  width: 100%;
  z-index: 10;
  color: #6e4275;
}

.newsBoxCurationSketch img {
  position: absolute;
  z-index: 11;
  width: 51%;
  top: -16%;
  opacity: 0;
  transition: opacity 0.5s ease;
  left: 48%;
  top: -21%;
  filter: brightness(0.96);
}

.newsBoxCurationButtonContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  align-items: flex-end;
  width: 100%;
  margin-left: -3%;
  margin-top: -12%;
  z-index: 20;
  font-family: "Suisse BP";
  font-weight: 400;
  font-size: 1.9rem;
}

.newsBoxCurationSketchButton {
  position: absolute;
  font-family: "Helvetica Neue LT Pro";
  font-size: 4rem;
  display: flex;
  color: #6e4275;
  padding-left: 2%;
}

.sketchbutton1 {
  background: 100%;
  width: 18%;
  background: #dadada;
  border: 9px solid #6e4275;
  border-radius: 33px;
  padding-left: 2%;
}

.newBoxCurationIdentifiedProblem {
  position: relative;
  width: 100%;
  z-index: 9;
  color: #6e4275;
}

.identifiedProblem {
  font-weight: 600;
  /* or you can use a specific value like 700 */
}

#videoContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 0%;
  flex-direction: row;
  flex-wrap: nowrap;
}

#videoMask {
  position: absolute;
  width: 200px;
  /* Example: set the dimensions of the mask here */
  height: 200px;
  top: 25%;
  left: 25%;
}

#newsBoxCurationVideoBlurred {
  width: 50%;
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  margin-left: 0%;
  background: #dadada;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f7bff",endColorstr="#ffffff",GradientType=1);
}

.transition1 {
  position: relative;
  width: 100%;
  top: -17%;
  z-index: 20;
  height: 100%;
  background: white;
  background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fef8fc 24%, white 75%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fef8fc 24%, white 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fef8fc 12%, white 85%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.pointOfViewTitle {
  position: absolute;
  width: 100%;
  top: 16%;
  z-index: 50;
  font-size: 9rem;
  font-family: "Helvetica Neue LT Pro";
  color: #747474;
  line-height: 94%;
  padding-left: 3%;
  padding-top: 1.5%;
}

.pointOfViewProblem {
  position: relative;
  top: 0%;
  z-index: 2;
  color: #4a4a4a;
}

.textBox2 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 20.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: 7%;
}

.pointOfViewParagraphContainer {
  position: absolute;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  z-index: 10;
  gap: 5%;
  align-content: center;
  margin-top: 22%;
  padding-left: 5%;
  font-family: "Suisse BP";
  font-weight: 400;
  font-size: 1.9rem;
}

.pointOfViewConcept {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #4a4a4a;
}

.pointofViewIdentifiedProblem {
  position: absolute;
  width: 100%;
  margin-left: 10%;
  margin-top: 32%;
}

.pointOfView {
  position: relative;
  width: 100%;
  z-index: 20;
  height: 130%;
  background: #dadada;
  border-top: 85px dotted #e8e8e8;
}

#pointOfViewVideoBlurred {
  width: 50%;
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  margin-left: 0%;
  background: #dadada;
}

.textBox3 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 19.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: -2%;
  padding-top: 0.5%;
}

#pointOfViewVideo {
  width: 50%;
  position: relative;
  border-radius: 200px;
  transform: scale(0.9);
  filter: drop-shadow(2px 4px 6px black);
  margin-top: 16%;
}

.aNewSound {
  position: relative;
  width: 100%;
  z-index: 20;
  height: 130%;
  background: #dadada;
  border-top: 85px dotted #e8e8e8;
}

.aNewSoundTitle {
  position: absolute;
  width: 100%;
  top: 16%;
  z-index: 50;
  font-size: 9rem;
  font-family: "Helvetica Neue LT Pro";
  color: #747474;
  line-height: 94%;
  padding-left: 3%;
  padding-top: 1.5%;
}

.aNewSoundProblem {
  position: relative;
  top: 0%;
  z-index: 2;
  color: #4a4a4a;
}

.aNewSoundParagraphContainer {
  position: absolute;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  z-index: 10;
  gap: 5%;
  align-content: center;
  margin-top: 22%;
  padding-left: 5%;
  font-family: "Suisse BP";
  font-weight: 400;
  font-size: 1.9rem;
}

.aNewSoundConcept {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #4a4a4a;
}

.aNewSoundIdentifiedProblem {
  position: absolute;
  width: 100%;
  margin-left: 10%;
  margin-top: 32%;
}

#aNewSoundVideoBlurred {
  width: 50%;
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  margin-left: 0%;
  background: #dadada;
}

.textBox4 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 20.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: -2%;
  padding-top: 0.5%;
}

#aNewSoundVideo {
  width: 50%;
  position: relative;
  border-radius: 200px;
  transform: scale(0.75);
  filter: drop-shadow(2px 4px 6px black);
  margin-top: 6%;
}

.weAreHere {
  position: relative;
  width: 100%;
  z-index: 20;
  height: 130%;
  background: #dadada;
  border-top: 85px dotted #e8e8e8;
}

.weAreHereTitle {
  position: absolute;
  width: 100%;
  top: 16%;
  z-index: 50;
  font-size: 9rem;
  font-family: "Helvetica Neue LT Pro";
  color: #747474;
  line-height: 94%;
  padding-left: 3%;
  padding-top: 1.5%;
}

.weAreHereProblem {
  position: relative;
  top: 0%;
  z-index: 2;
  color: #4a4a4a;
}

.weAreHereParagraphContainer {
  position: absolute;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  z-index: 10;
  gap: 5%;
  align-content: center;
  margin-top: 19%;
  padding-left: 5%;
  font-family: "Suisse BP";
  font-weight: 400;
  font-size: 1.9rem;
}

.weAreHereConcept {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #4a4a4a;
}

.weAreHereIdentifiedProblem {
  position: absolute;
  width: 100%;
  margin-left: 10%;
  margin-top: 32%;
}

#weAreHereVideoBlurred {
  width: 50%;
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  margin-left: 0%;
  background: #dadada;
}

.textBox5 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 12.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: -2%;
  padding-top: 1%;
}

#weAreHereVideo {
  width: 50%;
  position: relative;
  border-radius: 200px;
  transform: scale(0.75);
  filter: drop-shadow(2px 4px 6px black);
  margin-top: 6%;
}

.newsTherapy {
  position: relative;
  width: 100%;
  z-index: 20;
  height: 130%;
  background: #dadada;
  border-top: 85px dotted #e8e8e8;
}

.newsTherapyTitle {
  position: absolute;
  width: 100%;
  top: 16%;
  z-index: 50;
  font-size: 9rem;
  font-family: "Helvetica Neue LT Pro";
  color: #747474;
  line-height: 94%;
  padding-left: 2%;
  padding-top: 1.5%;
}

.newsTherapyProblem {
  position: relative;
  top: 0%;
  z-index: 2;
  color: #4a4a4a;
}

.newsTherapyParagraphContainer {
  position: absolute;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  z-index: 10;
  gap: 5%;
  align-content: center;
  margin-top: 19%;
  padding-left: 5%;
  font-family: "Suisse BP";
  font-weight: 400;
  font-size: 1.9rem;
}

.newsTherapyConcept {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #4a4a4a;
}

.newsTherapyIdentifiedProblem {
  position: absolute;
  width: 100%;
  margin-left: 10%;
  margin-top: 32%;
}

#newsTherapyVideoBlurred {
  width: 50%;
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  margin-left: 0%;
  background: #dadada;
}

.textBox6 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 13.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: -2%;
}

#newsTherapyVideo {
  width: 50%;
  position: relative;
  border-radius: 200px;
  transform: scale(0.75);
  filter: drop-shadow(2px 4px 6px black);
  margin-top: 7%;
}

.surpriseMe {
  position: relative;
  width: 100%;
  z-index: 20;
  height: 130%;
  background: #dadada;
  border-top: 85px dotted #e8e8e8;
}

.surpriseMeTitle {
  position: absolute;
  width: 100%;
  top: 16%;
  z-index: 50;
  font-size: 9rem;
  font-family: "Helvetica Neue LT Pro";
  color: #747474;
  line-height: 94%;
  padding-left: 3%;
  padding-top: 1.5%;
}

.surpriseMeProblem {
  position: relative;
  top: 0%;
  z-index: 2;
  color: #4a4a4a;
}

.surpriseMeParagraphContainer {
  position: absolute;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  z-index: 10;
  gap: 5%;
  align-content: center;
  margin-top: 19%;
  padding-left: 5%;
  font-family: "Suisse BP";
  font-weight: 400;
  font-size: 1.9rem;
}

.surpriseMeConcept {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #4a4a4a;
}

.surpriseMeIdentifiedProblem {
  position: absolute;
  width: 100%;
  margin-left: 10%;
  margin-top: 32%;
}

#surpriseMeVideoBlurred {
  width: 50%;
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  margin-left: 0%;
  background: #dadada;
}

.textBox7 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 12.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: -2%;
  padding-top: 0.5%;
}

#surpriseMeVideo {
  width: 50%;
  position: relative;
  border-radius: 200px;
  transform: scale(0.75);
  filter: drop-shadow(2px 4px 6px black);
  margin-top: 7%;
}

.end {
  position: relative;
  width: 100%;
  height: 60%;
  margin-top: 0%;
  background: #ef8bc7;
  background: -moz-linear-gradient(0deg, #ef8bc7 0%, #dadada 100%);
  background: -webkit-linear-gradient(0deg, #ef8bc7 0%, #dadada 100%);
  background: linear-gradient(0deg, #ef8bc7 0%, #dadada 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ef8bc7",endColorstr="#dadada",GradientType=1);
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.endText {
  position: relative;
  width: 100%;
  display: flex;
  top: 0%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  font-size: 8rem;
  font-family: "Helvetica Neue LT Pro";
  left: 5%;
}

#endTopButton {
  position: relative;
  display: flex;
  width: 100%;
  top: 0%;
  justify-content: center;
  flex-wrap: nowrap;
  align-content: center;
  fill: white;
  transform: scale(0.5);
}

.endBtnCircle {
  display: block;
  fill: white;
}

@media (max-width: 767px) {
  .inlabschibsted__title {
    position: absolute;
    font-size: 9rem;
    top: 0%;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    font-family: "Nd Tupa Nova";
    text-shadow: 0 1px #ef8bc7, 0 1.05px #ef8bc7, 1.05px 1px #ef8bc7, 1.05px 1.05px #ef8bc7, 3.1px 4.1px #ef8bc7, 1.1px 4.15px #ef8bc7, 2.15px 6.2px #ef8bc7, 4.15px 8.25px #ef8bc7, 6.2px 9.3px #ef8bc7;
    padding-top: 15%;
  }

  .inlabSchibstedConceptList {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 20px;
    top: 12%;
    align-items: center;
  }

  .inlabSchibstedConceptList > div:hover {
    border: 2px solid #ef8dc8;
    display: flex;
    width: 77%;
    height: 200%;
    background: #ffffff;
    border-radius: 37px;
    color: #ef8dc8;
    padding: 10px;
    filter: drop-shadow(2px 4px 6px pink);
  }

  #schibstedHeader {
    position: absolute;
    opacity: 1;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    transform: scale(3);
    margin-top: 55%;
  }

  .textBox1 {
    width: 80%;
    color: white;
    display: flex;
    align-items: center;
    border: 2px solid #ffffff6b;
    z-index: 3;
    position: relative;
    transform: translate(14%, 10px);
    height: 53%;
    border-radius: 47px;
    background: #f08cc875;
    gap: 26px;
    margin-top: 42%;
    justify-content: center;
    flex-wrap: wrap;
    filter: drop-shadow(2px 4px 6px pink);
    padding: 0px 30px 8px 30px;
  }

  #schibstedHeader {
    position: absolute;
    opacity: 1;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    transform: scale(4);
    margin-top: 75%;
    filter: blur(0px);
  }

  .inLabSchibstedcaseBackground {
    position: relative;
    width: 100%;
    opacity: 1;
    z-index: 1;
    top: 0%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 2.2rem;
    font-family: "Helvetica Neue LT Pro";
    padding: -1%;
  }

  .newsBoxCurationParagraphContainer {
    position: absolute;
    width: 91%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 10;
    gap: 2%;
    margin-top: 0%;
    padding-left: 6%;
    height: 100%;
    font-size: 2.5rem;
    font-weight: 400;
    font-family: "Suisse BP";
    top: 50%;
  }

  .inLabSchibstedUnderHeading {
    position: relative;
    width: 99%;
    opacity: 1;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: "HelveticaNeueLT Pro 43 LtEx";
    font-size: 2.4rem;
  }

  .inLabSchibstedcaseDescription {
    position: relative;
    width: 100%;
    opacity: 1;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-size: 2.3rem;
    font-family: "Helvetica Neue LT Pro";
  }

  #arrowToSchibstedCase {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 20%;
    z-index: 2;
    margin-top: 6%;
    -webkit-text-stroke: 1px;
    fill: rgba(255, 255, 255, 0);
    stroke-width: 2%;
    stroke: white;
    transition: fill 0.3s ease-in-out;
    transform: scale(0.75);
  }

  .inlabSchibstedConceptOverview {
    position: relative;
    height: 130vh;
    width: 100vw;
    z-index: 3;
    background: #f2f1f2;
    background: -moz-linear-gradient(0deg, white 0%, #edb3d6 27%, #ef8bc7 73%, rgba(239, 139, 199, 0) 100%);
    background: linear-gradient(0deg, #dadada -1%, #edb3d6 42%, #ef8bc7 73%, rgba(239, 139, 199, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ef8bc7",GradientType=1);
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    margin-top: -50%;
  }

  .inlabSchibstedConceptIntro {
    position: relative;
    display: flex;
    justify-content: center;
    width: 73%;
    font-family: "HelveticaNeueLT Pro 43 LtEx";
    font-size: 2.5rem;
    margin-top: 0%;
    top: 9%;
  }

  .inlabSchibstedlinktoWebsite {
    position: relative;
    display: flex;
    font-size: 2.75rem;
    z-index: 9999;
    opacity: 1;
    padding-right: 10%;
    flex-wrap: nowrap;
    height: 0%;
    margin-top: 6%;
    font-family: "Helvetica Neue LT Pro";
    top: 917%;
    width: fit-content;
    transform: none;
    color: white;
    display: flex;
    justify-content: center;
    left: 9%;
  }

  .newsBoxCurationLT, .aNewSoundLT, .newsTherapyLT, .pointOfViewsLT, .surpriseMeLT, .weAreHereLT {
    font-family: "HelveticaNeueLT Pro 43 LtEx";
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 4.25rem;
  }

  .inlabSchibstedConceptTitle {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 11rem;
    padding-top: 5%;
    font-family: "Nd Tupa Nova";
    margin-top: 0%;
    top: 5%;
    font-size: 14rem;
  }

  #videoContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-left: 0%;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    height: 100%;
    align-items: center;
  }

  #newsBoxCurationVideoBlurred {
    width: 50%;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    margin-left: 0%;
    background: #dadada;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f7bff",endColorstr="#ffffff",GradientType=1);
    display: none;
  }

  .textBox2 {
    position: relative;
    width: 76%;
    background: #dadada;
    border-radius: 20px;
    height: 9.5%;
    margin-left: 0%;
    border: 5px solid;
    top: 5%;
    margin-top: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12%;
    flex-wrap: nowrap;
  }

  .newsBoxCurationSketch img {
    position: absolute;
    z-index: 11;
    width: 89%;
    top: -16%;
    opacity: 0;
    transition: opacity 0.5s ease;
    left: 8%;
    top: -25%;
    filter: brightness(0.96);
    opacity: 0;
  }

  .newsboxCuration {
    position: relative;
    background: #dadada;
    z-index: 0;
    height: 130%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #newsBoxCurationVideo {
    width: 83%;
    height: auto;
    position: relative;
    margin-left: 0%;
    border-radius: 57px;
    transform: scale(0.75);
    filter: drop-shadow(2px 4px 6px rgba(71, 22, 60, 0.3));
    top: -14%;
  }

  .newsboxCurationTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;
  }

  .sketchbutton1 {
    background: 100%;
    width: 32%;
    background: #dadada;
    border: 4px solid #6e4275;
    border-radius: 14px;
    padding-left: 2%;
    position: absolute;
  }

  .newsBoxCurationSketchButton {
    position: absolute;
    font-family: "Helvetica Neue LT Pro";
    font-size: 3rem;
    display: flex;
    color: #6e4275;
    padding-left: 2%;
  }

  .newsBoxCurationButtonContainer {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
    width: 100%;
    margin-left: -17%;
    margin-top: -12%;
    z-index: 20;
    font-family: "Suisse BP";
    font-weight: 400;
    font-size: 1.9rem;
  }

  .newsTherapy {
    position: relative;
    width: 100%;
    z-index: 20;
    height: 130%;
    background: #dadada;
    border-top: 85px dotted #e8e8e8;
    top: -2%;
  }

  #newsTherapyVideo {
    width: 65%;
    position: relative;
    border-radius: 45px;
    transform: scale(1);
    filter: drop-shadow(2px 4px 6px rgba(71, 22, 60, 0.3));
    margin-top: -42%;
  }

  .newsTherapyTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;
  }

  .newsTherapyParagraphContainer {
    position: absolute;
    width: 91%;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
    z-index: 10;
    gap: 2%;
    align-content: center;
    margin-top: 81%;
    padding-left: 5%;
    font-family: "Suisse BP";
    font-weight: 400;
    font-size: 2.5rem;
  }

  .textBox6 {
    position: relative;
    width: 76%;
    background: #dadada;
    border-radius: 20px;
    height: 9.5%;
    margin-left: 0%;
    border: 5px solid;
    top: 5%;
    margin-top: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12%;
    flex-wrap: nowrap;
    padding-bottom: 3%;
  }

  .aNewSound {
    position: relative;
    width: 100%;
    z-index: 20;
    height: 130%;
    background: #dadada;
    border-top: 85px dotted #e8e8e8;
    top: -2%;
  }

  .aNewSoundTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;
  }

  .aNewSoundParagraphContainer {
    position: absolute;
    width: 91%;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
    z-index: 10;
    gap: 2%;
    align-content: center;
    margin-top: 91%;
    padding-left: 5%;
    font-family: "Suisse BP";
    font-weight: 400;
    font-size: 2.5rem;
  }

  #aNewSoundVideo {
    width: 65%;
    position: relative;
    border-radius: 45px;
    transform: scale(1);
    filter: drop-shadow(2px 4px 6px rgba(71, 22, 60, 0.3));
    margin-top: -42%;
  }

  .textBox4 {
    position: relative;
    width: 76%;
    background: #dadada;
    border-radius: 20px;
    height: 9.5%;
    margin-left: 0%;
    border: 5px solid;
    top: 5%;
    margin-top: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12%;
    flex-wrap: nowrap;
    padding-bottom: 3%;
  }

  .weAreHere {
    position: relative;
    width: 100%;
    z-index: 20;
    height: 130%;
    background: #dadada;
    border-top: 85px dotted #e8e8e8;
    top: -2%;
  }

  .weAreHereTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;
  }

  .textBox5 {
    position: relative;
    width: 76%;
    background: #dadada;
    border-radius: 20px;
    height: 9.5%;
    margin-left: 0%;
    border: 5px solid;
    top: 5%;
    margin-top: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12%;
    flex-wrap: nowrap;
    padding-bottom: 3%;
  }

  .weAreHereIdentifiedProblemTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;
  }

  .weAreHereParagraphContainer {
    position: absolute;
    width: 91%;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
    z-index: 10;
    gap: 2%;
    align-content: center;
    margin-top: 91%;
    padding-left: 5%;
    font-family: "Suisse BP";
    font-weight: 400;
    font-size: 2.5rem;
  }

  #weAreHereVideoBlurred {
    width: 50%;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    margin-left: 0%;
    background: #dadada;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f7bff",endColorstr="#ffffff",GradientType=1);
    display: none;
  }

  #weAreHereVideo {
    width: 65%;
    position: relative;
    border-radius: 45px;
    transform: scale(1);
    filter: drop-shadow(2px 4px 6px rgba(71, 22, 60, 0.3));
    margin-top: 0%;
    top: -30%;
  }

  .pointOfView {
    position: relative;
    width: 100%;
    z-index: 20;
    height: 130%;
    background: #dadada;
    border-top: 85px dotted #e8e8e8;
    top: -2%;
  }

  .pointOfViewTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;
  }

  .textBox3 {
    position: relative;
    width: 76%;
    background: #dadada;
    border-radius: 20px;
    height: 9.5%;
    margin-left: 0%;
    border: 5px solid;
    top: 5%;
    margin-top: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12%;
    flex-wrap: nowrap;
    padding-bottom: 3%;
  }

  .pointOfViewIdentifiedProblemTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;
  }

  .pointOfViewParagraphContainer {
    position: absolute;
    width: 91%;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
    z-index: 10;
    gap: 2%;
    align-content: center;
    margin-top: 91%;
    padding-left: 5%;
    font-family: "Suisse BP";
    font-weight: 400;
    font-size: 2.5rem;
  }

  #pointOfViewVideoBlurred {
    width: 50%;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    margin-left: 0%;
    background: #dadada;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f7bff",endColorstr="#ffffff",GradientType=1);
    display: none;
  }

  #pointOfViewVideo {
    width: 65%;
    position: relative;
    border-radius: 45px;
    transform: scale(1);
    filter: drop-shadow(2px 4px 6px rgba(71, 22, 60, 0.3));
    margin-top: 0%;
    top: -30%;
  }

  .surpriseMe {
    position: relative;
    width: 100%;
    z-index: 20;
    height: 130%;
    background: #dadada;
    border-top: 85px dotted #e8e8e8;
    top: -2%;
  }

  .surpriseMeTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;
  }

  .surpriseMeConcept {
    position: relative;
    width: 100%;
    height: 40%;
    z-index: 10;
  }

  .textBox7 {
    position: relative;
    width: 76%;
    background: #dadada;
    border-radius: 20px;
    height: 9.5%;
    margin-left: 0%;
    border: 5px solid;
    top: 5%;
    margin-top: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12%;
    flex-wrap: nowrap;
    padding-bottom: 3%;
  }

  .surpriseMeIdentifiedProblemTitle {
    position: absolute;
    width: 80%;
    top: 14%;
    z-index: 50;
    font-family: "Helvetica Neue LT Pro";
    font-size: 7rem;
    color: #6e4275;
    line-height: 94%;
    padding-left: 2%;
    padding-top: -0.5%;
  }

  .surpriseMeParagraphContainer {
    position: absolute;
    width: 91%;
    display: flex;
    flex-direction: column;
    height: 50%;
    flex-wrap: nowrap;
    z-index: 10;
    gap: 2%;
    align-content: center;
    margin-top: 91%;
    padding-left: 5%;
    font-family: "Suisse BP";
    font-weight: 400;
    font-size: 2.5rem;
  }

  #surpriseMeVideoBlurred {
    width: 50%;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    margin-left: 0%;
    background: #dadada;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f7bff",endColorstr="#ffffff",GradientType=1);
    display: none;
  }

  #surpriseMeVideo {
    width: 65%;
    position: relative;
    border-radius: 45px;
    transform: scale(1);
    filter: drop-shadow(2px 4px 6px rgba(71, 22, 60, 0.3));
    margin-top: 0%;
    top: -30%;
  }

  .end {
    position: relative;
    width: 100%;
    height: 40%;
    margin-top: -5%;
    background: #ef8bc7;
    background: -moz-linear-gradient(0deg, #ef8bc7 0%, #dadada 100%);
    background: -webkit-linear-gradient(0deg, #ef8bc7 0%, #dadada 100%);
    background: linear-gradient(0deg, #ef8bc7 0%, #dadada 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ef8bc7",endColorstr="#dadada",GradientType=1);
  }

  .endText {
    position: relative;
    width: 100%;
    display: flex;
    top: 0%;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    font-size: 8rem;
    font-family: "Helvetica Neue LT Pro";
    left: 5%;
  }

  #endTopButton {
    position: relative;
    display: flex;
    width: 100%;
    top: 0%;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: center;
    fill: white;
    transform: scale(0.5);
  }

  .endBtnCircle {
    display: block;
    fill: white;
  }
}