@media screen and (device-width: 3840px) and (device-height: 2160px) {
    .preface {
        top: 13vh;
        }


    
      .festival_notification {
        margin-top: -28%;
        margin-left: 70%;
    }
    
      .access {
        margin-top: 5%;
        margin-left: 0%;
      }
    
    button#arrowToAssets {
        margin-top: 142vh;
        transform: scale(2);
        margin-left: -9%;   
     }
    
      svg#arrow2BackToContext {
        top: 84vh;
    }
      .animatic {
        top: 19vh;
    }
    
      #storyboardCharacterRun {
        top: 16vh;
    }
    
    
      .storyboard__Title, .menu__ac {
        margin-top: 36.5%;
        bottom: -102.4vh;
        }
    
    .poem {
        top: 40vh;
    }
    
    
    
    .subTitle__Storyboard {

        top: 31vh;
    
    }
    
    
    #storyboardVideo {
     margin-top: 19%;
    }
    
    
    
    #indicator-container {
      margin-top: 3%;
    }
    
    #arrowBackToProcess, #arrowToNextSlide {
      padding-top: 24vh;
    }

    
    
    .navtitle__character2 {
      margin-top: -3vh;
      top: 0vh;
    }
    
    

    
    .o.o3 {
        margin-top: -12vh;
        margin-left: 1.5%;
    }
    
    
    
    h2.navtitle__character1 {
        top: -1.75vh;
        width: 150%;

    
    }
    p.navdescription__character1 {
      top: 0.25vh;
      width: 111%;

  }
    
    .o4 {
      margin-top: -21vh;
    }
    
    h2.navtitle__spacecapsule {
        margin-top: -10vh;
        top: 4.5vh;
        left: -4.3vh;
    }


}



@media screen and (device-width: 2560px) and (device-height: 1600px){
    .preface {
      top: 22vh;
    }
  
    .festival_notification {
      top: -13vh;
    }

    .case4 {

        margin-left: 61%;
          left:4%;
          }
          
  
    .access {
      margin-top: -2vh;
      margin-left: -2vw;
    }
  
    button#arrowToAssets {
      margin-top: 95vh;
  }
  
    svg#arrow2BackToContext {
      margin-top: 3%;
  }
    .animatic {
      top: 25vh;
    }
  
    #storyboardCharacterRun {
      top: 27vh;
    }
  
  
    .storyboard__Title, .menu__ac {
      margin-bottom: 0vh;
  }
  
  .poem {margin-top:3%;}
  
  
  .subTitle__Storyboard {
    margin-top: 8%;
  }
  
  #storyboardVideo {
  
    top:7vh;
  }
  
  
  .processText {
    top: -2vh;
    left: -4vw;
  }
  
  #indicator-container {
    margin-top: 3%;
  }
  
  #arrowBackToProcess, #arrowToNextSlide {
    padding-top: 26vh;
  }
  
  .navtitle__character2 {
    width: 102%;
    margin-top: -3vh;
    top: -1vh;
  }
  
  
  .o2 {
    margin-top: -15vh;
  }
  
  .o.o3 {
    margin-top: -21vh;
  }
  
  
  
  h2.navtitle__character1 {
    top: -0.75vh;
  
  
    p.navdescription__character1 {
      top: 0.25vh;
  }
  }
  
  
  
  .o4 {
    margin-top: -21vh;
  }
  
  h2.navtitle__spacecapsule {
    margin-top: -13vh;
    top: 7vh;
    left: -6vh;
  }
  
  }


  









 @media screen and (device-width: 1680px) and (device-height: 1050px) {
    .preface {
        top: 22vh;
      }
    
      .festival_notification {
        top: -13vh;
      }
    
      .access {
        margin-top: -2vh;
        margin-left: -2vw;
      }
    
    button#arrowToAssets {
        margin-top: 91vh;
    }
    
      svg#arrow2BackToContext {
        margin-top: 3%;
    }
      .animatic {
        top: 25vh;
      }
    
      #storyboardCharacterRun {
        top: 27vh;
      }
    
    
      .storyboard__Title, .menu__ac {
        margin-bottom: 0vh;
    }
    
    .poem {margin-top:3%;}
    
    
    .subTitle__Storyboard {
      margin-top: 8%;
    }
    
    #storyboardVideo {
    
      top:7vh;
    }
    
    
    .processText {
        top: 1.5vh;
        left: -1vw;
    }
    
    #indicator-container {
      margin-top: 3%;
    }
    
    #arrowBackToProcess, #arrowToNextSlide {
      padding-top: 24vh;
    }

    
    
    .navtitle__character2 {
      width: 102%;
      margin-top: -3vh;
      top: 0vh;
    }
    
    

    
    .o.o3 {
    margin-top: -12vh;
    margin-left: -1.5%;
    }
    
    
    
    h2.navtitle__character1 {
      top: -0.75vh;
    
    
      p.navdescription__character1 {
        top: 0.25vh;
    }
    }
    
    .o4 {
      margin-top: -21vh;
    }
    
    h2.navtitle__spacecapsule {
    margin-top: -15vh;
    top: 12.9vh;  
    left: -9vh;
    }




    
  }














@media screen and (device-width: 1440px) and (device-height: 900px) {
    .preface {
        top: 22vh;
    }
    
      .festival_notification {
        margin-top: -28%;
        margin-left: 70%;
    }
    
      .access {
        margin-top: 5%;
        margin-left: 0%;
      }
    
    button#arrowToAssets {
        margin-top: 91vh;
    }
    
      svg#arrow2BackToContext {
        top: 84vh;
    }
      .animatic {
        top: 19vh;
    }
    
      #storyboardCharacterRun {
        top: 24vh;
    }
    
    
      .storyboard__Title, .menu__ac {
        margin-top: 36.5%;
        bottom: -102.4vh;
        }
    
    .poem {
        top: 40vh;
    }
    
    
    
    .subTitle__Storyboard {

        top: 31vh;
    
    }
    
    
    #storyboardVideo {
     margin-top: 19%;
    }
    
    
    .processText {
        top: 3.5vh;
        left: 0vw;
    }
    
    #indicator-container {
      margin-top: 3%;
    }
    
    #arrowBackToProcess, #arrowToNextSlide {
      padding-top: 24vh;
    }

    
    
    .navtitle__character2 {
      width: 102%;
      margin-top: -3vh;
      top: 0vh;
    }
    
    

    
    .o.o3 {
    margin-top: -12vh;
    margin-left: -1.5%;
    }
    
    
    
    h2.navtitle__character1 {
      top: -0.75vh;
    
    
      p.navdescription__character1 {
        top: 0.25vh;
    }
    }
    
    .o4 {
      margin-top: -21vh;
    }
    
    h2.navtitle__spacecapsule {
        margin-top: -15vh;
        top: 15.5vh;
        left: -10vh;
    }




    
  }



