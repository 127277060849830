
.inlabschibsted {
  @extend %page;
  visibility: visible;
}


.inlabschibsted__title {
  position: absolute;
  font-size: 18rem;
  top: -4%;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  font-family: $font-nd-tupa-nova;
  text-shadow: 0 1px #ef8bc7, 0 1.05px #ef8bc7, 1.05px 1px #ef8bc7, 1.05px 1.05px #ef8bc7, 3.1px 4.1px #ef8bc7, 1.1px 4.15px #ef8bc7, 2.15px 6.2px #ef8bc7, 4.15px 8.25px #ef8bc7, 6.2px 9.3px #ef8bc7;

}

.inlabschibsted__wrapper {
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
/* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
      display: none;
}
}

.inlabschibsted__wrapper::-webkit-scrollbar {
  display: none;
}


.bg_inLabSchibsted{
  width:100%;
  height:100%;
  position: absolute;
  background-color:#c5c5c5;

}





.inlabPrefaceContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 15%;
  flex-direction: column;
  gap: 1px;
  align-content: space-around;
  align-items: center;
}




.inLabSchibstedcaseBackground {
  position: relative;
  width: 89%;
  opacity: 1;
  z-index: 1;
  top: 0%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 2.3rem;
  font-family: $font-helvetica-neue-lt-pro;
  padding: -1%;
}

.inLabSchibstedcaseDescription {
  position: relative;
  width: 100%;
  opacity: 1;
  z-index: 1;
  display: flex;
  justify-content: center;
  font-size: 1.75rem;
  font-family: $font-helvetica-neue-lt-pro;
}

.inLabSchibstedUnderHeading {
  position: relative;
  width: 86%;
  opacity: 1;
  z-index: 1;
  display: flex;
  justify-content: center;
  font-family: $font-helvetica-neue-lt-pro-ltex;
  font-size: 2rem;
}

#schibstedHeader {
  position: absolute;
  width: 100%;
  opacity: 1;
  z-index: 1;
  top:-10;
}



.textBox1 {
  width: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
  border: 2px solid #ffffff6b;
  flex-wrap: wrap;
  z-index: 3;
  position: relative;
  transform: translate(50%, 10px);
  height: 43%;
  border-radius: 44px;
  background: #f08cc875;
  gap: 20px;
  padding-top: 2.5%;
}



.inlabSchibstedlinktoWebsite {
  position: relative;
  display: flex;
  justify-content: flex-end;
  font-size: 1.75rem;
  z-index: 2;
  opacity: 1;
  padding-right: 10%;
  margin-top: 0%;
  flex-wrap: wrap;
  height: 0%;
  margin-top: 6%;
  font-family: $font-helvetica-neue-lt-pro;
  transition: color 0.3s;
  color: #ffd4d4;
  z-index: 9999;
  pointer-events: all;
}

.inlabSchibstedlinktoWebsite:hover {
  color:#ffffff; 
}

.arrowSchibstedContainer {
  position: absolute;
  display: flex;
  width: 100%;
  z-index: 11;
  bottom: 27.5%;
  justify-content: center;
  flex-wrap: wrap;
}


#arrowToSchibstedCase {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 6%;
  z-index: 2;
  margin-top: 3%;
  -webkit-text-stroke: 1px;
  fill: rgba(255, 255, 255, 0);
  stroke-width: 2%;
  stroke: white;
  transition: fill 0.3s ease-in-out; 
}

#arrowToSchibstedCase:hover {
  fill: rgba(255, 255, 255, 1);
}

.inlabSchibstedConceptOverview {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 3;
  background: #f2f1f2;
  background: -moz-linear-gradient(0deg, white 0%, #edb3d6 27%, #ef8bc7 73%, rgba(239, 139, 199, 0) 100%);
  background: linear-gradient(0deg, #dadada -1%, #edb3d6 42%, #ef8bc7 73%, rgba(239, 139, 199, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ef8bc7",GradientType=1);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}



.inlabSchibstedConceptTitle {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 11rem;
  padding-top: 0%;
  font-family: $font-nd-tupa-nova;
  top: -7%;
}

.inlabSchibstedConceptIntro {
  position: relative;
  display: flex;
  justify-content: center;
  width: 25%;
  font-family: $font-helvetica-neue-lt-pro-ltex;
  font-size: 1.75rem;
  margin-top: -1%;
}

.inlabSchibstedConceptList {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-top: 5%;
  align-items: center;
}


.inlabSchibstedConceptList > div {
  transition: all 0.3s ease; 
}

.inlabSchibstedConceptList > div:hover {
  border: 2px solid #ef8dc8;
  display: flex;
  width: 17%;
  height: 200%;
  background: #ffffff;
  border-radius: 37px;
  color: #ef8dc8;
  padding: 10px;
  filter: drop-shadow(2px 4px 6px pink);
}


.newsBoxCurationLT, .aNewSoundLT, .newsTherapyLT, .pointOfViewsLT, .surpriseMeLT, .weAreHereLT {
  font-family: "HelveticaNeueLT Pro 43 LtEx";
  position: relative;
  width: 25%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 2.25rem;
}



.newsboxCuration {
  position: relative;
  background: #dadada;
  z-index: 0;
  height:130%;
}

#newsBoxCurationVideo {
  width: 50%;
  height: auto;
  position: relative;
  margin-left: 0%;
  border-radius: 200px;
  transform: scale(0.75);
  filter: drop-shadow(2px 4px 6px black);
  margin-top: 4%;
}


.newsboxCurationTitle {
  position: absolute;
  width: 100%;
  top: 0%;
  z-index: 50;
  font-family:$font-helvetica-neue-lt-pro;
  font-size: 9rem;
  color: #6e4275;
  line-height: 94%;
  padding-left: 4%;
  padding-top: 1.5%;
}


.textBox2 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 20.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: 7%;
}

.newsBoxCurationParagraphContainer {
  position: absolute;
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 10;
  gap: 2%;
  margin-top: 24%;
  padding-left: 6%;
  height: 100%;
  font-size: 1.9rem;
  font-weight:400;
  font-family: $font-suisse-bp;
}

.newsBoxCurationConcept {
  position: relative;
  width: 100%;
  z-index: 10;
  color: #6e4275;
}


.newsBoxCurationSketch img {
  position: absolute;
  z-index: 11;
  width: 51%;
  top: -16%;
  opacity:0;
  transition: opacity 0.5s ease;
  left: 48%;
  top: -21%;
  filter: brightness(0.96);
}


.newsBoxCurationButtonContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  align-items: flex-end;
  width: 100%;
  margin-left: -3%;
  margin-top: -12%;
  z-index: 20;
  font-family:$font-suisse-bp;
  font-weight:400;
  font-size: 1.9rem;

}
.newsBoxCurationSketchButton {
  position: absolute;
  font-family: $font-helvetica-neue-lt-pro;
  font-size: 4rem;
  display: flex;
  color: #6e4275;
  padding-left: 2%;
}




.sketchbutton1 {
  background: 100%;
  width: 18%;
  background: #dadada;
  border: 9px solid #6e4275;
  border-radius: 33px;
  padding-left: 2%;
}




.newBoxCurationIdentifiedProblem {
  position: relative;
  width: 100%;
  z-index: 9;
  color: #6e4275;
}

.identifiedProblem {
  font-weight: 600; /* or you can use a specific value like 700 */
}




#videoContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 0%;
  flex-direction: row;
  flex-wrap: nowrap;
}



#videoMask {
  position: absolute;
  width: 200px; /* Example: set the dimensions of the mask here */
  height: 200px;
  top: 25%;
  left: 25%;
}

#newsBoxCurationVideoBlurred {
  width: 50%;
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  margin-left: 0%;
  background: #dadada;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f7bff",endColorstr="#ffffff",GradientType=1);
}


.transition1 {
  position: relative;
  width: 100%;
  top: -17%;
  z-index: 20;
  height: 100%;
  background: white;
  background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fef8fc 24%, white 75%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fef8fc 24%, white 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fef8fc 12%, white 85%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}


.pointOfViewTitle {
  position: absolute;
  width: 100%;
  top: 16%;
  z-index: 50;
  font-size: 9rem;
  font-family: $font-helvetica-neue-lt-pro;
  color: #747474;
  line-height: 94%;
  padding-left: 3%;
  padding-top: 1.5%;
}





.pointOfViewProblem {
  position: relative;
  top: 0%;
  z-index: 2;
  color: #4a4a4a;
}

  
.textBox2 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 20.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: 7%;
}

.pointOfViewParagraphContainer {
  position: absolute;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  z-index: 10;
  gap: 5%;
  align-content: center;
  margin-top: 22%;
  padding-left: 5%;
  font-family:$font-suisse-bp;
  font-weight:400;
  font-size: 1.9rem;

}


.pointOfViewConcept {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #4a4a4a;
}




.pointofViewIdentifiedProblem {
  position: absolute;
  width: 100%;
  margin-left: 10%;
  margin-top: 32%;
}

.pointOfView {
  position: relative;
  width: 100%;
  z-index: 20;
  height: 130%;
  background: #dadada;
  border-top: 85px dotted #e8e8e8;
}

  

  #pointOfViewVideoBlurred {
    width: 50%;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    margin-left: 0%;
    background: #dadada;
}


.textBox3 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 19.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: -2%;
  padding-top: 0.5%;
}

#pointOfViewVideo {
  width: 50%;
  position: relative;
  border-radius: 200px;
  transform: scale(0.9);
  filter: drop-shadow(2px 4px 6px black);
  margin-top: 16%;
}




























.aNewSound {
  position: relative;
  width: 100%;
  z-index: 20;
  height: 130%;
  background: #dadada;
  border-top: 85px dotted #e8e8e8;
}

.aNewSoundTitle {
  position: absolute;
  width: 100%;
  top: 16%;
  z-index: 50;
  font-size: 9rem;
  font-family: $font-helvetica-neue-lt-pro;
  color: #747474;
  line-height: 94%;
  padding-left: 3%;
  padding-top: 1.5%;
}





.aNewSoundProblem {
  position: relative;
  top: 0%;
  z-index: 2;
  color: #4a4a4a;
}

  
.aNewSoundParagraphContainer {
  position: absolute;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  z-index: 10;
  gap: 5%;
  align-content: center;
  margin-top: 22%;
  padding-left: 5%;
  font-family:$font-suisse-bp;
  font-weight:400;
  font-size: 1.9rem;

}

.aNewSoundConcept {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #4a4a4a;
}




.aNewSoundIdentifiedProblem {
  position: absolute;
  width: 100%;
  margin-left: 10%;
  margin-top: 32%;

}


  

  #aNewSoundVideoBlurred {
    width: 50%;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    margin-left: 0%;
    background: #dadada;
}

.textBox4 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 20.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: -2%;
  padding-top: 0.5%;
}

#aNewSoundVideo {
  width: 50%;
  position: relative;
  border-radius: 200px;
  transform: scale(0.75);
  filter: drop-shadow(2px 4px 6px black);
  margin-top: 6%;
}































.weAreHere {
  position: relative;
  width: 100%;
  z-index: 20;
  height: 130%;
  background: #dadada;
  border-top: 85px dotted #e8e8e8;
}

.weAreHereTitle {
  position: absolute;
  width: 100%;
  top: 16%;
  z-index: 50;
  font-size: 9rem;
  font-family: $font-helvetica-neue-lt-pro;
  color: #747474;
  line-height: 94%;
  padding-left: 3%;
  padding-top: 1.5%;
}





.weAreHereProblem {
  position: relative;
  top: 0%;
  z-index: 2;
  color: #4a4a4a;
}

  
.weAreHereParagraphContainer {
  position: absolute;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  z-index: 10;
  gap: 5%;
  align-content: center;
  margin-top: 19%;
  padding-left: 5%;
  font-family:$font-suisse-bp;
  font-weight:400;
  font-size: 1.9rem;

}

.weAreHereConcept {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #4a4a4a;
}




.weAreHereIdentifiedProblem {
  position: absolute;
  width: 100%;
  margin-left: 10%;
  margin-top: 32%;

}


  

  #weAreHereVideoBlurred {
    width: 50%;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    margin-left: 0%;
    background: #dadada;
}

.textBox5 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 12.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: -2%;
  padding-top: 1%;
}

#weAreHereVideo {
  width: 50%;
  position: relative;
  border-radius: 200px;
  transform: scale(0.75);
  filter: drop-shadow(2px 4px 6px black);
  margin-top: 6%;
}



























.newsTherapy {
  position: relative;
  width: 100%;
  z-index: 20;
  height: 130%;
  background: #dadada;
  border-top: 85px dotted #e8e8e8;
}

.newsTherapyTitle {
  position: absolute;
  width: 100%;
  top: 16%;
  z-index: 50;
  font-size: 9rem;
  font-family: $font-helvetica-neue-lt-pro;
  color: #747474;
  line-height: 94%;
  padding-left: 2%;
  padding-top: 1.5%;
}





.newsTherapyProblem {
  position: relative;
  top: 0%;
  z-index: 2;
  color: #4a4a4a;
}

  
.newsTherapyParagraphContainer {
  position: absolute;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  z-index: 10;
  gap: 5%;
  align-content: center;
  margin-top: 19%;
  padding-left: 5%;
  font-family:$font-suisse-bp;
  font-weight:400;
  font-size: 1.9rem;

}

.newsTherapyConcept {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #4a4a4a;
}




.newsTherapyIdentifiedProblem {
  position: absolute;
  width: 100%;
  margin-left: 10%;
  margin-top: 32%;

}


  

  #newsTherapyVideoBlurred {
    width: 50%;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    margin-left: 0%;
    background: #dadada;
}

.textBox6 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 13.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: -2%;
}

#newsTherapyVideo {
  width: 50%;
  position: relative;
  border-radius: 200px;
  transform: scale(0.75);
  filter: drop-shadow(2px 4px 6px black);
  margin-top: 7%;
}






























.surpriseMe {
  position: relative;
  width: 100%;
  z-index: 20;
  height: 130%;
  background: #dadada;
  border-top: 85px dotted #e8e8e8;
}

.surpriseMeTitle {
  position: absolute;
  width: 100%;
  top: 16%;
  z-index: 50;
  font-size: 9rem;
  font-family: $font-helvetica-neue-lt-pro;
  color: #747474;
  line-height: 94%;
  padding-left: 3%;
  padding-top: 1.5%;
}





.surpriseMeProblem {
  position: relative;
  top: 0%;
  z-index: 2;
  color: #4a4a4a;
}

  
.surpriseMeParagraphContainer {
  position: absolute;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  z-index: 10;
  gap: 5%;
  align-content: center;
  margin-top: 19%;
  padding-left: 5%;
  font-family:$font-suisse-bp;
  font-weight:400;
  font-size: 1.9rem;

}

.surpriseMeConcept {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #4a4a4a;
}




.surpriseMeIdentifiedProblem {
  position: absolute;
  width: 100%;
  margin-left: 10%;
  margin-top: 32%;

}


  

  #surpriseMeVideoBlurred {
    width: 50%;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    margin-left: 0%;
    background: #dadada;
}

.textBox7 {
  position: absolute;
  width: 43%;
  background: #dadada;
  border-radius: 20px;
  height: 12.5%;
  margin-left: 4%;
  border: 5px solid;
  margin-top: -2%;
  padding-top: 0.5%;
}

#surpriseMeVideo {
  width: 50%;
  position: relative;
  border-radius: 200px;
  transform: scale(0.75);
  filter: drop-shadow(2px 4px 6px black);
  margin-top: 7%;
}




.end {
  position: relative;
  width: 100%;
  height: 60%;
  margin-top: 0%;
  background: #ef8bc7;
  background: -moz-linear-gradient(0deg, #ef8bc7 0%, #dadada 100%);
  background: -webkit-linear-gradient(0deg, #ef8bc7 0%, #dadada 100%);
  background: linear-gradient(0deg, #ef8bc7 0%, #dadada 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ef8bc7",endColorstr="#dadada",GradientType=1);
  display:flex;
  justify-content:center;
  flex-direction: row;
}

.endText {
  position: relative;
  width: 100%;
  display: flex;
  top: 0%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  font-size: 8rem;
  font-family: $font-helvetica-neue-lt-pro;
  left: 5%;
}


#endTopButton {
  position: relative;
  display: flex;
  width: 100%;
  top: 0%;
  justify-content: center;
  flex-wrap: nowrap;
  align-content: center;
  fill:white;
  transform:scale(0.5);
}


.endBtnCircle {
  display: block;
  fill: white;
}



































