.thepark {
  @extend %page;

    background: #FEC4C4;

  // color: $color-bright-grey;
  overflow:scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
      display: none;
}
}

.thepark::-webkit-scrollbar {
  display: none;
}

.thepark__wrapper {
  top: 0;
  position: absolute;
  width: 100%;
  overflow:hidden;
  scroll-behavior: smooth;
  /* Hide scrollbar */
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
  }

}

@include media ('<phone') {

  .thepark__wrapper {
    top: 0;
    position: absolute;
    width: 100%;
    overflow: scroll;
    height: 100%;
}
}


.thepark__title {
position:relative;
font-family: $font-nd-tupa-nova;
font-size: 20rem;
display: flex;
align-content: center;
justify-content: center;
-webkit-text-stroke: 2px;
-webkit-text-stroke-color: #ffa2a2;
text-shadow: 3px 3px #b65c5c;
}



@include media ('<phone') {.thepark__title {
  position: relative;
  margin-top: 21%;
}}


.thepark__video {
  width: 100%;
  height: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3%;
  position: relative;
  padding-bottom: 7%;
}


@include media ('<phone') {.thepark__video {
  width: 100%;
  display: flex;
  margin-top: -24%;
  display: flex;
  align-content: center;

}}


.thepark__deliverables {
  position: relative;
  width: 37%;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  font-size: 1.5rem;
  font-family: $font-helvetica-neue-lt-pro;
  color: #2e408a;
  padding-top: 2%;
}

@include media ('<phone') {.thepark__deliverables {


    position: relative;
    width: 84%;
    margin-top: -22%;
    color: black;
    font-family: $font-helvetica-neue-lt-pro;
    height: auto;
    font-size: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: 8%;
    margin-top: -22%;
}}

.thepark__container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
}

.thepark_intro {
  position: relative;
  width: 100%;
  margin-top: 0%;
  display: flex;
  justify-content: center;
  padding-top: 2%;
  font-family: $font-suisse-bp;
  color: #2e408a;
  padding-bottom:7%;
 }

@include media ('<phone') {

    .thepark_intro {
        position: relative;
        width: 75%;
        opacity: 1;
        height: auto;
        font-size: 2.3rem;
        color: black;
        font-family: system-ui;
        margin-top: 4%;
    }}


.headOf3D {
  position: relative;
  width: 100%;
  height: auto;
  -webkit-text-stroke: 2px;
  -webkit-text-stroke-color: #ffc7c7;
  text-shadow: 3px 3px #ff5757;
  font-size: 10rem;
  font-family: $font-nd-tupa-nova;
  display: flex;
  justify-content: center;
}

@include media ('<tablet') {.headOf3D { 
  position: relative;
  width: 100%;
  height: auto;
  -webkit-text-stroke: 2px;
  -webkit-text-stroke-color: #ffc7c7;
  text-shadow: 3px 3px #ff5757;
  font-size: 10rem;
  font-family: "Nd Tupa Nova";
  display: flex;
  justify-content: center;
  font-size: 15rem;
}}

.headOf3Dimage {
  position: relative;
  width: 29%;
  opacity: 1;
  padding-top: 3%;
  border-radius: 50px;

  @include media ('<tablet') {
      position: relative;
      width: 50%;
      opacity: 1;
      padding-top: 3%;
      border-radius: 50px;
  }
  

  @include media ('<phone') {
    position: relative;
    width: 89%;
    opacity: 1;
    padding-top: 3%;
    border-radius: 50px;
  }
}

@include media ('<phone') { .headOf3D {

    position: relative;
    width: 100%;
    height: auto;
    -webkit-text-stroke: 2px;
    -webkit-text-stroke-color: #ffc7c7;
    text-shadow: 3px 3px #ff5757;
    font-size: 13rem;
    font-family: $font-nd-tupa-nova;
    display: flex;
    justify-content: center;
    padding-bottom: 5%;
    padding-top: 12%;
}
}



.myTask {
  position: relative;
  width: 40%;
  height: auto;
  display: flex;
  justify-content: center;
  color: #2e408a;
  font-family: $font-suisse-bp;
  align-items: center;
}



@include media ('<phone') { .myTask {
  position: relative;
  width: 100%;
  height: auto;
  color: black;
  padding-top: 7%;
  padding-bottom: 7%;
 }
}




@include media ('<phone') {.charactersImage {
  position: relative;
  width: 100%;
  opacity:1;
}}



.thepark__containerMyTask {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  justify-content: center;
  gap: 7%;
  background: #ffd2d2;
  filter: drop-shadow(3px 1px 2px #ae8585);
  padding-bottom: 4%;
}

@include media ('<phone') {
.thepark__containerMyTask {
  display:flex;
  position:relative;
  flex-direction:column-reverse;
  padding-bottom:4%;
  align-items:center;
  flex-wrap: nowrap;
  align-items: center;
}


}



.thepark_container1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
}

.thepark_characterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  padding-bottom: 10%;
}

.charactersImage {
  position: relative;
  width: 89%;
  opacity: 1;
}

.thepark_characterDescription {
  position: relative;
  display: flex;
  width: 70%;
  color: #2e408a;
  font-weight: 300;
}

@include media ('<tablet') {.thepark_characterDescription {
  position: relative;
  display: flex;
  width: 70%;
  color: #2e408a;
  font-weight: 300;
  font-size: 4rem;
}
}

@include media ('<phone') {.thepark_characterDescription {
  position: relative;
  display: flex;
  width: 89%;
  color: #2e408a;
  font-weight: 300;
  font-size:3rem;
}}


.thepark_videoContainer {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 10%;
  gap: 50px;
}

.thepark__sidebysideVideo {
  position:relative;
  top:0%;
  display:flex;
  width:50%;

}

@include media ('<phone') {


  .thepark__sidebysideVideo {
    position: relative;
    top: 0%;
    display: flex;
    width: 93%;
    border-radius: 43px;
}

}
.thepark__videoText {
  position: relative;
  display: flex;
  width: 50%;
  color: #2e408a;
  font-weight: 300;
}

@include media ('<phone') {.thepark__videoText{
  position: relative;
  display: flex;
  width: 91%;
  color: #2e408a;
  font-weight: 300;
} }

.placesCredits {
  position:relative;
  display:flex;
  justify-content: center;
}








.theparkCredits {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: 12%;
}
@include media ('<phone') {
  .theparkCredits {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: -100%;
    position: relative;
    margin-top: 200%;
  }
}


@include media ('<phone') {
  .myTask {
    position:relative;
    width:90%;
    height:auto;
    color:black;
    padding-top: 7%;
    padding-bottom: 7%;
  }
}












.credits-table2 {
  width: 58%;
  border-collapse: collapse;
  line-height: 0px;
  display: flex;
  justify-content: center;
  padding-bottom: 3%;
  margin-left: 9%;
}


@include media ('<tablet') {

  .credits-table2 {
    width: 100%;
    border-collapse: collapse;
    line-height: 0px;
    display: flex;
    padding-bottom: 3%;
    margin-left: 33%;
    flex-direction: column;
    align-items: stretch;
}


}


@include media ('<phone') {

  .credits-table2 {
    width: 80vw;
    border-collapse: collapse;
    line-height: 5px;
    display: flex;
    justify-content: center;
    margin-left: 5%;
    font-size: 3rem;
    padding-bottom: inherit;
}


}




.credits-table2 td.role2 {
  padding: inherit;
  font-weight: 600;
  color: black;
}

@include media ('<phone') {

  .credits-table2 td.role2 {
    padding: inherit;
    font-weight: 600;
    color: black;
  }

}

.credits-table2 td.name2 {
  padding-bottom: 8px;
  font-weight: 300;
  color: black;
  font-family:$font-suisse-bp;
  transform: translate(-196px, 0px);
  width: 50%;
  line-height: 122%;
}




@include media ('<phone') {

  .credits-table2 td.name2 {
    padding: 10px;
    font-weight: 300;
    color: black;
    font-family: $font-suisse-bp;
    transform: translate(10px, 0px);
    font-size: 2.5rem;
  }

}





































