$shadowcolour: #000000;

@include media('<phone') {
  %textshade {
    text-shadow: #000000 0 1px $shadowcolour,
      0 1.05px $shadowcolour,
      1.05px 1px $shadowcolour,
      1.05px 1.05px $shadowcolour,
      1.1px 1.1px $shadowcolour,
      1.1px 1.15px $shadowcolour,
      1.15px 1.2px $shadowcolour,
      1.15px 1.25px $shadowcolour,
      1.2px 1.3px $shadowcolour,
  }

  .scrollContainer {
    position: fixed;
    top: 20;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    /* Hide the overflowing content */
  }

  .storyboard {
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 0%;
    width: 100vw;
    z-index: 5;
    display: flex;
  }

  .subTitle__Storyboard {
    font-family: $font-helvetica-neue-lt-pro;
    color: white;
    position: absolute;
    font-size: 4.2rem;
    top: 11vh;
    left: 0vh;
    width: 100%;
    z-index: 3;
    opacity: 1;
  }



  .scrollContent {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }

  .theNegroSpaceProgram_wrapper,
  .case,
  .context,
  .concept {
    height: 100vh;
  }

  /* Each section should be at least the height of the viewport */
  .scrollContent section {
    height: 100vh;
  }

  .theNegroSpaceProgram {
    @extend %page;
    background: black;
    // color: $color-bright-grey;
    overflow: none;

  }

  .theNegroSpaceProgram__wrapper {
    animation: fadeIn 4s ease-in forwards;
  }

  .headerVideo__mobile {
    display: block;
    position: absolute;
    z-index: 0;
    pointer-events: none;
    width: 120%;
    height: auto;
    opacity: 0;
    z-index: -2;
    animation: fadeIn 4s ease-in forwards;
  }

  #headerVideo {
    position: absolute;
    width: 100%;

  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .theNegroSpaceProgram__wrapper {
    top: 0;
    position: absolute;
    width: 100%;
    overflow: none;
  }

  .theNegroSpaceProgram__title {

    font-family: $font-nd-tupa-nova;
    font-size: 17.5rem;
    position: absolute;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .preface {
    font-family: $font-helvetica-neue-lt-pro;
    font-size: 5.5rem;
    position: absolute;
    margin-top: 29%;
    display: flex;
    height: 100%;
    width: 100%;
    opacity: 1;
    margin-left: 0%;
  }

  .preface_Title,
  .preface_Title2 {
    position: absolute;
    font-size: 13rem;
    width: 100vw;
    top: 76vh;
    z-index: 1;
    height: 100vh;
    background-color: black;
    color: rgba(255, 255, 255, 0.25);
    transition: color 0.5s ease-in-out;
    padding-bottom: 4px;
  }

  .preface_Title.active,
  .preface_Title2.active {
    color: rgba(255, 255, 255, 1);
  }

  .preface_Title:hover:not(.no-hover),
  .preface_Title2:hover:not(.no-hover) {
    color: rgba(255, 255, 255, 1);
  }

  .preface_Title2 {
    margin-left: 0%;
    top: 1vh;
    font-size: 12rem;
    z-index: 0;
    padding-bottom: -23vw;
}


  .preface_Title {
    position: absolute;
    top: 9vh;
    left: -12vw;
    font-size: 10rem;
    padding-top: 0%;
}



  .storyboard__Title,
  .menu__ac {
    font-family: $font-helvetica-neue-lt-pro;
    position: fixed;
    font-size: 13rem;
    width: auto;
    z-index: 1;
    height: auto;
    background-color: #00000000;
    color: rgba(255, 255, 255, 0.25);
    transition: color 0.5s ease-in-out;
    flex-wrap: wrap;
    padding: 30px 200px 31px 150px;
    margin-top: 35.5%;
    position: fixed;
    bottom: -103vh;
  }

  .storyboard__Title.active,
  .menu__ac.active {
    color: rgba(255, 255, 255, 1);
  }

  .storyboard__Title:hover:not(.no-hover),
  .menu__ac:hover:not(.no-hover) {
    color: rgba(255, 255, 255, 1);
  }


  .storyboard__Title {
    position: absolute;
    z-index: 2;
    width: auto;
    pointer-events: all;
    font-size: 7rem;
    left: -36vw;
    height: 100%;
    top: 45vh;
    display: none;
    pointer-events: none;
    ;
  }

  .menu__ac {
    position: fixed;
    width: 100vw;
    margin-left: 37vw;
    pointer-events: all;
    top: -21.2vh;
    height: 100vh;
    font-size: 4rem;
    display: none;
    pointer-events: none;
  }


  .case {
    position: absolute;
    top: 100vh;
    width: 200vw;
    background: #000000;
    z-index: 2;
    height: 50vh;
  }


  .context {
    position: absolute;
    top: 0vh;
    width: 100vw;
    height: 50vh;
    background: #000000;
    z-index: 2;
    overflow: scroll;
  }

  .context__flexbox {
    position: absolute;
    display: flex;
    height: auto;
    padding: 15px;
    top: -2vh;
    flex-direction: column;
    width: 100vw;
    align-content: center;
    align-items: stretch;
    justify-content: space-around;
    overflow: scroll;


  }


  .concept {
    position: absolute;
    top: 0px;
    width: 100vw;
    background: #000000;
    z-index: 2;
    height: 100vh;
    margin-top: -100vh;
    margin-left: 100vw;
  }


  .poem {
    font-size: 2rem;
    position: absolute;
    top: 46vh;
    width: 100vw;
    color: white;
    line-height: auto;
    z-index: 14;
    left: 19vw;
    font-family: $font-helvetica-neue-lt-pro;


  }

  #arrow2ToAssets {
    position: absolute;
    width: 34vw;
    top: 74vh;
    left: 60vw;
    fill: white;
    display: block;
  }


  #arrow2Animatic {
    position: absolute;
    width: 34vw;
    top: 74vh;
    left: 28vw;
    fill: white;
    display: none;
  }

  #arrow2BackToContext {
    position: absolute;
    width: 22vw;
    top: 77vh;
    left: 7vw;
    fill: white;
    display: block;
  }

  #storyboardVideo {
    position: absolute;
    width: 90%;
    margin-top: 37%;
    margin-left: 5vw;
    border-radius: 24px;
  }

  .process {
    position: absolute;
    top: 0px;
    width: 100vw;
    z-index: 4;
    opacity: 1.5;
    height: 100vh;
    margin-top: -100vh;
    margin-left: 200vw;
    pointer-events: none;
  }






  .blackProcessBg {

    position: absolute;
    height: 200vh;
    width: 100vw;
    background-color: black;
    z-index: 0;
    margin-top: -100vh;
    margin-left: 200vw;

  }

  .results {
    position: absolute;
    top: 0px;
    width: 100vw;
    background: #000000;
    z-index: 2;
    opacity: 1.5;
    height: 150vh;
    margin-top: -100vh;
    margin-left: 300vw;
  }


  .Breakdown__title {
    font-family: $font-nd-tupa-nova;
    font-size: 11.5rem;
    position: relative;
    width: 100vw;
    display: flex;
    height: 100vh;
    justify-content: center;
    top: 50vh;
  }


  .manifesto_text1,
  .manifesto_text2,
  .manifesto_text3 {
    font-family: $font-helvetica-neue-lt-pro;
    font-size: 1.1rem;
    width: 118vw;
    height: 21%;
    font-size: 1.8rem;
    line-height: 2;
  }

  #arrowBackToContext,
  #arrowToAssets {
    margin-top: 84vh;
    display: none;
    pointer-events: none;
  }

  #arrowToCase svg {
    position: absolute;
    margin-left: 1vw;
    margin-top: -28.5vh;
    fill: white;
    z-index: 54;
    pointer-events: all;
    width: 50px;
}

  .festival_notification {
    position: absolute;
    font-family: $font-helvetica-neue-lt-pro;
    font-size: 3.1rem;
    width: 41vw;
    text-align: center;
    margin-top: 50%;
    margin-left: 32%;
    z-index: 1;
    background-color: black;
    color: white;
    height: 10vh;
    border-radius: 3px;
    border-style: outset;
    border-color: white;
    border-width: medium;
    opacity: 0.5;

    @include media ('<tablet') {
      position: absolute;
      font-family: "Helvetica Neue LT Pro";
      font-size: 3.1rem;
      width: 41vw;
      text-align: center;
      margin-top: 50%;
      margin-left: 32%;
      z-index: 1;
      background-color: black;
      color: white;
      height: 10vh;
      border-radius: 3px;
      border-style: outset;
      border-color: white;
      border-width: medium;
      opacity: 0.5;
      transform: translate(0px, 0px);
    }
  }

  .access {
    position: absolute;
    height: auto;
    width: 138%;
    top: 11rem;
    text-align: left;
    left: 19%;
    font-size: 1.3rem;
  }

  .face__canvas {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }


  #arrowBackToContext svg {
    position: absolute;
    fill: rgba(255, 255, 255, 1);
    z-index: 2;
    stroke-width: 2px;
    stroke: white;
    stroke-opacity: 0.25;
    transition: all 1s ease;
    pointer-events: all;
    height: 100%;
    width: 36vw;
    left: 0vw;
    top: -8.3vh;
  }

  .arrowBackToContext {
    pointer-events: all;
  }

  #arrowBackToContext:hover {
    fill: rgba(255, 255, 255, 1);
    stroke-width: 0;
    stroke: white;
    stroke-opacity: 1;
    pointer-events: all;
  }





  #arrowToAssets svg {
    display: none;
    pointer-events: none;
  }



  .arrowToAssets {
    pointer-events: all;
  }

  #arrowToAssets:hover {
    fill: rgba(255, 255, 255, 1);
    stroke-width: 0;
    stroke: white;
    stroke-opacity: 1;
    pointer-events: all;
    stroke: white;
    stroke-width: 0px;
  }



  #storyboardCharacterRun {
    position: absolute;
    width: 43%;
    margin-top: 211%;
    margin-left: -1%;
    border: 0.5px solid #c8c8c8;
  }

  .process__menu,
  .process__menu2 {
    display: none;
    pointer-events: none;
  }





  .arrowBackToProcess {
    pointer-events: all;
  }

  .arrowBackToProcess {
    pointer-events: all;
  }



  #container2 {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 2;
    pointer-events: none;
    top: -100vh;
    margin-left: -28vw;
  }



  .navdescription__nebula {
    font-family: $font-helvetica-neue-lt-pro;

    height: 100%;
    width: 82%;
    position: absolute;
    margin-top: 59px;
    margin-left: 98px;

    z-index: 1;
    opacity: 1;
    font-size: 0.7rem;
    color: #ffffff;
    text-align: left;
    transition: opacity 0.1s ease-in-out;
  }






}