.about__content {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 20rem 0;

  &:first-child {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(5) {
    justify-content: flex-end;
  }

  &:nth-child(8) {
    justify-content: end;
  }

  @include media('<phone') {
    &:nth-child(5) {
      justify-content: flex-end;
    }

    &:nth-child(8) {
      justify-content: flex-start;
    }
  }
}


.about__content--left {

  position:relative;
}
.about__content--right {
  position: relative;
  transform: translate(17%, -250%);
  margin-bottom: 0%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.about__content__wrapper {
  display: flex;

  .about__content--right & {
    flex-direction: row-reverse;
  }

  @include media('<phone') {
    .about__content:nth-child(3) & {
      flex-direction: column-reverse;
    }

    .about__content:nth-child(5) & {
      flex-direction: column;
    }

    .about__content:nth-child(8) & {
      flex-direction: column-reverse;
    }

    .about__content:nth-child(9) & {
      flex-direction: column-reverse;
    }
  }
}

.about__content__box {
  display: flex;

  @include media('<=phone') {
    margin: auto;
    width: 60.9rem;

    .about__content:nth-child(3) & {
      margin-top: 10rem;
    }

    .about__content:nth-child(5) & {
      margin-bottom: 10rem;
    }

    .about__content:nth-child(8) & {
      margin-top: 10rem;
    }

    .about__content:nth-child(9) & {
      margin-top: 10rem;
    }
  }
}

.about__content__label {
  @extend %description-12;

  width: 19rem;
  text-transform: uppercase;
  font-family: $font-helvetica-neue-lt-pro;
  color: #ffffff;
  font-size: 2rem;


  @include media('<=phone') {
    font-size: 2.4rem;
    padding-right: 2.5rem;
    width: 15rem;
  }
}






.about__content__description {
  width: 36.2rem;
  color: white;
  font-family: $font-suisse-bp;
  font-weight: normal;

  p:not(:first-child) {
    margin-top: 2.5rem;
  }

  @include media('<=phone') {
    width: calc(100% - 15rem);
  }
}
.about__content__media {
  position: relative;

  .about__content:nth-child(3) & {
    width: 53.8rem;
    margin-left: 18rem;
    height: 88.8rem;
  }

  .about__content:nth-child(5) & {
    height: 60rem;
    margin-left: 18rem;
    width: 96rem;
  }

  .about__content:nth-child(8) & {
    width: 84.2rem;
    margin-right: 12rem;
    height: 57rem;
  }

  .about__content:nth-child(9) & {
    height: 85.8rem;
    margin-left: 22rem;
    width: 60.8rem;
  }

  @include media('<=phone') {
    .about__content:nth-child(3) & {
      margin-left: 0;
      width: 60.9rem;
      height: 91.4rem;
    }

    .about__content:nth-child(5) & {
      height: 50rem;
      margin-left: 0;
      width: 100vw;
    }

    .about__content:nth-child(8) & {
      width: 100vw;
      margin-right: 0;
      height: 57rem;
    }

    .about__content:nth-child(9) & {
      height: 85.8rem;
      margin-left: 0;
      width: 60.8rem;
    }
  }
}
.description-4 {
  width: fit-content;
  white-space: nowrap;
}

@include media ('<phone') {

  .description-4 {
      margin-bottom: 13vh;
      height: 100%;
      width: 100%;
      font-size: 2.7rem;
      white-space: nowrap;
      margin-left: -27%;
      margin-top: 4%;
  }}



  @include media ('<phone') {
    .description-5 {
        height: 100%;
        width: 100%;
        white-space: nowrap;
        margin-left: -23%;
        margin-top: 4%;
    }
    
    }

.description-6 {
  @include media ('<phone') {
  margin-top: 6vh;

  }  }

.about__content__media__image {
  @extend %cover;
  object-fit: cover;
  border-radius: 250px 250px 0px 0px;
  object-fit: cover;
}
