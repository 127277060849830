/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 29, 2021 */

@font-face {
  font-family: $font-george-x;
  src: url('../fonts/george_x-webfont.woff2') format('woff2'),
    url('../fonts/george_x-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-suisse-bp;
  src: url('../fonts/suissebpintl-light-webfont.woff2') format('woff2'),
    url('../fonts/suissebpintl-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: $font-suisse-bp;
  src: url('../fonts/suissebpintl-regular-webfont.woff2') format('woff2'),
    url('../fonts/suissebpintl-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-suisse-bp;
  src: url('../fonts/suissebpintl-ultralight-webfont.woff2') format('woff2'),
    url('../fonts/suissebpintl-ultralight-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: $font-nd-tupa-nova;
  src: url('../fonts/NdTupaNovaTrial-Heavy.woff2') format('woff2'),
      url('../fonts/NdTupaNovaTrial-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-helvetica-neue-lt-pro;
  src: url('../fonts/HelveticaNeueLTPro-MdEx.woff2') format('woff2'),
      url('../fonts/HelveticaNeueLTPro-MdEx.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: $font-helvetica-neue-lt-pro-ltex;
  src: url('../fonts/HelveticaNeueLTPro-LtEx.woff2') format('woff2'),
      url('../fonts/HelveticaNeueLTPro-LtEx.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}







