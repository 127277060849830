$color-white: #f9f1e7;

$color-contessa: #2D81E0;
$color-quicksand: #2D81E0;
$color-cadet-blue: #b2b8c3;
$color-bright-grey: #37384c;

$font-george-x: 'George X';
$font-suisse-bp: 'Suisse BP';
$font-nd-tupa-nova: 'Nd Tupa Nova';
$font-helvetica-neue-lt-pro: 'Helvetica Neue LT Pro';
$font-helvetica-neue-lt-pro-ltex: 'HelveticaNeueLT Pro 43 LtEx';

$ease-in-out: cubic-bezier(0.77, 0, 0.175, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

$z-indexes: ('preloader', 'navigation', 'content', 'canvas');
